import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DetailButton from "../components/buttons/CardButton";
import DarkLoader from "../components/general/DarkLoader";
import { getAllAddresses } from "../config/Apis/AddressApis";
import appContext from "../context/AppContext";

function CheckoutAddresses({
  setParentSelected,
  selectedAddress,
  setSelectedAddress,
}) {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState({});
  useEffect(() => {
    getData();
  }, []);
  console.log("%c addresses", "background: purple; color: white");

  const getData = async () => {
    try {
      setLoader(true);
      const response = await getAllAddresses();
      setAddresses(response.data.list);
      setSelected(response.data.list[0]);
      setSelectedAddress(response.data.list[0]._id);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  return (
    <div>
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-wrap p-5">
            {addresses.map((item, key) => (
              <AddressCard
                data={item}
                selected={selected}
                setSelected={setSelected}
                setSelectedAddress={setSelectedAddress}
                key={key}
              />
            ))}
            <div
              onClick={() => {
                navigate("/account/addresses");
              }}
              className={`min-h-[250px] w-[320px] border-[#333] border-[1px] border-dashed flex justify-center items-center p-6 bg-[#fff] mx-3 my-2 cursor-pointer`}
            >
              <p className="text-xl font-light" > ADD NEW ADDRESS</p>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="w-[150px] my-3 mr-10">
              <DetailButton
                onClicked={() => setParentSelected(2)}
                bgcolor={"#2C73D2"}
                text="Continue"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export const AddressCard = ({
  data,
  selected,
  setSelected,
  setSelectedAddress,
  key,
}) => {
  const { isRtl } = useContext(appContext);
  const { t } = useTranslation();
  return (
    <div
      key={key}
      onClick={() => {
        setSelected(data);
        setSelectedAddress(data._id);
      }}
      className={`w-[320px] h-[136px] ${
        selected._id == data._id
          ? "border-blue-600  border-[1px] border-solid"
          : ""
      } bg-[#fff] px-3 py-2 ${
        isRtl ? "ml-[10px]" : "mr-[10px]"
      }  mb-[10px] shadow-md cursor-pointer`}
    >
      <div className="block">
        <b>{t("address")}:</b> <p>{`${data.address1}`}</p>
      </div>
      <div className="block">
        <b>{t("phoneNum")}:</b> <p>{`${data.phone1}`}</p>
      </div>
    </div>
  );
};
export default CheckoutAddresses;
