import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import DetailButton from "../buttons/CardButton";
import SubmitButton from "../buttons/SubmitButton";

export default function ConfirmModal({
  closeModal,
  isOpen,
  label,
  onConfirm,
  loader,
  closebtn,
  btnlabel
}) {
  const currentUrl = window.location.href;
  // console.log(currentUrl);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="min-h-full bg-bl mx-auto w-[90%] sm:px-6 lg:px-4 text-center ">
                  {label}
                </div>
                <div className="flex justify-center my-2 ">
                  <div className={`w-[80px] ${!closebtn && "mr-2"}`}>
                    <SubmitButton
                      onClick={onConfirm}
                      label={btnlabel ? btnlabel : "Yes"}
                      color="#2c73d2"
                      loader={loader}
                    />
                  </div>
                  {
                    !closebtn && <div className="w-[80px]">
                      <SubmitButton
                        onClick={closeModal}
                        label="No"
                        color="#ca0b00"
                        loader={loader}
                      />
                    </div>
                  }
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
