import React from "react";
import { Link } from "react-router-dom";

function TopBrandsCard({ name, website, logo, slug, id }) {
  return (
    <Link to={`/seeall?brandId=${id}`}>
      <div className="bg-white mb-[10px] flex justify-center items-center h-[128px] w-[100%] bg-[logo]">
        <img src={logo} alt="" className="h-20 sm:h-[50px]" />
      </div>
    </Link>
  );
}

export default TopBrandsCard;
