import React from "react";
import AccountDropdown from "../dropdown/AccountDropdown";
import CartSlider from "../general/CartSlider";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cart from "../../assets/svg/Shopping-Cart.svg";
import bag from "../../assets/svg/Shopping-Bag.svg";
import loginUser from "../../assets/svg/login.svg";
import flag from "../../assets/svg/flag.svg";
import SellerModal from "../../hooks/SellerModal";

function DesktopHeaderIcons({
  isRtl,
  sellWithUs,
  // flag,
  // loginUser,
  setIsRtl,
  loggedIn,
  onSignOut,
  openModal,
  setCartOpen,
  cartIcon,
  cartItems,
  cartOpen,
  settingData,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  console.log("%c sell with us setting dtaa:","background: green; color: white",settingData )
  // console.log(settingData);
  return (
    <div className="flex lg:hidden items-center justify-around col-span-4">
      <div className="flex max-w-[428px] justify-evenly items-center">
      
        <a
          // // href={`/${settingData.sellWithUsUrl}`}
          href={`http://localhost:3001/`}
          target="_blank"
          className={`cursor-pointer flex items-center ${
            isRtl ? "ml-[10px]" : ""
          } pr-[12px] pl-[8px] text-[14px] lgth3:pl-[0px] font-medium`}
        >
          {/* <img
            src={sellWithUs}
            className="w-[22px] h-[22px] mr-[4px] lgth3:mr-[2px]"
          /> */}
            <img
            src={bag}
            className="w-[21px] h-[21px] mr-[4px] lgth3:mr-[2px] "
          />
        
          {/* <p className="text-[#333]">Sell with Us</p> */}
          <SellerModal />
          {/* <p className="text-[#333] lgth3:text-[12px] lgth:text-[12px]">
            {t("sellWithUs")} 
</p> */}
        </a>
        {/* </SellerModal> */}
        <div className="h-[30px] w-[1px] bg-[#ACACAC]" />
        <div
          className={`cursor-pointer flex items-center  ${"pl-[15px]"} pr-[15px]`}
        >
          <img
            src={flag}
            className="h-5"
            onClick={() => {
              setIsRtl(!isRtl);
              if (isRtl) {
                i18n.changeLanguage("en");
                localStorage.setItem("isRtl", "false");
                document
                  .getElementsByTagName("html")[0]
                  .setAttribute("dir", "ltr");
              } else {
                i18n.changeLanguage("ar");
                localStorage.setItem("isRtl", "true");
                document
                  .getElementsByTagName("html")[0]
                  .setAttribute("dir", "rtl");
              }
            }}
          />
        </div>
        <div className="h-[30px] w-[1px] bg-[#ACACAC]" />

        {loggedIn ? (
          <div className="cursor-pointer pr-[4px] pl-[4px] flex items-center">
            <AccountDropdown
              onNavigate={(val) => navigate(val)}
              onSignout={onSignOut}
            />
          </div>
        ) : (
          <div
            className="cursor-pointer  pr-[15px] pl-[20px] flex items-center"
            onClick={openModal}
          >
            {/* <AccountDropdown /> */}
            <img src={loginUser} className="h-[20px] w-[20px] mr-2" />
            <p className="text-[12px] font-medium text-[#333]">{t("login")}</p>
          </div>
        )}
        <div className="h-[30px] w-[1px] bg-[#ACACAC]" />
        <div
          onClick={() => setCartOpen(true)}
          className="cursor-pointer basic_transition w-[40px] h-[40px] flex mx-[15px] items-center justify-center relative hover:shadow-md rounded-md"
        >
          <img src={cart} style={{width: '25px'}} />
          {/* <img src={cartIcon} /> */}
          <div className=" flex items-center justify-center absolute bg-[#FF9900] text-white p-[2px] text-[10px] rounded-[50%] top-[-5px] right-[-5px] h-[15px] w-[15px] ">
            {isNaN(cartItems) ? 0 : cartItems}
          </div>
        </div>

        <CartSlider open={cartOpen} setOpen={setCartOpen} />
      </div>
    </div>
  );
}

export default DesktopHeaderIcons;
