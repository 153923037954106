import React, { useContext, useEffect, useState } from "react";
import { getCart } from "../config/Apis/CartApis";
import { getSettings } from "../config/Apis/SettingsApi";
import { getWishlist } from "../config/Apis/WishlistApi";
import { details } from "../config/keys";
import appContext from "../context/AppContext";
import { getProductQuantity } from "../Helper/helperFunctions";

let favicon = document.getElementById("favicon");
function useApp(props) {
  const {
    setLoggedIn,
    SetWishlist,
    updateCartCount,
    setSessionModal,
    setSettingData,
    settingData,
    setSettingLoader,
  } = useContext(appContext);
  const [isSettingError, setIsSettingError] = useState(false);

  useEffect(() => {
    setSettingLoader(true);
    getWishlist()
      .then((res) => {
        SetWishlist(res.data.list);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setSessionModal(true);
        } else {
          console.log(err);
        }
      });

    // getCart()
    //   .then((res) => {
    //     const productQuantity = getProductQuantity(res);
    //     updateCartCount(productQuantity);
    //   })
    //   .catch((err) => {
    //     if (err.response.status == 401) {
    //       setSessionModal(true);
    //     }
    //   });

    getSettings()
      .then((res) => {
        setSettingData(res.data);
        setSettingLoader(false);
        console.log(favicon);
        favicon.href = res?.data?.homePage?.favicon;
        // console.log(undefined.undefined);
        setIsSettingError(false);
      })
      .catch((err) => {
        console.log(err);
        setSettingLoader(false);
        setIsSettingError(true);
        // window.location.href = `${window.origin}/maintenance`;
      });

    if (details) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return { isSettingError };
}

export default useApp;
