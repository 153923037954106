import React, { useState } from "react";
import { menu_underline } from "../../assets/index";
import FilterMenu from "./FilterMenu";
import CardContainer from "./CardContainer";
import FilterMenuMobile from "./FilterMenuMobile";
import DarkLoader from "../general/DarkLoader";
import { useParams } from "react-router-dom";
const SeeAllHeader = ({
  data,
  categoryData,
  loader,
  selected,
  setSelected,
  category,
  setSelectedVariant,
  setCurrentBrand,
  setMatch,
  match,
  variants,
  selectedVariant,
}) => {
  const params = useParams();
  return (
    <div className="w-[100%] sm:w-full">
      {Boolean(data.length || params.categoryid != "search") && (
        <>
          <div className="bg-[#f8f8f8] p-4 text-zinc-800 ">
            <div className=" flex justify-between">
              <p className="text-[24px] font-medium text-[#333] uppercase font-[Almarai] ">
                {categoryData?.name}
              </p>
            </div>
            <div className="mt-2">
              <img src={menu_underline} style={{ width: "100%" }} />
            </div>
          </div>
          <div className="px-4 font-[Arial] text-[#333] font-medium w-full leading-[24px] text-[15px] lg:w-[100%]  ">
            {categoryData?.description}
          </div>
          <div className="hidden lg:block">
            <FilterMenuMobile
            selected={selected}
            category={category}
            setSelected={setSelected}
            setCurrentBrand={setCurrentBrand}
            setSelectedVariant={setSelectedVariant}
            setMatch={setMatch}
            match={match}
            variants={variants}
            selectedVariant={selectedVariant}
            categoryData={categoryData}
            />
          </div>
          <div className="block lg:hidden">
            <FilterMenu
              selected={selected}
              category={category}
              setSelected={setSelected}
              setCurrentBrand={setCurrentBrand}
              setSelectedVariant={setSelectedVariant}
              setMatch={setMatch}
              match={match}
              variants={variants}
              selectedVariant={selectedVariant}
              categoryData={categoryData}
            />
          </div>
        </>
      )}

      {loader ? (
        <div className="py-20">
          <DarkLoader />
        </div>
      ) : (
        <div className="justify-center pl-3 lg:pl-2 ">
          <CardContainer selected={selected} data={data} loader={loader} />
        </div>
      )}
    </div>
  );
};

export default SeeAllHeader;
