import React from "react";
import { Link } from "react-router-dom";

import { brakes } from "../../assets/index";
function PopularCategoryCard({ imgUrl, desc, categoryId }) {
  return (
    <Link
      to={`/seeall/${categoryId}`}
      className="flex items-center
       justify-center h-[150px] 
       max-w-[120px] lg:h-[110px] lg:min-w-[110px]
        lgth:min-w-[11%] 
        overflow-hidden 
       bg-white mb-8 shadow-md 
       rounded-[18px] cursor-pointer 
       mr-[20px] 
       lgth:h-auto lgth:p-[5px]"
    >
      <div className="flex flex-col items-center">
        <img
          src={imgUrl}
          className="max-h-[90px] lg:max-h-[40px] my-1 pt-1  lgth:w-[70%] "
        />
        <p className="text-[14px] lg:text-[11px] lgth:text-[68%] text-center tracking-wider pb-3 pt-3 truncate text-[#333]">
          {desc.length > 15 ? `${desc.substring(0, 15)}` : desc}
        </p>
      </div>
    </Link>
  );
}

export default PopularCategoryCard;
