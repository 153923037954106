import { BASE_URL, TOKEN, USER_ID } from "../keys";
import axios from "axios";
import Geocode from "react-geocode";
const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
};

export const getCurrentAddress = () => {
  return new Promise(async (resolve, reject) => {
    try {


      // navigator.geolocation.getCurrentPosition(function(position) {
      //   console.log("Latitude is :", position.coords.latitude);
      //   console.log("Longitude is :", position.coords.longitude);
      //   Geocode.setApiKey("AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU");
      //   Geocode.fromLatLng(position.coords.latitude.toString(),position.coords.longitude.toString()).then((response) => {
      //     console.log(response)
      //   })
      // });
      const response = await axios.get(`https://geolocation-db.com/json/`);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAddressById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}address/${id}?type=register`, getConfig);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const addNewAddress = (body) => {
console.log("Token: ", TOKEN)

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${BASE_URL}address?userId=${USER_ID}`,
        { ...body, userType: "buyer" },
        getConfig
      );
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAllAddresses = (limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await axios.get(`${BASE_URL}address?type=register`, {
      const response = await axios.get(`${BASE_URL}address?type=register`, {
        ...getConfig,
        params: { limit },
      });
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const addAddress = async (AddressData) => {
  // console.log(TOKEN);
  // console.log(AddressData);
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify(AddressData);
      // console.log(body);
      const response = await axios.post(
        `${BASE_URL}address?userId=${USER_ID}`,
        { ...body, userType: "buyer" },
        getConfig
      );
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err.response);
      return reject(err);
    }
  });
};

export const updateAddress = async (AddressData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify(AddressData);
      // console.log(body);
      const response = await axios.put(`${BASE_URL}address`,
        { ...body, userType: "buyer" },
        getConfig);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err.response);
      return reject(err);
    }
  });
};
