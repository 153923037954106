const ProductData = [
    {
        "_id": "6421285127e07a8ebdf24d79",
        "sellerId": {
            "_id": "64210bcb27e07a8ebdf24c36",
            "storeUrl": "EliteEmporium123",
            "storeName": "EliteEmporium123"
        },
        "cognitoId": "a30442e4-6874-4fda-9833-b9379d3c4154",
        "brandId": {
            "_id": "6421276e27e07a8ebdf24d35",
            "name": "Nioxin System 2"
        },
        "categoryId": {
            "_id": "641801e327e07a8ebdf23d52",
            "name": "Beauty"
        },
        "slug": "Nioxin-System-2-Cleanser-Shampoo-&-Scalp-Therapy-Conditioner-Duo,-33.8-oz-each-56",
        "position": {
            "isSchedule": false,
            "isSale": false,
            "isFeatured": false,
            "isHotdeal": false,
            "isPopular": false,
            "isTrending": false,
            "isClearance": false
        },
        "title": "Nioxin System 2 Cleanser Shampoo & Scalp Therapy Conditioner Duo, 33.8 oz each",
        "shortDescription": "Nioxin System 2 Shampoo & Conditioner is a hair care product designed to improve the appearance of thinning hair. This system is specifically formulated for natural hair with progressed thinning, and includes a shampoo and conditioner that work together to help strengthen hair and promote a healthy scalp environment. ",
        "description": "<p>Nioxin System 2 Shampoo &amp; Conditioner Duo is the perfect choice to get your healthiest, fullest, and cleanest hair yet. This two part Nioxin regimen is specially formluated for natural hair with progressed thinning. The professional derma-purifying Cleanser Shampoo removes dirt, excessive oil and other environmental residues from the scalp and hair while the hair &amp; scalp conditioner helps to retain moisture balance, providing hair resilience reducing breakage and leaves the hair fuller looking.</p>",
        "metaTitle": "",
        "metaDescription": "",
        "metaKeyword": "",
        "media": [
            {
                "name": "ff524869.jpg",
                "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/ff524869.jpg",
                "type": "image",
                "_id": "6421391a27e07a8ebdf24f42"
            }
        ],
        "attributes": {
            "partNumber": 0,
            "condition": "new"
        },
        "soldCount": 0,
        "bidCount": 0,
        "price": 420,
        "salePrice": 300,
        "finalPrice": 120,
        "upsells": [],
        "crosssells": [],
        "lowStockThreshold": 0,
        "quantity": 198,
        "saleStartDate": "2023-03-28T19:00:00.000Z",
        "saleEndDate": "2023-04-27T19:00:00.000Z",
        "isSchedule": false,
        "isSale": true,
        "isFeatured": false,
        "isHotdeal": false,
        "isPopular": false,
        "isCoupon": false,
        "isTrending": false,
        "variants": [
            {
                "sku": "sku-1",
                "data": [
                    {
                        "key": "Warranty",
                        "value": " 6 Months",
                        "type": "selectBox",
                        "_id": "64212b4727e07a8ebdf24e7c"
                    },
                    {
                        "key": "Condition",
                        "value": "New",
                        "type": "selectBox",
                        "_id": "64212b4727e07a8ebdf24e7d"
                    },
                    {
                        "key": "Year",
                        "value": "2021",
                        "type": "selectBox",
                        "_id": "64212b4727e07a8ebdf24e7e"
                    }
                ],
                "shipping": {
                    "weight": 0,
                    "type": "flat",
                    "_id": "64212b4727e07a8ebdf24e7f"
                },
                "price": 420,
                "salePrice": 300,
                "quantity": 99,
                "media": [],
                "order": 1,
                "type": "selectBox",
                "isActive": true,
                "_id": "64212b4727e07a8ebdf24e7b"
            }
        ],
        "shipping": {
            "weight": 1,
            "dimention": {
                "length": 1,
                "width": 1,
                "height": 1
            },
            "type": "flat",
            "_id": "64212b4727e07a8ebdf24e80"
        },
        "linkedProduct": [],
        "type": "variable",
        "discountType": "fixed",
        "sellingType": "buyNow",
        "tags": [],
        "createdBy": null,
        "updatedBy": "64210a9527e07a8ebdf24be2",
        "status": "active",
        "created_at": "2023-03-27T05:22:29.376Z",
        "updated_at": "2023-03-27T06:35:06.437Z"
    },
    {
        "_id": "642130ba27e07a8ebdf24ead",
        "sellerId": {
            "_id": "64210bcb27e07a8ebdf24c36",
            "storeUrl": "EliteEmporium123",
            "storeName": "EliteEmporium123"
        },
        "cognitoId": "a30442e4-6874-4fda-9833-b9379d3c4154",
        "brandId": {
            "_id": "6419552b27e07a8ebdf23fbf",
            "name": "Novarise "
        },
        "categoryId": {
            "_id": "6418096227e07a8ebdf23d96",
            "name": "Home, Furniture, and Appliance Products"
        },
        "slug": "SEVILLE-7PC-Mid-Century-Modern-Wood-Dining-Room-Set,-Walnut/Gray",
        "position": {
            "isSchedule": false,
            "isSale": false,
            "isFeatured": false,
            "isHotdeal": false,
            "isPopular": false,
            "isTrending": false,
            "isClearance": false
        },
        "title": "SEVILLE 7PC Mid Century Modern Wood Dining Room Set, Walnut/Gray",
        "shortDescription": "The SEVILLE 7PC Mid Century Modern Wood Dining Room Set is a stylish and functional furniture set that includes a dining table and six chairs. The set features a beautiful walnut finish on the table top and chair seats, and a gray finish on the chair frames and table legs. ",
        "description": "<p>The SEVILLE 7PC Mid Century Modern Wood Dining Room Set is a stylish and functional furniture set that includes a dining table and six chairs. The set features a beautiful walnut finish on the table top and chair seats, and a gray finish on the chair frames and table legs. The mid-century modern design is sleek and elegant, making it a great addition to any dining room. The table has a spacious rectangular top that can comfortably seat six people, and the chairs have a comfortable and supportive design with a curved backrest and cushioned seat. Overall, this dining room set offers a perfect balance of style and functionality.</p>",
        "metaTitle": "",
        "metaDescription": "",
        "metaKeyword": "",
        "media": [
            {
                "name": "boxton272144.jpeg",
                "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/boxton272144.jpeg",
                "type": "image",
                "_id": "642130c127e07a8ebdf24eb8"
            }
        ],
        "attributes": {
            "partNumber": 0,
            "condition": "new"
        },
        "soldCount": 0,
        "bidCount": 0,
        "price": 6500,
        "salePrice": 5000,
        "finalPrice": 1500,
        "upsells": [],
        "crosssells": [],
        "lowStockThreshold": 0,
        "quantity": 197,
        "saleStartDate": "2023-03-29T19:00:00.000Z",
        "saleEndDate": "2023-04-27T19:00:00.000Z",
        "isSchedule": false,
        "isSale": true,
        "isFeatured": false,
        "isHotdeal": false,
        "isPopular": false,
        "isCoupon": false,
        "isTrending": false,
        "variants": [
            {
                "sku": "sku-1",
                "data": [
                    {
                        "key": "Warranty",
                        "value": "2 Years",
                        "type": "selectBox",
                        "_id": "642130c127e07a8ebdf24eba"
                    },
                    {
                        "key": "Condition",
                        "value": "New",
                        "type": "selectBox",
                        "_id": "642130c127e07a8ebdf24ebb"
                    },
                    {
                        "key": "Year",
                        "value": "2020",
                        "type": "selectBox",
                        "_id": "642130c127e07a8ebdf24ebc"
                    }
                ],
                "shipping": {
                    "weight": 0,
                    "type": "flat",
                    "_id": "642130c127e07a8ebdf24ebd"
                },
                "price": 6500,
                "salePrice": 5000,
                "quantity": 197,
                "media": [],
                "order": 1,
                "type": "selectBox",
                "isActive": true,
                "_id": "642130c127e07a8ebdf24eb9"
            }
        ],
        "shipping": {
            "weight": 1,
            "dimention": {
                "length": 1,
                "width": 1,
                "height": 1
            },
            "type": "flat",
            "_id": "642130c127e07a8ebdf24ebe"
        },
        "linkedProduct": [],
        "type": "variable",
        "discountType": "fixed",
        "sellingType": "buyNow",
        "tags": [],
        "createdBy": null,
        "updatedBy": "64210a9527e07a8ebdf24be2",
        "status": "active",
        "created_at": "2023-03-27T05:59:22.063Z",
        "updated_at": "2023-03-27T05:59:29.731Z"
    },
    {
        "_id": "64214ed427e07a8ebdf250f1",
        "sellerId": {
            "_id": "64210bcb27e07a8ebdf24c36",
            "storeUrl": "EliteEmporium123",
            "storeName": "EliteEmporium123"
        },
        "cognitoId": "a30442e4-6874-4fda-9833-b9379d3c4154",
        "categoryId": {
            "_id": "641801e327e07a8ebdf23d52",
            "name": "Beauty"
        },
        "slug": "Pantene-Pro-V-Daily-Moisture-Renewal-Shampoo",
        "position": {
            "isSchedule": false,
            "isSale": false,
            "isFeatured": false,
            "isHotdeal": false,
            "isPopular": false,
            "isTrending": false,
            "isClearance": false
        },
        "title": "Pantene Pro-V Daily Moisture Renewal Shampoo",
        "shortDescription": "Pantene is a popular brand of hair care products known for its quality and effectiveness. Their shampoos are designed to provide strong, healthy hair and are formulated with a blend of nourishing ingredients to help restore and protect damaged hair.",
        "description": "<p>Pantene is a popular brand of hair care products known for its quality and effectiveness. Their shampoos are designed to provide strong, healthy hair and are formulated with a blend of nourishing ingredients to help restore and protect damaged hair. With a range of products for different hair types, Pantene has something for everyone. Whether you have curly hair, straight hair, or something in between, Pantene can help you achieve the strong, beautiful hair you've always wanted.</p>",
        "metaTitle": "",
        "metaDescription": "",
        "metaKeyword": "",
        "media": [
            {
                "name": "pantene shampoo640584.webp",
                "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/pantene shampoo640584.webp",
                "type": "image",
                "_id": "64214ed727e07a8ebdf250fc"
            }
        ],
        "attributes": {
            "partNumber": 0,
            "condition": "new"
        },
        "soldCount": 0,
        "bidCount": 0,
        "price": 230,
        "salePrice": 200,
        "finalPrice": 30,
        "upsells": [],
        "crosssells": [],
        "lowStockThreshold": 0,
        "quantity": 110,
        "saleStartDate": "2023-03-28T19:00:00.000Z",
        "saleEndDate": "2023-04-26T19:00:00.000Z",
        "isSchedule": false,
        "isSale": true,
        "isFeatured": false,
        "isHotdeal": false,
        "isPopular": false,
        "isCoupon": false,
        "isTrending": false,
        "variants": [
            {
                "sku": "sku-1",
                "data": [
                    {
                        "key": "Warranty",
                        "value": "2 Years",
                        "type": "selectBox",
                        "_id": "64214ed727e07a8ebdf250fe"
                    },
                    {
                        "key": "Condition",
                        "value": "New",
                        "type": "selectBox",
                        "_id": "64214ed727e07a8ebdf250ff"
                    },
                    {
                        "key": "Year",
                        "value": "2021",
                        "type": "selectBox",
                        "_id": "64214ed727e07a8ebdf25100"
                    }
                ],
                "shipping": {
                    "weight": 0,
                    "type": "flat",
                    "_id": "64214ed727e07a8ebdf25101"
                },
                "price": 230,
                "salePrice": 200,
                "quantity": 110,
                "media": [],
                "order": 1,
                "type": "selectBox",
                "isActive": true,
                "_id": "64214ed727e07a8ebdf250fd"
            }
        ],
        "shipping": {
            "weight": 1,
            "dimention": {
                "length": 1,
                "width": 1,
                "height": 1
            },
            "type": "flat",
            "_id": "64214ed727e07a8ebdf25102"
        },
        "linkedProduct": [],
        "type": "variable",
        "discountType": "fixed",
        "sellingType": "buyNow",
        "tags": [],
        "createdBy": null,
        "updatedBy": "64210a9527e07a8ebdf24be2",
        "status": "active",
        "created_at": "2023-03-27T08:07:48.968Z",
        "updated_at": "2023-03-27T08:07:51.794Z"
    },
    {
        "_id": "64d0a72d8819b6839f2db826",
        "sellerId": {
            "_id": "6411fc0227e07a8ebdf23296",
            "storeUrl": "Wonder-Emporium",
            "storeName": "Wonder Emporium"
        },
        "cognitoId": "203e6e8f-e86b-4108-89f4-417e9db95a2c",
        "brandId": {
            "_id": "6412cb0427e07a8ebdf2375c",
            "name": "Luxe Attire"
        },
        "categoryId": {
            "_id": "641810e227e07a8ebdf23daa",
            "name": "Furniture"
        },
        "slug": "Exquisite-Furniture-Collection:-Elevate-Your-Home-with-Timeless-Elegance.",
        "position": {
            "isSchedule": false,
            "isSale": false,
            "isFeatured": false,
            "isHotdeal": false,
            "isPopular": false,
            "isTrending": false,
            "isClearance": false
        },
        "title": "Exquisite Furniture Collection: Elevate Your Home with Timeless Elegance.",
        "shortDescription": "Discover our exquisite furniture collection that brings a touch of timeless elegance to your home. Elevate your living space with beautifully crafted furniture pieces designed to blend seamlessly with your interior style. From classic to contemporary, our curated selection offers both comfort and sophistication. Upgrade your home decor with the finest furniture that complements your aesthetic, making every moment spent at home a truly luxurious experience.",
        "description": "<p>Introducing our Exquisite Furniture Collection, where each piece is meticulously crafted to bring an aura of timeless elegance and sophistication to your living spaces. Transform your home into a haven of luxurious comfort and style with our carefully curated range of furniture that combines exceptional craftsmanship, impeccable design, and a touch of opulence.</p><p>Indulge in the allure of our furniture pieces, each bearing the mark of meticulous artistry and dedication to quality. From sumptuous sofas that invite you to sink into their plush cushions to elegant dining sets that make every meal a grand affair, our collection reflects a seamless fusion of aesthetics and functionality.</p><p>Crafted with an unwavering commitment to excellence, our furniture pieces are not just items of utility; they are statement pieces that define your space and exude your personal style. Delicate detailing, refined materials, and intricate carvings come together in perfect harmony to create furniture that is not just functional, but also a work of art.</p>",
        "metaTitle": "",
        "metaDescription": "",
        "metaKeyword": "",
        "media": [
            {
                "name": "litfad gold round641566.jpeg",
                "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/litfad gold round641566.jpeg",
                "type": "image",
                "_id": "64d0a7348819b6839f2db833"
            }
        ],
        "attributes": {
            "partNumber": 0,
            "condition": "new"
        },
        "soldCount": 0,
        "bidCount": 0,
        "price": 3000,
        "salePrice": 2000,
        "finalPrice": 1000,
        "upsells": [],
        "crosssells": [],
        "lowStockThreshold": 0,
        "quantity": 1997,
        "saleStartDate": "2023-08-29T19:00:00.000Z",
        "saleEndDate": "2023-09-28T19:00:00.000Z",
        "isSchedule": false,
        "isSale": true,
        "isFeatured": false,
        "isHotdeal": false,
        "isPopular": false,
        "isCoupon": false,
        "isTrending": false,
        "variants": [
            {
                "sku": "sku-1",
                "data": [
                    {
                        "key": "Warranty",
                        "value": "3  Years",
                        "type": "selectBox",
                        "_id": "64d0a7348819b6839f2db835"
                    },
                    {
                        "key": "Condition",
                        "value": "New",
                        "type": "selectBox",
                        "_id": "64d0a7348819b6839f2db836"
                    },
                    {
                        "key": "Year",
                        "value": "2021",
                        "type": "selectBox",
                        "_id": "64d0a7348819b6839f2db837"
                    }
                ],
                "shipping": {
                    "weight": 0,
                    "type": "flat",
                    "_id": "64d0a7348819b6839f2db838"
                },
                "price": 3000,
                "salePrice": 2000,
                "quantity": 1997,
                "media": [
                    {
                        "name": "litfad gold round 3276954.jpeg",
                        "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/litfad gold round 3276954.jpeg",
                        "type": "image",
                        "_id": "64d0a7348819b6839f2db839"
                    },
                    {
                        "name": "bed553534.webp",
                        "url": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/bed553534.webp",
                        "type": "image",
                        "_id": "64d0a7348819b6839f2db83a"
                    }
                ],
                "order": 1,
                "type": "selectBox",
                "isActive": true,
                "_id": "64d0a7348819b6839f2db834"
            }
        ],
        "shipping": {
            "weight": 1,
            "dimention": {
                "length": 1,
                "width": 1,
                "height": 1
            },
            "type": "flat",
            "_id": "64d0a7348819b6839f2db83b"
        },
        "linkedProduct": [],
        "type": "variable",
        "discountType": "fixed",
        "sellingType": "buyNow",
        "tags": [],
        "createdBy": null,
        "updatedBy": "641186e127e07a8ebdf2302d",
        "status": "active",
        "created_at": "2023-08-07T08:11:25.935Z",
        "updated_at": "2023-08-07T08:11:32.089Z"
    }
]

export default ProductData;