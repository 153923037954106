import React from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { useContext } from "react";
import appContext from "../../context/AppContext";

function HeaderMenuIcon({ open, ...props }) {
  const { isRtl } = useContext(appContext);
  return (
    <div
      className={`hidden items-center lg:absolute outline-none ${
        isRtl ? "lg:left-5" : "lg:right-5"
      }  lg:inset-y-0 lg:flex`}
    >
      {/* Mobile menu button */}
      <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 outline-none">
        <span className="sr-only">Open menu</span>
        {open ? (
          <XIcon className="block h-6 w-6 outline-none" aria-hidden="true" />
        ) : (
          <MenuIcon className="block h-6 w-6 outline-none" aria-hidden="true" />
        )}
      </Popover.Button>
    </div>
  );
}

export default HeaderMenuIcon;
