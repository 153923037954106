import React, { useState } from "react";
import SpecButton from "../buttons/SpecButton";
import SpecDetails from "./SpecDetails";
import Reviews from "../sections/Reviews";
import { useTranslation } from "react-i18next";
import NoData from "./NoData";

function Specs({ data, reviews }) {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();
  return (
    <div id="specs" className="p-6">
      <div className="flex border-b-[1px] border-[#DCDEE0]">
        <SpecButton
          label={t("desc")}
          active={active}
          number={0}
          onClick={() => setActive(0)}
        />
        <SpecButton
          label={t("specs")}
          active={active}
          number={1}
          onClick={() => setActive(1)}
        />
        <SpecButton
          label={t("compatibility")}
          active={active}
          number={2}
          onClick={() => setActive(2)}
        />
        <SpecButton
          label={t("reviews")}
          active={active}
          number={3}
          onClick={() => setActive(3)}
        />
      </div>
      <div className="px-2 py-4">
        {active == 0 && (
          <SpecDetails body={data?.description ?? ""} type={t("desc1")} />
        )}
        {active == 1 && (
          <SpecDetails body={data?.specification} type={t("specs1")} />
        )}
        {active == 2 && (
          <SpecDetails body={data?.compatibility} type={t("compatibility1")} />
        )}
        {active == 3 && (
          <>
            {reviews.length ? (
              <Reviews reviews={reviews} />
            ) : (
              <div className="py-[10px]">
                <NoData />
              </div>
              // <p className="text-center text-lg my-8">
              //   {`${t("thisProducthas")} ${t("reviews1")} ${t("yet")}.`}
              // </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Specs;
