import React from "react";
import { Link } from "react-router-dom";
import { menu_underline } from "../../../assets/index";
function MenuCard(props) {
  const data1 = [
    { id: 1, name: "Brake - Disc & Drum Kits" },
    { id: 2, name: "Brake Hose, Lines & Fittings" },
    { id: 3, name: "Master Cylinders" },
    { id: 4, name: "Brake Boosters" },
    { id: 5, name: "Ring & Pinion" },
    { id: 6, name: "Brake Rotors" },
  ];

  const data2 = [
    { id: 1, name: "Brake Calipers" },
    { id: 2, name: "Differential" },
    { id: 3, name: "Brake Pads" },
    { id: 4, name: "Line Lock / Roll Control Kits" },
    { id: 5, name: "Brake Fluid" },
  ];

  const data3 = [
    { id: 1, name: "Pedals" },
    { id: 2, name: "Brake Tools" },
    { id: 3, name: "Driveshafts" },
    { id: 4, name: "Emergency Brake" },
    { id: 5, name: "Spools" },
    { id: 6, name: "Vacuum Pumps" },
    { id: 7, name: "Brake Ducting" },
  ];
  return (
    <div
      className={`${
        props.from == "all" ? "bg-[#F5F5F5]" : "bg-[#fff]"
      } w-max p-4  text-zinc-800 lg:hidden`}
    >
      <div className="flex justify-between">
        <p className="text-[14px] font-semibold my-1">
          Browse Categories in {props.data.name}
        </p>
        {/* <p className="text-xs cursor-pointer">see all</p> */}
      </div>
      <img src={menu_underline} />
      <div className="flex">
        <div className="flex-1 flex flex-col justify-center py-3 ">
          {props.data.subCategory.map((item, index) => (
            <Link to={`/seeall/${item.subCategory_id}`} key={index}>
              <p id={item.subCategory_id} className="text-xs py-1">
                {" "}
                {item.name}{" "}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MenuCard;
