import { Fragment, useState } from "react";
import { Listbox, Transition, Popover } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import MenuCard from "../general/card/MenuCard";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailButton from "../buttons/CardButton";
const categories = [
  { name: "Orders", route: "orders" },
  { name: "Addresses", route: "addresses" },
  { name: "Profile", route: "profile" },
  { name: "Whishlist", route: "whishlist" },
  { name: "Reviews", route: "reviews" },
];

export default function AccountDropdown({ onSignout, onNavigate }) {
  const [selected, setSelected] = useState(categories[0]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="w-auto">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative py-2 pl-2 pr-8 text-left bg-white  cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate lgth:text-[12px] lgth3:text-[12px] text-[#333]">
              {" "}
              {t("myAccount")}{" "}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className=" h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 w-44 py-1 mt-1 pt-2 overflow-visible text-base bg-white rounded-md shadow-lg min-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {categories.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `${active ? "text-yellow-400 " : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-2 pr-4 text-xs`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <Popover.Button>
                      <div
                        className="relative group"
                        onClick={() => onNavigate(`/account/${person.route}`)}
                      >
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate text-left text-sm cursor-pointer pl-2`}
                        >
                          {t(person.route)}
                        </span>
                        {/* {selected ? ( */}
                        <span
                          className={`${
                            active ? "text-yellow-400" : "text-gray-900"
                          }
                                absolute inset-y-0 right-2 flex items-center`}
                        ></span>
                        {/* // ) : null} */}
                      </div>
                    </Popover.Button>
                  )}
                </Listbox.Option>
              ))}
              <div className="flex justify-center mb-[5px]">
                <DetailButton
                  bgcolor="#FF9900"
                  text={t("signOut")}
                  onClicked={() => {
                    localStorage.removeItem("matjar_user_data");
                    Cookies.remove("Token");
                    Cookies.remove("email");
                    Cookies.remove("session");
                    // Cookies.remove("session");
                    onSignout();
                  }}
                />
              </div>

              {/* <div
                onClick={() => {
                  localStorage.removeItem("matjar_user_data");
                  onSignout();
                }}
                className="text-center py-2 text-sm text-gray-400"
              >
                {t("signOut")}
              </div> */}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
