import React from "react";
import PopularCategoryCard from "../general/PopularCategoryCard";
import Slider from "react-slick";
import { brakes, batteries, engine, oil, servicekit,  steering, RightArrow, LeftArrow, suspension, tires } from "../../assets/index";
import { useTranslation } from "react-i18next";

function PopularCategories(props) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center pt-12 pb-6 px-8">
      <div className="w-[100%]">
        <div className="flex justify-between">
          <p className="text-xl font-medium">{t("popularCategories")}</p>
        </div>
        <div>
          <div className="flex overflow-x-auto no-scrollbar mt-4 ">
            {props.data.map((item, index) => (
              <PopularCategoryCard
                key={index}
                categoryId={item._id}
                imgUrl={item.image}
                desc={item.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularCategories;
