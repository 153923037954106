import { Fragment, useState, useContext } from "react";
import React from 'react'
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import appContext from "../../context/AppContext";
import Toggle from "./Toggle";
import { toTitleCase } from "../../Helper/helperFunctions";
import FiltersDropdown from "../dropdown/FiltersDropdown";
import useFilterMenu from "../../hooks/useFilterMenu";
import { useNavigate } from "react-router-dom";
import useFilters from "./useFilters";
import Dropdown2 from "./DropDown2";
import Dropdown3 from "./DropDown3";
const sortOptions = [
  { label: "Show all", href: "#", id: 0 },
  { label: "Auction", href: "#", id: 1 },
  { label: "Buy now", href: "#", id: 2 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FilterMenuMobile = ({
  selected,
  setSelected,
  category,
  setSelectedVariant,
  setCurrentBrand,
  setMatch,
  match,
  variants,
  selectedVariant,
  categoryData,
}) => {
  const [open, setOpen] = useState(false);

  const { isRtl } = useContext(appContext);

  const { onSelection, query, sellerId } = useFilterMenu({
    selected,
    setSelected,
    category,
    setSelectedVariant,
    setCurrentBrand,
    setMatch,
    match,
  });
  const navigate = useNavigate();
  const { handleCheckChangeTop, selectedVariants, handleWarrantyTop } = useFilters({
    navigate,
    sellerId,
    category,
  });

  const [conditions, setconditions] = useState("Conditions");
  const [matchs, setmatchs] = useState("Best Match");
  const variations = query.get("variantion") || "";
  const selectedVariations = variations
    .split("|")
    .map((item) => item?.split(","))
    .reduce((prev, next) => prev.concat(next));

  const filters = [
    {
      id: "year",
      name: "Year",
      options: [
        { value: "2011", label: "2011" },
        { value: "2012", label: "2012" },
        { value: "2013", label: "2013" },
      ],
    },
    {
      id: "brand",
      name: "Brand",
      options: [
        { value: "brand 1", label: "Brand 1" },
        { value: "brand 2", label: "Brand 2" },
        { value: "brand 3", label: "Brand 3" },
      ],
    },
    {
      id: "model",
      name: "Model",
      options: [
        { value: "model 1", label: "Model 1" },
        { value: "model 2", label: "Model 2" },
        { value: "model 3", label: "Model 3" },
      ],
    },
    {
      id: "engine",
      name: "Engine",
      options: [
        { value: "engine 1", label: "Engine 1" },
        { value: "engine 2", label: "Engine 2" },
        { value: "engine 3", label: "Engine 3" },
      ],
    },
    {
      id: "bestMatch",
      name: "Best Match",
      options: [
        { value: 1, label: "Best Match", selected: match == 1 },
        { value: 2, label: "Price low to high", selected: match == 2 },
        { value: 3, label: "Price high to low", selected: match == 3 },
      ],
    },
    {
      id: "condition",
      name: "Condition",
      options: [
        { value: "condition 1", label: "Condition 1" },
        { value: "condition 2", label: "Condition 2" },
        { value: "condition 3", label: "Condition 3" },
      ],
    },
  ];

  return (
    <div className="bg-gray-50">
      {/* Mobile filter dialog */}

      <div className="max-w-3xl mx-auto px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
        <section aria-labelledby="filter-heading" className=" py-6">
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <FiltersDropdown
              setSelected1={setSelected}
              selected1={selected}
              data={sortOptions}
              onSelection={onSelection}
              title="Sort"
              type="all"
              match={match}
            />

            <div
              className="flex items-center justify-between no-scrollbar"
              style={{
                overflowX: "scroll",
                overflowY: "visible",
                paddingBottom: "250px",
                marginBottom: "-250px",
              }}
            >
              {variants.map((item, index) => (
                <React.Fragment key={index}>
                  {item.position.plpTop && Boolean(item.value.length) && (
                    <>
                      {item.key == "Warranty" && (
                        <div className="flex text-xs items-center mx-2 mr-6 mt-1 ">
                          <span className="Warranty lg:ml-2 mr-2 text-[#666] ">
                            Warranty
                          </span>
                          <Toggle isRtl={isRtl} onToggle={(state) => {
                            console.log(item.value)
                            if (state) {
                              handleWarrantyTop(item.key, item.value, state)
                            }
                            else {
                              handleWarrantyTop(item.key, item.value, state)
                            }
                          }} />
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}

              {variants
                .sort((a, b) => a.key.localeCompare(b.key))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {item.position.plpTop && (
                      <>
                        {item.key != "Warranty" && (
                          <Dropdown2
                            selectedVariations={selectedVariations}
                            key={item.key}
                            click={(e) => {
                              handleCheckChangeTop(e, item.key);
                            }}
                            label={toTitleCase(item.key)}
                            data={item?.value}
                            category={category}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}

              <Dropdown3
                value={matchs}
                setValue={({ item1, val }) => {
                  setmatchs(item1);
                  onSelection(selected, val);
                }}
                data={["Best Match", "Price low to high", "Price high to low"]}
              />

            </div>

            {/* <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 "
              onClick={() => setOpen(true)}
            >
              Filters
            </button> */}
          </div>
        </section>
      </div>
    </div>
  );
}
export default FilterMenuMobile;
