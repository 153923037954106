import React, { useState } from "react";
import CartContext from "./AppContext";

const ContextState = ({ children }) => {
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const [wishlistItems, setwishlistitems] = useState([]);
  const [settingData, setSettingData] = useState({});
  const [isRtl, setIsRtl] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);
  const [settingLoader, setSettingLoader] = useState(true);
  const [mainCategories, setMainCategories] = useState([]);
  const [error, setError] = useState("");
  const [City, setCity] = useState("");
  const [cartData, setCardData] = useState({});
  const [warrantyData, setWarrantyData] = useState([]);
  const updateCartCount = (count) => {
    console.log("updateCartCount called with count: ", count);
    setCartItems(count);
  };
  const SetWishlist = (arr) => {
    // console.log(arr);
    setwishlistitems(arr);
  };
  const setLoggedIn = (loginState) => {
    // console.log(loginState);
    setIsLoggedIn(loginState);
  };
  const setIsOpen = (isOpenState) => {
    setOpen(isOpenState);
  };
  return (
    <CartContext.Provider
      value={{
        loggedIn,
        isOpen,
        cartItems,
        wishlistItems,
        SetWishlist,
        updateCartCount,
        setLoggedIn,
        setIsOpen,
        setSettingData,
        settingData,
        isRtl,
        setIsRtl,
        sessionModal,
        setSessionModal,
        error,
        setError,
        City,
        setCity,
        setMainCategories,
        mainCategories,
        settingLoader,
        setSettingLoader,
        setCardData,
        cartData,
        setWarrantyData,
        warrantyData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default ContextState;
