import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

export const SideBarItems = ({ name, selected, number, route, key }) => {
  const { t } = useTranslation();
  return (
    <div key={key}
      className={`py-4 cursor-pointer ${
        number == selected ? `text-yellow-500` : "text-gray-800"
      } max-w-[100px] text-sm font-semibold hover:text-yellow-500`}
    >
      {t(route)}
    </div>
  );
};

function AccountSidebar(props) {
  const { pathname } = useLocation();
  let iniSelect = 0;
  const data = [
    { name: "Orders", id: 1, route: "orders" },
    { name: "Addresses", id: 2, route: "addresses" },
    { name: "Profile", id: 3, route: "profile" },
    { name: "Whislist", id: 4, route: "whishlist" },
    { name: "Reviews", id: 5, route: "reviews" },
  ];
  for (const item of data) {
    if (item.route == pathname.split("/").pop()) {
      iniSelect = item.id;
    }
  }
  const navigate = useNavigate();
  return (
    <div className="p-5">
      {data.map((item, ind) => (
        <div key={ind} 
          onClick={() => {
            navigate(item.route);
          }}
        >
          <SideBarItems
            name={item.name}
            key={item.id}
            number={item.id}
            route={item.route}
            selected={iniSelect}
          />
        </div>
      ))}
    </div>
  );
}

export default AccountSidebar;
