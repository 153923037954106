import AuctionCardSm from '../general/card/AuctionCardSm.js';
import BuyNowCardSm from '../general/card/BuNowCardSm.js';
import {
  HotDealsCard,
  HotDealsCardSm,
  SubmitButton,
  useNavigate,
  AuctionCard,
  BuyNowCard
} from './index.js'
const CardContainer = ({ data, selected }) => {
  const navigate = useNavigate();
  const buyNowProducts = data.filter((data) => data.sellingType === "buyNow");
  const auctionProducts = data.filter((data) => data.sellingType === "auction");
  console.log("%c Dataaaaa", "background: black; color: white", data)
 return (
    <>
      {Boolean(data.length) ? (
        <>
          {/* // <div className="flex flex-wrap py-3 justify-start"> */}
          <div className="flex flex-wrap py-3 sm:justify-between sm:px-[4vw]">
            {selected === 0 && data.map((item, index) => {
              // console.log("item.sellingType", item.sellingType)
              return (
                <div
                  key={index}
                  className={`max-w-[280px] sm:w-[46%] w-[80%] mr-[10px] mb-[20px] lgth:w-[172px] lgth2:w-[183px] lgth3:w-[200px] lgth4:w-[209px] lgth5:w-[220px] lgth6:w-[233px] lgth6:mr-[20px]`}
                >
                  {/* ------For Small Screen Size ----------*/}
                  <div className="hidden sm:block">
                    <HotDealsCardSm data={item} selected={selected} />
                  </div>
                  {/* ------For Large Screen Size ----------*/}
                  <div className="sm:hidden">
                    <HotDealsCard data={item} selected={selected} />
                  </div>
                </div>
              )
            }
            )}
            {/* ------------ Auction Products ----------------- */}
            {selected === 1 && auctionProducts.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`max-w-[280px] sm:w-[46%] w-[80%] mr-[10px] mb-[20px] lgth:w-[172px] lgth2:w-[183px] lgth3:w-[200px] lgth4:w-[209px] lgth5:w-[220px] lgth6:w-[233px] lgth6:mr-[20px]`}
                >
                   <div className="hidden sm:block">
                    <AuctionCardSm data={item} selected={selected} />
                  </div>
                  <div className="sm:hidden">
                    <AuctionCard data={item} selected={selected} />
                  </div>
                </div>
              )
            })}
            {/* ------------ BuyNow Products ----------------- */}
            {selected === 2 && buyNowProducts.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`max-w-[280px] sm:w-[46%] w-[80%] mr-[10px] mb-[20px] lgth:w-[172px] lgth2:w-[183px] lgth3:w-[200px] lgth4:w-[209px] lgth5:w-[220px] lgth6:w-[233px] lgth6:mr-[20px]`}
                >
                   <div className="hidden sm:block">
                    <BuyNowCardSm data={item} selected={selected} />
                  </div>
                  <div className="sm:hidden">
                    <BuyNowCard data={item} selected={selected} />
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center py-[50px]">
          <img
            src="/assets/noProducts.png"
            alt="No results"
            className="h-[70px]"
          />
          <p className="mt-[15px] text-[#444] text-center font-bold text-[28px]">
            We couldn't find what you were looking for
          </p>
          <p className="text-[#888] text-center text-[15px]">
            Keep calm and search again. We have so many other products that you
            will like!
          </p>
          <div className="w-fit mt-[20px]">
            <SubmitButton
              label={"Continue Shopping"}
              color="#2C73D2"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          {/* <NoData /> */}
        </div>
      )}
    </>
  );
};

export default CardContainer;
