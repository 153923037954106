import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getCart } from "../../config/Apis/CartApis";
import CartSliderCards from "./card/CartSliderCards";
import { Link } from "react-router-dom";
import appContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import DarkLoader from "./DarkLoader";
import { getProductQuantity } from "../../Helper/helperFunctions";
import EmptyCart from "./EmptyCart";

export default function CartSlider({ open, setOpen }) {
  const { updateCartCount, loggedIn, setSessionModal, setCardData, cartData } =
    useContext(appContext);
  const [loader, setLoader] = useState(true);

  const { t } = useTranslation();
  useEffect(() => {
    if (loggedIn) {
      getData();
    } else {
      setLoader(false);
    }
  }, [open]);

  const getData = async () => {
    try {
      if (open) {
        setLoader(true);
      }
      const response = await getCart();
      console.log("%c Response from get Cart", "background: purple; color: white", response)
      const productQuantity = getProductQuantity(response);
      console.log("%c Response from Product Quantity: ", "background: purple; color: white", productQuantity)

      setCardData(response.data);
      updateCartCount(productQuantity);
    } catch (err) {
      console.log(err);
      if (err.response.status == 401) {
        setSessionModal(true);
      }
    } finally {
      setLoader(false);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden bg-primary">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div
            style={{ zIndex: "10000" }}
            className="pointer-events-none  fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md ">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {t("cart")}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500 outline-none"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon
                            className="h-6 w-6 outline-none"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>

                    {loader ? (
                      <div className="py-[100px]">
                        <DarkLoader />
                      </div>
                    ) : (
                      <div className="mt-8">
                        {Boolean(cartData?.cart?.length) ? (
                          <div className="flow-root">
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-gray-200"
                            >
                              {console.log('cartData', cartData)}
                              {cartData?.cart?.map((item, index) => {
                                if (item.products) {
                                  return (
                                    <div key={index}>
                                      {item.products.map((product, index1) => (
                                        <div key={index1}>
                                          {product?.media && (
                                            <CartSliderCards
                                              setMainLoader={setLoader}
                                              key={index1}
                                              onRefresh={getData}
                                              product={product}
                                              setOpen={setOpen}
                                            />
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        ) : (
                          <EmptyCart />
                        )}
                      </div>
                    )}
                  </div>

                  {Boolean(cartData?.cart?.length) && (
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>{t("subtotal")}</p>
                        <p>AED {cartData?.subTotal}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        {t("subtotal1")}
                      </p>
                      <div className="mt-6">
                        <Link
                          to={"/check-out"}
                          onClick={() => setOpen(false)}
                          className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          {t("checkout")}
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{" "}
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            {t("continueShop")}
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
