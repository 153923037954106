import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import appContext from "../../context/AppContext";
import useMyMaps from "../../hooks/useMyMaps";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import DetailButton from "../buttons/CardButton";
import SubmitButton from "../buttons/SubmitButton";
import NoData from "../general/NoData";
import { getAddressById, getAllAddresses } from "../../config/Apis/AddressApis";
import { currentAddress, USER_ID } from "../../config/keys";
import DarkLoader from "../general/DarkLoader";
import { useNavigate } from "react-router-dom";

const AddressCard = ({
  data,
  selected,
  setSelected,
  setAddressError,
  ...props
}) => {
  return (
    <div
      onClick={() => {
        setSelected(data);
        setAddressError(false);
      }}
      className={`w-full bg-[#f5f5f5] h-[150px] my-[10px] py-[10px] px-[15px] flex flex-col justify-center cursor-pointer rounded-lg ${
        selected._id == data._id
          ? "border-blue-600  border-[1px] border-solid"
          : ""
      }`}
    >
      <div className="flex mb-[10px]">
        <div className="w-[70px] text-[13px] text-[#444]">Name:</div>
        <div className="text-[13px] text-[#333] font-semibold">
          {data.firstName}
        </div>
      </div>
      <div className="flex mb-[10px]">
        <div className="w-[70px] text-[13px] text-[#444]">Address:</div>
        <div className="text-[13px] text-[#333] font-semibold">
          {data.address1}
        </div>
      </div>
      <div className="flex mb-[10px]">
        <div className="w-[70px] text-[13px] text-[#444]">Phone:</div>
        <div className="text-[13px] text-[#333] font-semibold">
          {data.phone1}
        </div>
      </div>
    </div>
  );
};

const AddressSelect = ({
  setSelectedAddress,
  selectedAddress,
  setAddressError,
  loader,
  setLoader,
  addressData,
}) => {
  return (
    <div className="h-[400px] overflow-y-auto">
      {loader ? (
        <div className="h-[100%] py-[85px]">
          <DarkLoader />
        </div>
      ) : (
        <>
          {Boolean(addressData.length) ? (
            <>
              {addressData.map((item) => (
                <AddressCard
                  data={item}
                  selected={selectedAddress}
                  setSelected={setSelectedAddress}
                  setAddressError={setAddressError}
                />
              ))}
            </>
          ) : (
            <div className="h-full flex items-center justify-center">
              <NoData />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Map = (props) => {
  const { coordinates, onDrag, onMapClicked, onMarker, setCoordinates } =
  useMyMaps({onChanged: props.onChanged});
  return (
    <GoogleMap
      onClick={onMapClicked}
      defaultZoom={10}
      defaultCenter={coordinates}
    >
      {props.isMarkerShown && (
        <Marker
          draggable
          ref={onMarker}
          onDrag={onDrag}
          // onPositionChanged={onPositionChanged}
          position={coordinates}
        />
      )}
    </GoogleMap>
  );
};

export default function DeliveryModal({
  closeModal,
  isOpen,
  onNewAddress,
  label,
  onConfirm,
}) {
  const currentUrl = window.location.href;
  // console.log(currentUrl);
  const { loggedIn } = useContext(appContext);
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressError, setAddressError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [addressData, setAddressData] = useState([]);

  useEffect(() => {
    getAddresses();
  }, []);

  const getAddresses = async () => {
    // setLoader(true);
    try {
      const res = await getAllAddresses();
      // const res = await getAddressById();

      setSelectedAddress(
        res.data.list.find((item) => item._id == currentAddress?._id) || {}
      );
// setSelectedAddress(res.data.list)
      setAddressData(res.data.list);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const onAddressConfirm = () => {
    if (Object.entries(selectedAddress).length) {
      onConfirm(selectedAddress);
    } else {
      setAddressError(true);
    }
  };
  const WrappedMap = withScriptjs(withGoogleMap(Map));

  const LocationSelect = () => {
    return (
      <>
        <WrappedMap
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `300px`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
          // onChanged={(val) => setCoordinates(val)}
        />
        <div className="h-[15px]" />
        <SubmitButton color={"#2c73d2"} label="Confirm" />
      </>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="h-[450px] flex flex-col justify-center bg-bl mx-auto w-[90%] sm:px-6 lg:px-4">
                  <p className="text-[18px] font-semibold">Select Address</p>
                  {loggedIn ? (
                    <>
                      <AddressSelect
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        setAddressError={setAddressError}
                        loader={loader}
                        setLoader={setLoader}
                        addressData={addressData}
                      />
                    </>
                  ) : (
                    <LocationSelect />
                  )}
                  {loggedIn && (
                    <>
                      {!loader && (
                        <div className="flex">
                          <SubmitButton
                            color={"#2c73d2"}
                            label="Add New Address"
                            onClick={onNewAddress}
                          />
                          {Boolean(addressData.length) && (
                            <>
                              <div className="w-[150px]" />
                              <SubmitButton
                                color={"#FF9900"}
                                label="Confirm"
                                onClick={onAddressConfirm}
                                loader={
                                  !Boolean(
                                    Object.entries(selectedAddress).length
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {addressError && (
                    <p className="text-[13px] text-red-600 text-center mt-[10px]">
                      Please select an address first.
                    </p>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
