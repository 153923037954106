import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import ReactStars from "react-rating-stars-component";
import { addProduct } from "../../config/Apis/CartApis";
import ImagePlaceHolder from "../../assets/imgplace.png";
import {
  addWishlist,
  getWishlist,
  RemoveWishList,
} from "../../config/Apis/WishlistApi";

import {
  whishlist,
  HotDealImage,
  AunctionIcon,
  WishlistSelected,
  EyeIcon,
  CartUpIcon,
  ShareIcon,
} from "../../assets/index";
import ShareModal from "../modals/ShareModal";
import DarkLoaderSm from "../general/DarkLoaderSm";
import QuantityDropDown from "../dropdown/QuantityDropDown";
import appContext from "../../context/AppContext";
import {
  averageFunc,
  getProductQuantity,
  getTimeRemaining,
  helperFunctions,
} from "../../Helper/helperFunctions";
import { getReviews } from "../../config/Apis/ReviewsApi";
import { useTranslation } from "react-i18next";
import BidModal from "../modals/BidModal";
import MainInput from "../general/MainInput";
import { StarIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function ProductDetail({ data, reviewsData }) {
  const navigate = useNavigate();
  console.log("%c data from productDetail:", "background: pink; color: black", data)

  // console.log(data);
  let variantImages;
  if (data?.variants?.media?.length) {
    variantImages = data?.variants?.map((item) => item?.media[0]);
  }
console.log("%c data Images", "background: purple; color: white", data)
 /*  const [currentImage, setCurrentImage] = useState(
    data?.variants[0]?.media?.length ? variantImages[0]?.url : data?.media[0]?.url ?? ""
  ); */
  const [currentImage, setCurrentImage] = useState(
    data?.variants && data.variants.length > 0 && data.variants[0]?.media?.length
    ? variantImages && variantImages.length > 0 ? variantImages[0]?.url : ""
    : data?.media && data.media.length > 0 ? data.media[0]?.url : ""
);

  console.log("%c Variants Image", "background: purple; color: white", data)
  const [currentImage1, setCurrentImage1] = useState(0);
  const [selectedvariant, setSelectedVariant] = useState({});
  const [addToCardLoader, setAddToCardLoader] = useState(false);
  const [buyNowLoader, setBuyNowLoader] = useState(false);
  const [bidLoader, setBidLoader] = useState();
  const [loader, setloader] = useState(true);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bidShow, setBidShow] = useState(false);
  const [bidAmount, setBidAmount] = useState();
  const [isBidSelected, setIsBidSelected] = useState(false);
  const [bidAmountError, setBidAmountError] = useState("");
  const [quantity, setQuantity] = useState(
    data.cart ? (data?.cart?.length ? data?.cart[0]?.quantity : 1) : 1
  );
  // const [quantity, setQuantity] = useState(data.cartQuantity);
  const [isfavorite, setIsfavorite] = useState(data.isWished);
  const {
    updateCartCount,
    setIsOpen,
    loggedIn,
    SetWishlist,
    wishlistItems,
    isRtl,
  } = React.useContext(appContext);

  const { t } = useTranslation();
  React.useEffect(() => {
    // console.log(wishlistItems);
    // console.log("whishlist items --------> ", wishlistItems);
    if (wishlistItems?.length > 0) {
      wishlistItems.map((item) => {
        // console.log(data._id);
        if (item._id == data._id) {
          setIsfavorite(true);
        }
      });
    }

    if (data?.variants?.length > 0) {
      setSelectedVariant({
        id: data?.variants[0]?._id,
        name: data?.variants[0]?.sku,
        size: data?.variants[0]?.data[1]?.value ?? "",
        color: data?.variants[0]?.data[0]?.value ?? "",
        quantity: data?.variants[0]?.quantity,
      });
      setloader(false);
    }
  }, []);

  const openImageViewer = React.useCallback((index) => {
    console.log(index);
    setCurrentImage1(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage1(0);
    setIsViewerOpen(false);
  };

  const averageRating = averageFunc(reviewsData.map((item) => item.ratingStar));
  const discounts =
    data?.type == "variable"
      ? helperFunctions(data?.variants[0]?.salePrice, data?.variants[0]?.price)
      : helperFunctions(data?.salePrice, data?.price);
  // console.log(discounts);
  const addWish = () => {
    if (loggedIn) {
      setIsfavorite(true);
      addWishlist(data?._id)
        .then((res) => {
          getWishlist()
            .then((res) => {
              SetWishlist(res.data.list);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsOpen(true);
    }
  };

  const removeWish = () => {
    setIsfavorite(false);
    RemoveWishList(data?._id)
      .then((res) => {
        getWishlist()
          .then((res) => {
            SetWishlist(res.data.list);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { attributes } = data;
  /* const cartAction2 = (setLoader1, type) => {
   /*---  if (loggedIn) {
        setLoader1(true);
        updateCartCount((prev) => prev + 1);
       //updateCartCount(1);
          
        setLoader1(false);
        if (type === "buy") {
          navigate("/check-out");
        } ----//
        if (quantity > 0) {
          setLoader1(true);
        addProduct(data._id, quantity, selectedvariant.id)
          .then((res) => {
            // console.log(res);
            const productQuantity = getProductQuantity(res);
           // updateCartCount(productQuantity);
           setLoader1(false);
            if (type == "buy") {
              navigate("/check-out");
            }
          })
          .catch((err) => {
            if (type == "buy") {
              navigate("/check-out");
            }
            console.log(err);
            setLoader1(false);
          });
      
    } else {
      setIsOpen(true);
    }
  }; */
  const cartAction = (setLoader1, type) => {
    console.log("%c type", "background: grey; color: black", type)

    if (loggedIn) {
      if (quantity > 0) {
        setLoader1(true);
        addProduct(data._id, quantity, selectedvariant.id)
          .then((res) => {
            console.log("%c res", "background: grey; color: black", res)
            // console.log(res);
            const productQuantity = getProductQuantity(res);
            updateCartCount(productQuantity);
            setLoader1(false);
            if (type == "buy") {
              navigate("/check-out");
            }
          })
          .catch((err) => {
            console.log(err);
            setLoader1(false);
          });
      }
    } else {
      setIsOpen(true);
    }
  }
  const timeLeft = getTimeRemaining(data.auction?.endingDate);
  const bidPercent = data.auction?.openingPrice * 0.03 || 0;

  const onAuctionPlaced = () => {
    if (timeLeft == "0d 0h") return;

    setBidAmountError("");
    if (loggedIn) {
      if (bidAmount) {
        if (bidAmount < data.auction.openingPrice + bidPercent) {
          console.log("inside if");
          setBidAmountError(
            `Bid amount must be greater than ${
              data.auction.openingPrice + bidPercent
            }`
          );
        } else {
          setIsBidSelected(true);
          setBidShow(true);
        }
      } else {
        setBidShow(true);
      }
    } else {
      setIsOpen(true);
    }
  };
  // const timeLeft = getTimeRemaining();
  const SizeButton = (props) => {
    return (
      <div
        className=" mx-1 flex py-1 px-4 bg-[#F7F7FA] items-center justify-center rounded-md border-[1px] border-[#E9EAEB] hover:cursor-pointer"
        onClick={() =>
          setSelectedVariant({
            ...selectedvariant,
            size: props.size,
          })
        }
      >
        <p
          className={`text-[12px] text-[${
            selectedvariant.size == props.size ? "#000000" : "#15AF84"
          }] font-normal}`}
        >
          {props.size}
        </p>
      </div>
    );
  };

  const DetailButton = (props) => {
    return (
      <div
        style={{ backgroundColor: quantity > 0 ? props.color : "#e5e5e5" }}
        className={`mr-1 flex py-[8px] min-w-[220px] sm:min-w-[130px] px-8 items-center justify-center rounded-md cursor-pointer`}
        {...props}
      >
        <div className={`text-[12px] lg:text-[11px] text-[#fff] font-normal}`}>
          {props.loader ? <DarkLoaderSm /> : props.text}
        </div>
      </div>
    );
  };
  const DetailButtonAuction = (props) => {
    return (
      <div
        style={{ backgroundColor: props.disabled ? "#e5e5e5" : props.color }}
        className={`flex py-[8px] min-w-[173px] sm:min-w-[130px] px-8 items-center justify-center rounded-md ${
          props.disabled ? "" : "cursor-pointer"
        }`}
        {...props}
      >
        <div className={`text-[12px] lg:text-[11px] text-[#fff] font-normal}`}>
          {props.loader ? <DarkLoaderSm /> : props.text}
        </div>
      </div>
    );
  };

  const ColorBox = ({ myClass, color, variantdata }) => {
    // console.log(color);
    return (
      <div
        style={{
          backgroundColor: color,
          borderWidth: color == selectedvariant.color ? 4 : 0,
          borderColor: "darkgray",
        }}
        className="mx-2 w-[32px] h-[32px] border-[1px] border-[#E9EAEB] p-[2px] rounded-sm"
        onClick={() => {
          setSelectedVariant({
            id: variantdata.variantid,
            name: variantdata.variantname,
            size: data.variants[variantdata.index].data[1].value,
            color: color,
            quantity: data.variants[variantdata.index].quantity,
          });
          setQuantity(1);
        }}
      >
        <div className={myClass}></div>
      </div>
    );
  };

  const shortDescription = data?.shortDescription;
  const [showMore, setshowMore] = useState('');


  return (
    <div className="flex bg-grey-500 w-[100%] px-6 justify-between lg:flex-col ">
      <div className="w-[40%] lg:w-[90%] lg:mx-auto px-4 items-center flex flex-col ">
        <img
          src={currentImage || ImagePlaceHolder}
          style={{ pointerEvents: "all" }}
          onClick={() => openImageViewer(0)}
          // onClick={() => console.log("clicked")}
          className="h-[250px]"
        />
        {/* <p> hello</p> */}
        {data.variants?.media?.length ? (
          <div className="flex my-2 justify-center">
            {variantImages.map((item, index) => {
              console.log("ietmmmmm", item)

              return (
                <div
                  key={index}
                  className="w-[70px] h-[70px] p-1 mx-1 border-[1px] border-[#DCDEE0]  hover:cursor-pointer"
                >
                  <img
                    src={item?.url ?? ""}
                    onClick={() => setCurrentImage(item?.url ?? "")}
                    className="w-[100%] h-[100%]"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex my-2 justify-center">
           {data?.media && data?.media.map((item, index) => {
              console.log("ietmmmmm", item)
              return (
                <div
                  key={index}
                  className="w-[70px] h-[70px] p-1 mx-1 border-[1px] border-[#DCDEE0]  hover:cursor-pointer"
                >
                  <img
                    src={item?.url ?? ImagePlaceHolder}
                    onClick={() => setCurrentImage(item?.url ?? "")}
                    className="w-[100%] h-[100%]"
                  />
                </div>
              );
            })}
          </div>
        )}
        {data.variants?.media?.length ? (
          <>
            {isViewerOpen && (
              <ImageViewer
                src={variantImages.map((item) => item.url)}
                currentIndex={currentImage1}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </>
        ) : (
          <>
            {isViewerOpen && (
              <ImageViewer
                src={data?.media.map((item) => item.url)}
                currentIndex={currentImage1}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </>
        )}
        <div className="flex xl:w-full w-[365px] md:w-[92%] sm2:w-[85vw] border-[1px] bg-[#ECFDF5] border-[#15AF84] rounded-sm justify-center p-[8.5px]">
          <img src={EyeIcon} className="h-[14px]" />
          <p className="mx-2 text-xs font-normal text-[#15AF84]">
            {" "}
            <span className="font-semibold">0</span> {t("peopleAreLooking")}
          </p>
        </div>
      </div>

      <div className="w-[60%] lg:mx-auto lg:w-[90%] lg:my-2 ">
        <p className="text-[14px] font-normal"> {data?.brandId?.name} </p>
        <p className="text-[26px] uppercase font-medium">{data?.title ?? ""}</p>
        {Boolean(attributes?.partNumber) && (
          <p className="text-[14px] font-normal text-[#666666]">
            Part Number: {attributes.partNumber}
          </p>
        )}
        <div className="flex sm:flex-col justify-between w-[100%]">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={classNames(
                    averageRating > rating ? "text-[#FB8200]" : "text-gray-300",
                    "flex-shrink-0 h-4 w-4"
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
            <a href="#specs" className="text-[12px] font-normal text-[#666666]">
              ({reviewsData.length} {t("reviews")}){console.log(reviewsData)}
            </a>
          </div>

          <div className="flex items-center">
            {data?.sellingType !== "auction" && (
              <div className="flex px-[6px] py-[4px] mx-[3px] bg-[#FFEDED] rounded-[3px] border-[1px] border-[#E24545] items-center justify-center">
                <img src={CartUpIcon} className="h-[18px] mx-1" />
                <p className="text-[12px] lg:text-[10px] text-[#E24545] font-normal">
                  <span className="font-medium">{data.soldCount}</span>{" "}
                  {t("sold")}
                </p>
              </div>
            )}

            <div className="flex px-[6px] py-[4px] lg:py-[5.5px] bg-[#ECFDF5] items-center justify-center rounded-[3px] border-[1px] border-[#15AF84]">
              <img src={EyeIcon} className="h-[14px] mx-1" />
              <p className="text-[12px] lg:text-[10px] text-[#15AF84] font-normal">
                <span className="font-medium">0</span> {t("watching")}
              </p>
            </div>
            {isfavorite ? (
              <img
                src={WishlistSelected}
                className="h-[20px] mx-[8px] cursor-pointer"
                onClick={removeWish}
              />
            ) : (
              <img
                src={"/assets/heartUnfilled.svg"}
                className="h-[20px] mx-[8px] cursor-pointer"
                onClick={addWish}
              />
            )}

            <img
              src={"/assets/share.svg"}
              onClick={() => setShowModal(true)}
              className="h-[20px] cursor-pointer"
            />
            <ShareModal
              isOpen={showModal}
              closeModal={() => setShowModal(false)}
              openModal={() => setShowModal(true)}
            />
          </div>
        </div>

        {data?.isSale && (
          <>
            <p className="text-[16px] font-normal text-[#AAAAAA]">
              {data?.type == "variable" ? (
                <s>AED {data?.variants[0]?.price}</s>
              ) : (
                <s>AED {data.price}</s>
              )}
            </p>
          </>
        )}
        <div className="flex items-baseline">
          <p className="text-[26px] font-medium">
            {data?.sellingType == "auction"
              ? `AED ${data?.auction?.openingPrice}`
              : `AED ${data.salePrice}`}
          </p>

          {data?.isSale && (
            <p className="mx-2 text-[14px] font-medium text-[#CC0000]">
              You Saved (AED {discounts.SavedAmount}){" "}
              {discounts.SavedPercent.toFixed(2)}%
            </p>
          )}
        </div>

        <ul className="list-none my-4">
          {/* <p
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            className={`text-[12px] text-[#666666]  max-w-[250px] ${(shortDescription.split('').length < 95 || showMore === data._id) ? "" : "h-[54px]"} overflow-hidden`}
          /> */}
           <p
        dangerouslySetInnerHTML={{ __html: shortDescription }}
        className={`text-[12px] text-[#666666]  max-w-[250px] ${
            shortDescription && shortDescription.split('').length < 95 || showMore === data._id
                ? "" 
                : "h-[54px]"
        } overflow-hidden`}
    />
        {
            ( shortDescription && shortDescription?.split('').length > 95 && showMore !== data._id) &&
            <span
              onClick={() => setshowMore(data._id)}
              className="text-[12px] text-[#666666]  max-w-[250px] hover:cursor-pointer"
            >show more</span>
          }
        </ul>
        {attributes?.condition && (
          <div className="flex">
            <p className="text-[12px] font-normal text-[#666666] min-w-[70px]">
              Condition:
            </p>
            <p className="text-[12px] font-medium">{attributes?.condition}</p>
          </div>
        )}

        {attributes?.warranty && (
          <div className="flex">
            <p className="text-[12px] font-normal text-[#666666] min-w-[70px]">
              Warranty:
            </p>
            <p className=" text-[12px] font-medium">{attributes?.warranty}</p>
          </div>
        )}
        {Boolean(data?.variants?.length) && (
          <div className="flex items-center my-2">
            <p className="text-[12px] font-normal text-[#444444]">Color:</p>

            {data?.variants.map((variant, index) =>
              variant?.data.map((item, index1) => {
                if (item.key == "color") {
                  return (
                    <ColorBox
                      key={index1}
                      myClass={`w-[100%] h-[100%] rounded-sm`}
                      color={item.value}
                      variantdata={{
                        variantname: variant.sku,
                        variantid: variant._id,
                        index: index,
                      }}
                    />
                  );
                }
              })
            )}
          </div>
        )}
        {Boolean(data?.variants?.length) && (
          <div className="flex items-center my-2">
            <p className="text-[12px] font-normal text-[#444444]">Size:</p>
            {data?.variants.map((variant) =>
              variant.data.map((item, index) => {
                if (item.key == "size" && variant.sku == selectedvariant.name) {
                  return <SizeButton key={index} size={item.value} />;
                }
              })
            )}
          </div>
        )}

        {data.sellingType !== "auction" && (
          <div className="flex justify-start  text-sm  items-center my-2">
            <input
              type="number"
              name="quantity"
              id="quantity"
              value={quantity}
              onChange={(e) => {
                if (e.target.value > data?.quantity) return;
                setQuantity(e.target.value);
              }}
              min={1}
              max={data?.quantity}
              className="appearance-none block w-[70px] mr-2 text-xs px-2 text-center py-2 border border-[#EDEDED] bg-transparent outline-none shadow-sm placeholder-gray-400"
            />

            <div
              className={`border-t-transparent border-b-transparent border-t-[8px] border-b-[8px] border-solid ${
                isRtl
                  ? "border-l-[8px] border-l-[#4B5C6B]"
                  : "border-r-[8px] border-r-[#4B5C6B]"
              } `}
            ></div>
            <div className="text-xs bg-[#4B5C6B] text-white p-2">
              {t("hurry")}{" "}
              <span className="text-yellow-500 font-semibold">
                {data.quantity - data.soldCount}
              </span>{" "}
              {t("leftInStock")}
            </div>
          </div>
        )}
        {/* <QuantityDropDown/> */}
        <div className="flex">
          <p className="text-[14px] font-medium">Shipping: </p>
          <p className="mx-1 text-[14px] font-normal">
            AED 50.00 |{" "}
            <span className="text-[12px] font-normal">
              From <span className="font-medium">Sharjah</span> to{" "}
              <span className="font-medium">Dubai</span> via Aramex
            </span>{" "}
          </p>
        </div>
        <div className="flex">
          <p className="text-[14px] font-medium">Delivery On: </p>
          <p className="mx-1 text-[14px] font-normal">25th February 2022</p>
        </div>

        {data.sellingType !== "auction" && (
          <div className="flex my-2">
            <DetailButton
              text={t("buyNow")}
              color="#2C73D2"
              loader={buyNowLoader ? 1 : 0}
              onClick={() => cartAction(setBuyNowLoader, "buy")}
            />

            <DetailButton
              text={t("addToCart")}
              color="#FF9900"
              loader={addToCardLoader ? 1 : 0}
              onClick={() => cartAction(setAddToCardLoader, "cart")}
            />
          </div>
        )}
        {data.sellingType == "auction" && (
          <div className="w-[300px] sm:w-[100%]">
            <MainInput
              type="number"
              inputLabel={"AED"}
              notRequired={true}
              onChange={(e) => {
                setBidAmountError("");
                setBidAmount(e.target.value);
              }}
              value={bidAmount}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  onAuctionPlaced();
                }
              }}
            />
          </div>
        )}

        {bidAmountError && (
          <div className="text-[13px] text-red-800 mt-[10px]">
            {bidAmountError}
          </div>
        )}
        {data.sellingType == "auction" && (
          <div className="flex my-2">
            <DetailButtonAuction
              text={t("placeBid")}
              color="#2C73D2"
              loader={bidLoader}
              disabled={timeLeft == "0d 0h"}
              onClick={onAuctionPlaced}
            />
          </div>
        )}

        {timeLeft == "0d 0h" && data.sellingType == "auction" && (
          <p className="font-semibold text-red-600 w-[100%] text-[12px]">
            {t("auctionExpire")}
          </p>
        )}

        {data.sellingType == "auction" && (
          <>
            {bidShow && (
              <BidModal
                closeModal={() => {
                  setBidShow(false);
                  setIsBidSelected(false);
                }}
                isOpen={bidShow}
                data={data}
                bidAmount={bidAmount}
                setBidAmount={setBidAmount}
                isBidSelected={isBidSelected}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductDetail;
