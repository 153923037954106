import React, { useContext, useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import { useParams } from "react-router-dom";
import DetailButton from "../components/buttons/CardButton";
import AddressSection from "../components/sections/AddressSection";
import EditAddressSection from "../components/sections/EditAddressSection";
import DarkLoader from "../components/general/DarkLoader";

import { getAddressById } from "../config/Apis/AddressApis";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import appContext from "../context/AppContext";

const Map = (props) => {
  const [coordinates, setCoordinates] = useState({
    lat: props.data.cordinate[0],
    lng: props.data.cordinate[1],
  });

  const mapRef = useRef();
  const refs = {};
  useEffect(() => {}, []);

  const onMarker = (ref) => {
    refs.marker = ref;
  };

  const onDrag = (e) => {
    props.onChanged({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const onMapClicked = (e) => {
    const { latLng } = e;
    console.log(latLng);
    let lat = latLng.lat();
    let lng = latLng.lng();
    setCoordinates({ lat, lng });
    props.onChanged({ lat, lng });
  };

  return (
    <GoogleMap
      onClick={onMapClicked}
      defaultZoom={10}
      defaultCenter={coordinates}
    >
      {props.isMarkerShown && (
        <Marker
          draggable
          ref={onMarker}
          onDrag={onDrag}
          // onPositionChanged={onPositionChanged}
          position={coordinates}
        />
      )}
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

function EditAddress({ data, siteTitle }) {
  const { setError } = useContext(appContext);
  const [loader, setLoader] = useState(true);
  const [addressData, setAddressData] = useState({});
  const [location, setLocation] = useState({});
  const [coordinates, setCoordinates] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });
  const { addressId } = useParams();
  const { t } = useTranslation();
  const getAddressData = async () => {
    try {
      setLoader(true);
      const response = await getAddressById(addressId);
      console.log(response.data);
      setAddressData(response.data);
      setLocation({
        country: response.data.country,
        state: response.data.state,
        city: response.data.city,
        address1: response.data.address1,
        cordinate: response.data.cordinate,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAddressData();
  }, []);
  Geocode.setApiKey("AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU");
  Geocode.setLanguage("en");
  // Geocode.setLocationType("ROOFTOP");

  const onLocationSet = () => {
    // console.log(coordinates);
    Geocode.fromLatLng(coordinates.lat.toString(), coordinates.lng.toString())
      .then((response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, completeAddress;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // console.log(response?.plus_code?.compound_code ?? "");
        completeAddress = response?.plus_code?.compound_code ?? "";
        // console.log(city, state, country);
        setLocation({ city, state, country, completeAddress });
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        window.scrollTo(0, 0);
      });
  };

  return (
    <div className="w-[90%] mx-auto">
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <>
          <Helmet>
            <title>{`Edit Address | ${siteTitle}`}</title>
          </Helmet>
          <div className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
            {t("editAddress")}
          </div>
          <WrappedMap
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onChanged={(val) => setCoordinates(val)}
            data={addressData}
          />
          <div className="w-36 my-2 ">
            <DetailButton
              onClicked={onLocationSet}
              bgcolor={"#2c73d2"}
              text={t("confirm")}
            />
          </div>
          <EditAddressSection
            data={addressData}
            location={location}
            coordinates={coordinates}
          />
        </>
      )}
    </div>
  );
}

export default EditAddress;
