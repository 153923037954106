import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DetailButton from "../../buttons/CardButton";

const AddressCardItem = ({ title, desc }) => {
  return (
    <div className="max-w-[200px] min-w-[200px] md:my-2">
      <div className="text-gray-500 text-xs"> {title} </div>
      <div className="text-sm"> {desc} </div>
    </div>
  );
};

function AddressCard({ data }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="bg-[#fff] my-2 sm:w-[95%]" key={data._id}>
      <div className="flex py-4 px-6 justify-between md:flex-col">
        <AddressCardItem
          title={t("name")}
          desc={`${data.firstName} ${data.lastName}`}
        />
        <AddressCardItem title={t("address")} desc={data.address1} />
        <AddressCardItem title={t("phone")} desc={data.phone1} />
        <div className="w-24">
          <DetailButton
            text={t("edit")}
            bgcolor="rgb(44, 115, 210)"
            onClicked={() => navigate(`/account/editaddress/${data._id}`)}
          />
        </div>
      </div>
    </div>
  );
}

export default AddressCard;
