import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Buffer } from "buffer";
import CartState from "./context/CartState";
import "./i18nextConf";
import { ErrorBoundary } from "react-error-boundary";
import { AuthProvider } from "./context/AuthContext";
import { createRoot } from 'react-dom/client';
window.Buffer = Buffer;
const container =  document.getElementById("root")
const root = createRoot(container); 
root.render(
  <React.StrictMode>
    <AuthProvider>
    <CartState>
      <App />
    </CartState>
    </AuthProvider>
  </React.StrictMode>
  );

reportWebVitals();
