import React from "react";

function SpecButton({ active, label, number, ...otherProps }) {
  return (
    <p
      id="reviews"
      {...otherProps}
      className={`text-sm mr-4 cursor-pointer font-normal lg:text-xs ${
        active == number
          ? "text-[#FF9900] border-b-2 border-[#FF9900] pb-2"
          : ""
      } `}
    >
      {label}
    </p>
  );
}

export default SpecButton;
