import { BASE_URL, TOKEN } from "../keys";
import axios from "axios";

let data = JSON.parse(localStorage.getItem("matjar_user_data"));

const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${data?.token ?? ""}`,
  },
};

export const getShippingMethods = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}shipping`, getConfig);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
