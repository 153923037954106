import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import {
  useLocation,
  useNavigate,
  Link,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SideBarItems } from "../sidebar/AccountSidebar";
import CheckBox from "../general/CheckBox";
import SubCategory from "../general/SubCategory";
import { searchIcon } from "../../assets/index";
import { getMenu } from "../../config/Apis/CategoryApis";
import appContext from "../../context/AppContext";
import { toTitleCase } from "../../Helper/helperFunctions";
import { useQuery } from "../../hooks/useQuery";
import useFilters from "./useFilters";

function SidebarContent({
  open,
  setOpen,
  category,
  categories,
  brands,
  variants,
  fixedBrands,
  setBrands,
  currentBrand,
  selectedVariant,
  minLimit,
  maxLimit,
  ...props
}) {
  const navigate = useNavigate();
  const { isRtl } = useContext(appContext);

  const [Categories, setCategories] = useState([]);
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [selected, setSelected] = useState("");
  const { t } = useTranslation();
  const query = useQuery();
  const sellerId = query.get("sellerId") || "";
  React.useEffect(() => {
    console.log("variants", variants);
  }, []);
  const {
    handleCheckChange,
    selectedBrand,
    setSelectedBrand,
    selectedVariants,
    setSelectedVariants,
    finalVariants,
    setFinalVariants,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
  } = useFilters({ setOpen, sellerId, navigate, category });

  const handleSearchBrand = (val) => {
    console.log(val);
    console.log(fixedBrands);
    if (!val) {
      setBrands(fixedBrands);
    } else {
      const arr = fixedBrands.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
      setBrands(arr);
    }
  };
console.log("%c Fixed Brand", "background: purple; color: white", fixedBrands)
  const handleBrandCheck = (e, setState) => {
    const isChecked = e.target.checked;
    console.log();
    if (isChecked) {
      setState(e.target.value);
      const params = {
        price1: minPrice,
        price2: maxPrice,
        variantion: finalVariants,
        brandId: e.target.value,
        sellerId,
      };
      setOpen(false);
      navigate({
        pathname: category ? `/seeall/${category}` : "/seeall",
        search: `?${createSearchParams(params)}`,
      });

      window.scrollTo(0, 100);
    } else {
      setState("");
      setOpen(false);
      const params = {
        price1: minPrice,
        price2: maxPrice,
        variantion: finalVariants,
        brandId: "",
        sellerId,
      };
 navigate({
        pathname: category ? `/seeall/${category}` : "/seeall",
        search: `?${createSearchParams(params)}`,
      });
    }
  };

  const onGo = () => {
    // console.log(minPrice, maxPrice);
    // console.log(selectedColors.toString(), selectedSizes.toString());
    // console.log(selectedBrand);

    const params = {
      price1: minPrice,
      price2: maxPrice,
      variantion: finalVariants,
      brandId: selectedBrand,
      sellerId,
    };
    setOpen(false);
    navigate({
      pathname: `/seeall/${category}`,
      search: `?${createSearchParams(params)}`,
    });

    window.scrollTo(0, 100);
  };

  const getCategories = async () => {
    try {
      const response = await getMenu();
      const response1 = response.map((item) => {
        if (item.subCategory.length) {
          item.subCategory.map((item1) => {
            // console.log(item1.parentId, category);
            if (item1._id == category) {
              item.isSelected = true;
            }
            if (item1.subSubCategory.length) {
              item1.subSubCategory.map((item2) => {
                if (item2._id == category) {
                  item1.isSelected = true;
                  item.isSelected = true;
                }
              });
            }
          });
        }
        return { ...item };
      });
      setCategories(response1);
      console.log("categories -------> ", response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    // console.log("re render");
    // console.log({ maxLimit, minLimit });
    setMaxPrice(maxLimit);
    setMinPrice(minLimit);
  }, [category, maxLimit, minLimit]);

  return (
    <div>
      {Categories.map((item, index) => {
        return (
          <div key={index}>
            <div className="flex items-center">
              {item.subCategory.length ? (
                <div
                  className="mt-1 cursor-pointer"
                  onClick={() => {
                    let arr = Categories.map((item1) => {
                      if (item._id == item1._id) {
                        item1.isSelected = !item1.isSelected;
                      }
                      return { ...item1 };
                    });
                    // setTestCat(arr);
                    setCategories(arr);
                    // console.log(arr);
                  }}
                >
                  {!item.isSelected ? "+" : "-"}
                </div>
              ) : (
                <></>
              )}
              <Link onClick={() => setOpen(false)} to={`/seeall/${item._id}`}>
                <div
                  style={{ marginRight: isRtl ? "5px" : 0 }}
                  className={`mt-2 ml-4 text-[14px] leading-[28px] ${item._id == category ? "font-semibold" : "font-normal"
                    } `}
                >
                  {toTitleCase(item.name)}
                </div>
              </Link>
            </div>
            {item.subCategory.map((item1, index1) => (
              <SubCategory
                key={index1}
                id={item1._id}
                name={item1.name}
                isSelected={item1.isSelected}
                parentSelected={item.isSelected}
                currentCategory={category}
                subSubCategory={item1.subSubCategory}
                data={Categories}
                setData={setCategories}
                isRtl={isRtl}
                setOpen={setOpen}
                to={`/seeall/${item1._id}`}
              />
            ))}
          </div>
        );
      })}

      {Boolean(fixedBrands.length) && (
        <>
          <h2 className="my-3 text-md text-gray-900">Brand</h2>
          <div className="mt-1 min-h-[36px] relative w-full">
            <input
              type="text"
              name="account-number"
              id="account-number"
              placeholder="Search"
              onChange={(e) => handleSearchBrand(e.target.value)}
              className="appearance-none block w-full px-3 py-2 border-[2px] border-[#EDEDED] outline-none bg-transparent placeholder:text-[14px] placeholder:text-[#232F3E] placeholder:opacity-40"
            />

            <div
              className={`absolute inset-y-0 ${isRtl ? "left-[5px]" : "right-0"
                } pr-3 flex items-center hover:cursor-pointer`}
            >
              {/* <SearchIcon
        className="h-5 w-5 text-[#232F3E] opacity-30"
        aria-hidden="true"
      /> */}
              <img src={searchIcon} />
            </div>
          </div>
          {console.log("brandss",brands.map((item) => item))}
          {brands.map((item, index) => (
            <CheckBox
              key={index}
              checked={item?._id == currentBrand}
              onChange={(e) => handleBrandCheck(e, setSelectedBrand)}
            >
              {item}
            </CheckBox>
          ))}
        </>
      )}
      {/* <div className="text-sm underline">Seel all</div> */}

      <h2 className="my-3 text-md text-gray-900">Variants</h2>
      {variants
        .sort((a, b) => a.key.localeCompare(b.key))
        .map((item, index) => (
          <div key={item.key}>
            {item.position.plpLeft && Boolean(item.value.length) && (
              <h2 className="my-3 text-[18px] font-medium text-gray-900">
                {toTitleCase(item.key)}{" "}
                <>
                  {item.value
                    ?.sort((a, b) => a.localeCompare(b))
                    .map((item1) => (
                      <>
                        <CheckBox
                          key={item1}
                          defaultChecked={selectedVariant.some(
                            (item2) => item2 == item1
                          )}
                          onChange={(e) => handleCheckChange(e, item.key)}
                        >
                          {item1}
                        </CheckBox>
                      </>
                    )) || null}
                </>{" "}
              </h2>
            )}
          </div>
        )) || null}
      <h2 className="my-3 text-[18px] font-medium text-gray-900">Price</h2>

      <div className="flex items-center">
        <input
          name="min"
          type="number"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          autoComplete="min"
          placeholder="Min"
          className={`appearance-none block w-full ${isRtl ? "pl-2" : "pr-2"
            }  text-center mr-3 py-2 border border-[#EDEDED] bg-transparent outline-none shadow-sm placeholder-gray-400`}
        />
        <h2 className="my-2 text-md text-gray-900">{t("to")}</h2>

        <input
          name="max"
          type="number"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          autoComplete="max"
          placeholder="Max"
          className={`appearance-none block w-full ${isRtl ? "pl-2" : "pr-2"
            } mx-3  text-center py-2 border border-[#EDEDED] bg-transparent outline-none shadow-sm placeholder-gray-400`}
        />

        <button
          onClick={onGo}
          className="bg-[#ABB0B6] rounded-full px-3 py-1 text-white shadow-black hover:bg-[#9a9fa5]"
        >
          {t("go")}
        </button>
      </div>
    </div>
  );
}

export default SidebarContent;
