import React from "react";

import ProductDetailSIdeCard from "../general/card/ProductDetailSIdeCard";
import ProductDetailSideCard2 from "../general/card/ProductDetailSideCard2";
import { verified, timer } from "../../assets/index";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../Helper/helperFunctions";

function ProductDetailSide({ sellerData }) {
  console.log(sellerData._id);
  const { t } = useTranslation();
  return (
    <div>
      <ProductDetailSIdeCard
        data={{
          icon: verified,
          heading: "moneyBack",
          desc: "moneyBack1",
        }}
      />
      <ProductDetailSIdeCard
        data={{
          icon: timer,
          heading: "14days",
          desc: "14days1",
        }}
      />
      <ProductDetailSideCard2
        sellerId={sellerData._id}
        sellerName={toTitleCase(sellerData.storeName)}
      />
    </div>
  );
}

export default ProductDetailSide;
