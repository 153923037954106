import React from "react";
import { Link } from "react-router-dom";
import { StarIcon, DotsHorizontalIcon } from "@heroicons/react/solid";
import SubmitButton from "../../buttons/SubmitButton";
import { deleteIcon } from "../../../assets/index";
import { RemoveWishList } from "../../../config/Apis/WishlistApi";
import ReviewModal from "../../modals/ReviewModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ToBeReviewedCard({ data }) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div
      key={data._id}
      className="py-10 border-b border-gray-200 flex space-x-6 md:flex-col"
    >
      <Link
        to={`/ProductDetail/${data?.productId?._id}`}
        state={data.productId}
        className="max-w-[110px] max-h-[110px]"
      >
        <img
          src={data?.productId?.media[0]?.url ?? ""}
          alt={"Product"}
          className="flex-none sm:ml-6 object-center  bg-gray-100 rounded-lg sm:w-30 sm:h-30"
        />
      </Link>

      <div className="flex-auto flex flex-col py-2">
        <div>
          <h4 className="font-medium text-gray-900">{`${data?.productId?.title}`}</h4>
          <p className="mt-2 text-sm text-gray-600">
            {data?.productId?.shortDescription}
          </p>
          <p className="mt-2 text-sm text-gray-600">{data?.productId?.price}</p>
        </div>

        <div className="mt-2">
          <h3 className="text-lg font-medium text-gray-900">
            Share your thoughts
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            If you’ve used this product, share your thoughts with other
            customers
          </p>

          <p
            onClick={() => setShowModal(true)}
            className="mt-6 cursor-pointer inline-flex w-[200px] bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
          >
            Write a review
          </p>
        </div>
        {showModal && (
          <ReviewModal
            isOpen={showModal}
            closeModal={() => setShowModal(false)}
            openModal={() => setShowModal(true)}
            id={data._id}
          />
        )}
      </div>
    </div>
  );
}

export default ToBeReviewedCard;
