import React from "react";
import "./App.css";
import Router from "./routes/Router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useApp from "./hooks/useApp";
import Maintenance from "./pages/Maintenance";

const App = () => {
   const { isSettingError } = useApp();
  return <>{isSettingError ? <Maintenance /> : <Router />}</>;
};

export default App;
