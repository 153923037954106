import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets/index";
import appContext from "../../context/AppContext";

function MainLogo(props) {
  const { settingData, settingLoader } = useContext(appContext);
  return (
    <div className="flex lg:static col-span-2 lg:col-span-3">
      <div className="flex-shrink-0 flex items-center mr-[20px] justify-self-start">
        <Link to={"/"} className="cursor-pointer">
          {!settingLoader && (
            <img
              className="block h-7 w-auto pl-4"
              src={settingData?.homePage?.logoPrimary}
              alt="logo"
            />
          )}
        </Link>
      </div>
    </div>
  );
}

export default MainLogo;
