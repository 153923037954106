import { BASE_URL, TOKEN } from "../keys";
import axios from "axios";

export const getOnePage = (slug) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}page/${slug}`, {
        params: { limit: 100 },
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
