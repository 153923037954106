import { UserPool } from "./PoolSettings";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export const Signin = (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log(data);
          return resolve(data);
        },
        onFailure: (err) => {
          console.log("Failure:", err);
          return reject(err);
        },
        newPasswordRequired: (data) => {
          //   console.log("newPasswordRequired", data);
          return resolve(data);
        },
      });
    } catch (error) {
      return reject(error);
    }
  });
};

export const Signup = (email, password) => {
  return new Promise(async (resolve, reject) => {
    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        return reject(err);
      }
      console.log(data);
      return resolve(data);
    });
  });
};

export const confirmCodeReq = (code, email) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        return reject(err);
      }
      console.log(result);
      return resolve(result);
    });
  });
};

export const resendCodeReq = (email) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.resendConfirmationCode((err, result) => {
      if (err) {
        return reject(err);
      }
      return resolve(result);
    });
  });
};

export const forgotPasswordReq = (email) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.forgotPassword({
      onSuccess: (data) => {
        console.log("success", data);
      },
      onFailure: (err) => {
        return reject(err);
      },
      inputVerificationCode: (data) => {
        return resolve(data);
      },
    });
  });
};

export const sendCode = (email) => {
  console.log(email);
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.forgotPassword({
      onSuccess: (result) => {
        return resolve(result);
      },
      onFailure: (err) => {
        return reject(err);
      },
      inputVerificationCode: null,
    });
  });
};

export const newPassword = (email, code, newPassword) => {
  console.log(code, newPassword);
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    user.confirmPassword(code, newPassword, {
      onSuccess() {
        return resolve("success");
      },
      onFailure(err) {
        return reject(err);
      },
    });
  });
};
