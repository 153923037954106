import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
function FooterCard({ title, data }) {
  // console.log(data);

  const [show, setShow] = useState(false);
  return (
    <div className="flex justify-start py-6 ">
      <div className="block md:hidden">
        <h1 className="text-[20px] font-semibold"> {title}</h1>
        {(data || []).map((item, index) => (
          <div key={index}>
            {item.pageId && (
              <Link key={index} to={`/page/${item.pageId.slug}`}>
                <p
                  className="text-[14px] leading-[24px] font-normal py-1 cursor-pointer"
                  id={item?._id}
                >
                  {" "}
                  {item.pageId.title}{" "}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="hidden md:block md:cursor-pointer">
        <div className="flex items-center " onClick={() => setShow(!show)}>
          <h1 className="text-[18px] font-semibold mr-2"> {title}</h1>
          {
            (data.length != 0) &&
            <div>
              {show ?
                <ChevronUpIcon className="w-5 h-5" />
                :
                <ChevronDownIcon className="w-5 h-5" />
              }
            </div>
          }
        </div>

        {
          (data.length != 0) &&
          <div className={show ? "block" : "hidden"}>
            {(data || []).map((item, index) => (
              <div key={index}>
                {item.pageId && (
                  <Link key={index} to={`/page/${item.pageId.slug}`}>
                    <p
                      className="text-[12px] leading-[24px] font-normal py-1 cursor-pointer"
                      id={item?._id}
                    >
                      {item.pageId.title}
                    </p>
                  </Link>
                )}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

export default FooterCard;
