import React from "react";
import { heading_underline, plus } from "../../assets/index";
import CrossellCard from "../general/card/CrossellCard";
import DetailButton from "../buttons/SubmitButton";

function Crossell(props) {
  return (
    <div className="p-6">
      <p className="text-[24px] pb-[12px] text-[#333] font-medium">
        Frequently bought together
      </p>
      <img src={heading_underline} className="w-full" />
      <div className="flex items-center md:flex-col md:items-start">
        <div className="py-[10px] flex w-[78%] md:flex-col items-center md:items-start">
          <CrossellCard />
          <img src={plus} className="h-[18px] w-[18px] mx-4 md:my-4" />
          <CrossellCard />
          <img src={plus} className="h-[18px] w-[18px] mx-4 md:my-4" />
          <CrossellCard />
        </div>
        <div>
          <p className="text-[#666666] text-[14px]">
            Total Price: <span className="text-[#444444]">AED 2,154.50</span>
          </p>
          <button className="block py-1 my-1 rounded-md bg-[#2C73D2] text-[14px] px-6 w-[100%] text-gray-100">
            Buy 3 together
          </button>
          <button className="block py-1 my-1 rounded-md bg-[#FF9900] text-[14px] px-6 w-[100%] text-gray-100">
            Add to Cart 3 Together
          </button>
        </div>
      </div>
    </div>
  );
}

export default Crossell;
