import React from "react";
import { StarIcon, DotsHorizontalIcon } from "@heroicons/react/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function ReviewCard({ review }) {
  const [expand, setExpand] = React.useState(false);
  return (
    <div key={review._id} className="py-12">
      <div className="flex items-center">
        <img
          src={review.buyerId.profilePic}
          alt={`${"Author"}.`}
          className="h-12 w-12 rounded-full"
        />
        <div className="ml-4">
          <h4 className="text-sm font-bold text-gray-900">{`${review.buyerId.firstName} ${review.buyerId.lastName}`}</h4>
          <div className="mt-1 flex items-center">
            {[0, 1, 2, 3, 4].map((rating) => (
              <StarIcon
                key={rating}
                className={classNames(
                  parseInt(review.ratingStar) > rating
                    ? "text-yellow-400"
                    : "text-gray-300",
                  "h-5 w-5 flex-shrink-0"
                )}
                aria-hidden="true"
              />
            ))}
          </div>
          <p className="sr-only">{review.rating} out of 5 stars</p>
        </div>
      </div>

      <div className="mt-4 space-y-6 text-base italic text-gray-600">
        {review.ratingSummery.length > 180 ? (
          <>
            {!expand ? (
              <>
                {review.ratingSummery.substring(0, 179)}{" "}
                {!expand && (
                  <DotsHorizontalIcon
                    onClick={() => setExpand(true)}
                    className="h-5 w-7 cursor-pointer inline-block border-[1px] border-gray-700 border-solid rounded-md"
                  />
                )}
              </>
            ) : (
              review.ratingSummery
            )}
          </>
        ) : (
          review.ratingSummery
        )}
      </div>
    </div>
  );
}

export default ReviewCard;
