import React from "react";
import { Fragment, useState } from "react";

import DetailButton from "../buttons/CardButton";
import { useNavigate } from "react-router-dom";
import { creditcards } from "../../assets/index";
import PaymentSelector from "../general/PaymentSelector";
import DeliverySelector from "../general/DeliverySelector";
import DarkLoaderSm from "../general/DarkLoaderSm";
import { useTranslation } from "react-i18next";

const deliveryMethods = [
  {
    _id: 1,
    name: "Standard",
    description: "4–10 business days",
    // price: "$5.00",
  },
  {
    _id: 2,
    name: "Express",
    description: "2–5 business days",
    // price: "$16.00",
  },
];

const PaymentMethod = ({
  payments,
  selectedPayment,
  setSelectedPayment,
  deliveries,
  selectedDelivery,
  setSelectedDelivery,
  loader,
  setError,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cardNum, setNum] = React.useState("");
  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState("");
  const [cvv, setCvv] = React.useState("");
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );
  console.log("Selected Payment Method: ", selectedPayment)
  return (
    <div className="w-[350px] sm:w-[100%]">
      <div className="bg-white p-5 rounded-sm my-2 ">
        <h2 className="text-[22px] text-[#444] font-medium my-2">
          {t("paymentMethod")} 
        </h2>

        <div className="my-4">
          <PaymentSelector
            data={payments}
            selected={selectedPayment}
            setSelected={setSelectedPayment}
            setError={setError}
          />
        </div>

        {selectedPayment?.method == "online" && (
          <>
            <label
              htmlFor="card-number"
              className="block text-sm mb-1 ml-1 text-[#444]"
            >
              {t("cardNumber")} <span className="text-red-500">*</span>
            </label>
            <div className="w-[100%] ">
              <div className="">
                <input
                  onChange={(e) => setNum(e.target.value)}
                  id="card-number"
                  name="card-number"
                  placeholder="**** **** **** ****"
                  type="number"
                  className="appearance-none main_input block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="w-[100%] ">
              <label
                htmlFor="card-name"
                className="block text-sm mb-1 mt-4 ml-1 text-[#444]"
              >
                {t("nameOnCard")} <span className="text-red-500">*</span>
              </label>
              <div className="">
                <input
                  onChange={(e) => setName(e.target.value)}
                  id="card-name"
                  name="card-name"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4 flex">
              <div className="w-[60%] mr-2">
                <label
                  htmlFor="expiration-date"
                  className="block text-sm mb-1 ml-1 text-[#444]"
                >
                  {t("expiry")} <span className="text-red-500">*</span>
                </label>
                <div className="">
                  <input
                    onChange={(e) => setDate(e.target.value)}
                    id="expiration-date"
                    name="expiration-date"
                    type="month"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-[40%] ml-1">
                <label
                  htmlFor="ccv"
                  className="block text-sm mb-1 ml-1 text-[#444]"
                >
                  CCV <span className="text-red-500">*</span>
                </label>
                <div className="">
                  <input
                    onChange={(e) => setCvv(e.target.value)}
                    id="ccv"
                    name="ccv"
                    type="number"
                    className="appearance-none main_input block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="w-[100%] my-2">
          {loader ? (
            <DarkLoaderSm />
          ) : (
            <DetailButton
              onClicked={() => props.onClicked({ name, cardNum, date, cvv })}
              bgcolor="#FF9900"
              text={t("placeOrder")}
              width={"100%"}
            />
          )}
        </div>
        {props.error && (
          <p className="my-2 text-red-700 text-sm text-center">
            {" "}
            {props.error}
          </p>
        )}
        <img src={creditcards} className="mt-6" />
      </div>
    </div>
  );
};

export default PaymentMethod;
