import React from "react";
import { useTranslation } from "react-i18next";

function CheckoutSlider({ selected, setSelected }) {
  const { t } = useTranslation();
  return (
    <div className="bg-[#fff]">
      <div className="w-[80%] mx-auto py-2 flex items-center justify-center">
        {/* <CheckOutSliderItem
          selected={selected}
          value={1}
          label={"Shipping Address"}
          setSelected={setSelected}
        /> */}
        {/* <div className="h-[2px] w-[100px] bg-[#999] mx-1" /> */}
        <CheckOutSliderItem
          setSelected={setSelected}
          selected={selected}
          value={1}
          label={t("shippingPayment")}
        />
        <div className="h-[2px] w-[100px] bg-[#999] mx-1" />
        <CheckOutSliderItem
          setSelected={setSelected}
          selected={selected}
          value={2}
          label={t("orderPlaced")}
        />
      </div>
    </div>
  );
}

const CheckOutSliderItem = ({ value, label, selected, setSelected }) => {
  return (
    <div
      // onClick={() => setSelected(value)}
      className="flex cursor-pointer items-center justify-center md:flex-col "
    >
      <div
        className={`rounded-[50%] ${
          selected == value
            ? "bg-[#FF9900] text-[#fff]"
            : "bg-[#fff] text-[#333]"
        } mx-1 md:my-1 w-[25px] h-[25px] border-[#777] border-solid border-[1px] text-[12px] flex justify-center items-center`}
      >
        {value}
      </div>
      <div className="text-xs text-center ">{label}</div>
    </div>
  );
};

export default CheckoutSlider;
