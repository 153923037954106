import React, { useState, useContext, useEffect } from "react";
import {
  getAllProducts,
  searchProducts,
  getFilters as getFiltersApi,
} from "../config/Apis/ProductApis";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  getCategoryData,
  getSubcategory,
  getCategoryData1,
} from "../config/Apis/CategoryApis";
import appContext from "../context/AppContext";
import useFilters from "../components/sidebar/useFilters";

function useSeeAll(props) {
  const { settingData } = useContext(appContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [dataLimit, setDataLimit] = useState(10);
  const [variants, setVariants] = useState([]);
  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brand, setBrand] = useState([]);
  const [currentBrand, setCurrentBrand] = useState("");
  const [fixedBrands, setFixedBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const [match, setMatch] = useState(1);
  const { categoryid } = useParams();
  const location = useLocation();
console.log("%c Location", "background: black; color: white", location)
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const sellerId = query.get("sellerId") || "";
  //temporary:
  //const categoryid = '641801e327e07a8ebdf23d52'
  //
  const temporaryCategory= {
    "_id": "641801e327e07a8ebdf23d52",
    "parentId": null,
    "brandId": null,
    "isPromo": true,
    "isParent": true,
    "isHighlight": false,
    "isFeatured": true,
    "isTop": false,
    "position": [],
    "name": "Beauty",
    "slug": "Beauty",
    "isDetails": false,
    "description": "Our beauty category offers a wide range of premium products designed to help you look and feel your best. From skincare and makeup to fragrance and body care, we have everything you need to create a complete beauty routine. Our products are made with high-quality, natural ingredients and are carefully formulated to meet the specific needs of different skin types and concerns.",
    "image": "https://admin-matjar.s3.me-south-1.amazonaws.com/products/beauty icon35716.jfif",
    "ads": [],
    "metaTitle": "Discover Your Beauty Routine with Our Premium Beauty Products",
    "metaKeywords": "Beauty products Skincare Makeup Fragrance Body care Hair care",
    "metaDescription": "Unleash your natural beauty with our premium beauty products. Our range includes skincare, makeup, fragrance, body care, and hair care products that are carefully formulated to help you look and feel your best.",
    "tags": [],
    "commissionFees": 10,
    "order": 6,
    "discountType": "fixed",
    "type": "m",
    "createdBy": "641186e127e07a8ebdf2302d",
    "updatedBy": null,
    "status": "active",
    "statusReason": "",
    "created_at": "2023-03-20T06:49:07.378Z",
    "updated_at": "2023-03-20T06:49:07.378Z"
}
/* useEffect(()=>{
setCategoryData(temporaryCategory)
},[]) */
  console.log("%c 'const { categoryid } = useParams();' categoryid","background: purple; color: white", categoryid)
  const getData = async () => {
    setLoader(true);
    try {
      if (categoryid == "search") {
        const productsData = await getAllProducts({
          q: query.get("query"),
          categoryId: query.get("categoryId"),
          limit: dataLimit,
        });
        productsData?.data?.list?.products.forEach((item) => {
          // console.log(item);
        });
        setData(productsData?.data?.list?.products ?? []);
        setDataInfo(productsData?.data);
        setCategoryData("");
      } else {
        const price1 = query.get("price1") ?? "";
        const price2 = query.get("price2") ?? "";
        const variation = query.get("variantion") ?? "";

        let selectedVariations = variation
          .split("|")
          .map((item) => item.split(","))
          .reduce((prev, next) => prev.concat(next));

        // console.log(selectedVariant);
        // console.log(
        //   variants.map((item) => {
        //     return item.value.map((item2) => {
        //       return selectedVariations.some((item3) => item3 == item2);
        //     });
        //   })
        // );

        // console.log(
        //   variants.map((item) => {
        //     return item.value.map((item2) => {
        //       return item2;
        //     });
        //   })
        // );

        // console.log(selectedVariations);
        setSelectedVariant(selectedVariations);
        const brand = query.get("brandId") ?? "";
        setCurrentBrand(brand);
        const sellingType = query.get("sellingType") ?? "";
        const priceAsc = query.get("priceAsc") ?? "";
        const priceDesc = query.get("priceDesc") ?? "";
        setMatch(priceAsc ? 2 : priceDesc ? 3 : 1);
        setSelected(
          !sellingType
            ? 0
            : sellingType == "auction"
            ? 1
            : sellingType == "buyNow"
            ? 2
            : 0
        );

        const productsData = await getAllProducts({
          categoryId: categoryid,
          price1,
          price2,
          variantion: variation,
          brandId: brand,
          limit: dataLimit,
          sellingType,
          priceAsc,
          priceDesc,
          sellerId,
        });

        setData(productsData?.data?.list?.products ?? []);
        setDataInfo(productsData?.data);
        const catRes = await getCategoryData1(categoryid)
          .then((res) => {
          console.log("%c catRes res: ", "background: orange; color: white", res)
         
        //temporary comment
           setCategoryData(res.data);
          })
          .catch((err) => {
          console.log("%c catRes err: ", "background: orange; color: white", err)
              
        //temporary comment
            setCategoryData("");
          });
          console.log("%c catRes: ", "background: orange; color: white", catRes, categoryData)
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    } finally {
      setLoader(false);
    }
  };

  const loadMoreData = async () => {
    let newLimit = dataLimit + 10;
    setDataLimit(newLimit);
   setLoadMoreLoader(true);
    try {
      if (categoryid == "search") {
        const productsData = await await getAllProducts({
          q: query.get("query"),
          categoryId: query.get("categoryId"),
          limit: dataLimit,
        });
        productsData?.data?.list?.products.forEach((item) => {});
        setData(productsData?.data?.list?.products ?? []);
        setDataInfo(productsData?.data);
      } else {
        const price1 = query.get("price1") ?? "";
        const price2 = query.get("price2") ?? "";
        const variation = query.get("variantion") ?? "";
        let selectedVariations = variation
          .split("|")
          .map((item) => item.split(","))
          .reduce((prev, next) => prev.concat(next));
        setSelectedVariant(selectedVariations);
        const brand = query.get("brandId") ?? "";
        const sellingType = query.get("sellingType") ?? "";
        const priceAsc = query.get("priceAsc") ?? "";
        const priceDesc = query.get("priceDesc") ?? "";
        setMatch(priceAsc ? 2 : priceDesc ? 3 : 1);
        setSelected(
          !sellingType
            ? 0
            : sellingType == "auction"
            ? 1
            : sellingType == "buyNow"
            ? 2
            : 0
        );
        const productsData = await getAllProducts({
          categoryId: categoryid,
          price1,
          price2,
          variantion: variation,
          brandId: brand,
          limit: newLimit,
          sellingType,
          priceAsc,
          priceDesc,
          sellerId,
        });

        setDataInfo(productsData?.data);
        setData(productsData?.data?.list?.products ?? []);
        getCategoryData1(categoryid).then((res) => {
          setCategoryData(res.data);
        });
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    } finally {
      setLoadMoreLoader(false);
    }
  };

  const getFilters = async () => {
    const brandArr = [];
    const tempBrand = [];
    const catArr = [];
    const tempCat = [];
    const price1 = query.get("price1") ?? "";
    const price2 = query.get("price2") ?? "";
    const variation = query.get("variantion") ?? "";
    let selectedVariations = variation
      .split("|")
      .map((item) => item.split(","))
      .reduce((prev, next) => prev.concat(next));
    setSelectedVariant(selectedVariations);
    const brand = query.get("brandId") ?? "";
    setCurrentBrand(brand);
    const sellingType = query.get("sellingType") ?? "";
    const priceAsc = query.get("priceAsc") ?? "";
    const priceDesc = query.get("priceDesc") ?? "";
    setMatch(priceAsc ? 2 : priceDesc ? 3 : 1);
    setSelected(
      !sellingType
        ? 0
        : sellingType == "auction"
        ? 1
        : sellingType == "buyNow"
        ? 2
        : 0
    );
    try {
      const filterData = await getFiltersApi(categoryid);

      const productsData = await getAllProducts({
        categoryId: categoryid == "search" ? "" : categoryid,
        price1,
        price2,
        variantion: variation,
        brandId: brand,
        limit: dataLimit,
        sellingType,
        priceAsc,
        priceDesc,
        sellerId,
      });

      if (filterData?.data?.brand.length) {
        for (const brandItem of filterData.data.brand) {
          brandArr.push(brandItem.brand);
        }
      }
console.log("%c Category id", "background: black; color: white", categoryid)
console.log("%c Filter Data", "background: black; color: white",filterData)
// console.log("%c productsData", "background: black; color: white", productsData, productsData.data.list.products)
console.log("%c GetFilters Function is calling", "background: black; color: white")
      // console.log("Category in main ---------->", catArr);
      function uniqueBy(arr, prop) {
        console.log("arr", arr)
        return arr.filter((obj, index, self) =>
          index === self.findIndex((t) => t[prop] === obj[prop])
        );
      }
      const allCategories = filterData.data.brand.map((yyy)=>{ 
        return ({
          "_id": yyy.brand._id,
          "name": yyy.brand.name
        })
      })
      const uniqueArr = uniqueBy(allCategories, "name");
      // setBrand(brandArr);
      setBrand(uniqueArr)
      setMinLimit(filterData?.data?.price?.minPrice ?? 0);
      setMaxLimit(filterData?.data?.price?.maxPrice ?? 0);

      setFixedBrands(brandArr);
      setVariants(filterData.data?.attribute ?? []);
      // console.log("Filters ---------> ", productsData);
    } catch (err) {
      console.log(err.response, err);
    }
  };

  useEffect(() => {
    // setSelectedVariant([])
    // console.log(selectedVariant)
    getData();
    getFilters();
  }, [location]);

  return {
    settingData,
    isOpen,
    setIsOpen,
    loader,
    setLoader,
    loadMoreLoader,
    setLoadMoreLoader,
    data,
    setData,
    dataInfo,
    setDataInfo,
    dataLimit,
    setDataLimit,
    variants,
    setVariants,
    selectedVariant,
    setSelectedVariant,
    categoryData,
    setCategoryData,
    brand,
    setBrand,
    currentBrand,
    setCurrentBrand,
    fixedBrands,
    setFixedBrands,
    category,
    setCategory,
    subCategories,
    setSubCategories,
    selected,
    setSelected,
    match,
    setMatch,
    categoryid,
    location,
    query,
    getData,
    loadMoreData,
    getFilters,
    minLimit,
    maxLimit,
  };
}

export default useSeeAll;
