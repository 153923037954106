export const helperFunctions = (DiscountPrice, ActualPrice) => {
  let saveAmount = ActualPrice - DiscountPrice;
  let savedPercent = (100 * (ActualPrice - DiscountPrice)) / ActualPrice;

  return {
    SavedAmount: saveAmount,
    SavedPercent: savedPercent,
  };
};

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

export const getTimeRemaining = (endDate) => {
  let endingDate = endDate ? new Date(endDate) : new Date();
  let dateFuture = new Date(endingDate.toUTCString().slice(0, -3));
  let dateNow = new Date(new Date().toUTCString().slice(0, -3));

  if (dateFuture - dateNow > 0) {
    let timeleft = dateFuture - dateNow;
    let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    return `${days}d ${hours}h`;
  } else {
    return "0d 0h";
  }
};

export const getProductQuantity = (response) => {
  if (response.data.cart.length) {
    const cartQuantity = response.data.cart[0].products.map((a) => a.quantity);
    const productQuantity = cartQuantity.reduce((a, b) => a + b, 0);
    return productQuantity;
  } else {
    return 0;
  }
};

export const averageFunc = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
