import React from "react";

function DarkLoaderSm() {
  return (
    <div className="flex items-center justify-center">
      <div
        className={`spinner-border animate-spin border-gray-700 border-r-transparent inline-block w-[15px] h-[15px] border-2 rounded-full`}
        role="status"
      >
        <span className="visually-hidden"></span>
      </div>
    </div>
  );
}

export default DarkLoaderSm;
