import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useAuth } from '../context/AuthContext';
import Cookies from "js-cookie";

const SellerLogin = () => {
  // ----------------------- Hooks -----------------------
  const { loginUser } = useAuth();

  // ----------------------- State -----------------------

  const [email, setEmail] = useState('');

  // --------------------- Event Handlers ---------------------

  const handleChange = (evt) => {
    const value = evt.target.value;
    setEmail(value);


  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser(email);
    
      console.log("Logged in successfully!");
    } catch (error) {
      console.error("Login error: ", error.message);
    }
  };

  const onFinish = async (values) => {
    // Handle form submission success
  
    try {
      await loginUser(values);
      console.log('Success:', values);
    window.location.replace('http://localhost:3000/');
    localStorage.setItem('buyer', JSON.stringify(email));
      console.log("Logged in successfully!");
    } catch (error) {
      console.error("Login error: ", error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // Handle form submission failure
    console.log('Failed:', errorInfo);
  };

  // ------------------ Lifecycle and Effects ------------------

  useEffect(() => {
    // Set the initial email value from localStorage if available
    const storedEmail = localStorage.getItem('matjar_user_data');
    setEmail(storedEmail || '');
  }, []);

  // ----------------------- Render -----------------------

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input
          name="email"
          value={email}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit"
        //  onClick={handleLogin}
         >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SellerLogin;
