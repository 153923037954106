import { useQuery } from "../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";

function useFilterMenu({
  selected,
  setSelected,
  category,
  setSelectedVariant,
  setCurrentBrand,
  setMatch,
  match,
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const sellerId = query.get("sellerId") || "";

  const onSelection = (current, currentMatch) => {
    setSelected(current);
    setMatch(currentMatch);
    const price1 = query.get("price1") ?? "";
    const price2 = query.get("price2") ?? "";
    const variation = query.get("variantion") ?? "";
    console.log("%c Varation", "background: purple; color: white", variation);
    let selectedVariations = variation
      .split("|")
      .map((item) => item.split(","))
      .reduce((prev, next) => prev.concat(next));
    setSelectedVariant(selectedVariations);
    const brand = query.get("brandId") ?? "";
    setCurrentBrand(brand);
    const params = {
      price1: price1,
      price2: price2,
      variantion: variation,
      brandId: brand,
      priceAsc: currentMatch == 2 ? true : "",
      priceDesc: currentMatch == 3 ? true : "",
      sellerId,
      sellingType:
        current == 0
          ? ""
          : current == 1
          ? "auction"
          : current == 2
          ? "buyNow"
          : 0,
    };

    navigate({
      pathname: category ? `/seeall/${category}` : `/seeall`,
      search: `?${createSearchParams(params)}`,
    });
  };

  return { query, sellerId, onSelection };
}

export default useFilterMenu;
