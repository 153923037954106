import React, { useState } from "react";
import { createOrder, createOrderOnline } from "../config/Apis/OrderApi";
import { getAllAddresses } from "../config/Apis/AddressApis";
// import { getCart } from "../config/Apis/CartApis";

import appContext from "../context/AppContext";
import { getPaymentMethods } from "../config/Apis/PaymentsApis";
import { getShippingMethods } from "../config/Apis/ShippingApis";
// import { getToken, pay } from "../config/Payment/Service";
import { details } from "../config/keys";

function useCheckout({ setOrderResponse, setParentSelected }) {
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selected, setSelected] = useState({});
  const { updateCartCount, cartData, setCardData } =
    React.useContext(appContext);
  const [checkoutError, setCheckoutError] = useState("");
  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone1: "",
    address1: "",
    type: "other",
    userType: "register",
    status: "active",
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState([]);
  const [placeLoader, setPlaceLoader] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [addressesLimit, setAddressesLimit] = useState(3);
  const [addressesResponse, setAddressesResponse] = useState({});
  const [loader, setLoader] = useState(false);
  // console.log(details);
  React.useEffect(() => {
    getData();
    setCheckoutError("");
  }, []);
  const getData = async () => {
    try {
      setLoader(true);
      const responses = await Promise.all([
        getPaymentMethods(),
        getShippingMethods(),
        getAllAddresses(addressesLimit),
      ]);
      console.log("REsponse", responses)
      setData({
        ...data,
        email: details.email,
      });
      console.log("%c Responses From Checkout Page", "background: purple ; color: white", responses)
      setPaymentMethods(responses[0].data.list);
      setSelectedPayment(responses[0].data.list[0]);
      setDeliveryMethods(responses[1].data.list);
      setSelectedDelivery(responses[1].data.list[0]);
      setAddresses(responses[2].data.list);
      setAddressesResponse(responses[2].data);
      if (responses[2].data?.list.length) {
        setSelected(responses[2].data.list[0]);
        setSelectedAddress(responses[2].data.list[0]._id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  const dummyResponse = {
    "data":{
    "_id": "65cca3a049a80908571c7bb4",
    "orderId": "5026161",
    "total": 0,
    "subTotal": 0,
    "tax": 0,
    "address": {
      "userId": "640b2d9727e07a8ebdf21d2c",
      "firstName": "hira",
      "lastName": "aamir",
      "address1": "Al Maktoum Road, Deira",
      "address2": "",
      "city": "Dubai",
      "state": "Dubai",
      "postal": "12345",
      "country": "United Arab Emirates",
      "phone1": "7854586968",
      "phone2": "",
      "email": "hk1855597+1@gmail.com",
      "type": "primary",
      "label": "home",
      "cordinate": [
        25.2048,
        55.2708
      ],
      "status": "active",
      "createdBy": "640b2d9727e07a8ebdf21d2c",
      "updatedBy": null,
      "userId": "640b2d9727e07a8ebdf21d2c",
      "userType": "register"
    },
    "products": [
      {
        "quantity": 1,
        "sellerId": "64210bcb27e07a8ebdf24c36",
        "courierId": null,
        "categoryId": "641801e327e07a8ebdf23d52",
        "productId": "6421281527e07a8ebdf24d66",
        "shipping": 100,
        "shippingType": "default",
        "status": "pendingPayment",
        "statusReason": "",
        "isCourierPaid": false,
        "isSellerPaid": false,
        "price": 0,
        "finalPrice": 0,
        "withdrawId": null,
        "_id": "65cca3a049a80908571c7bb6"
      }
    ],
  },
    "shipping": 0,
    "status": "pendingPayment",
    "dev_message": "",
    "isServerError": false,
    "isSuccess": true,
    "message": "cart",
    "statusCode": 201
  }

  const loadMoreAddresses = async () => {
    try {
      setLoadMoreLoader(true);
      let newLimit = addressesLimit + 3;
      setAddressesLimit(newLimit);
      const response = await getAllAddresses(newLimit);
      setAddresses(response.data.list);
      setAddressesResponse(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadMoreLoader(false);
    }
  };

  const codPayment = async () => {
    const finalData = {
      addressId: selectedAddress,
      paymentType: selectedPayment?.method,
      currency: "AED",
      shippingId: selectedDelivery._id,
      shippingCostId: selectedDelivery.costs[0]._id,
      shippingObj: selectedAddress,
    };

    // const finalData = {
    //   addressId: "62a67f05bc03f2f238224156",
    //   paymentType: "cod",
    //   shippingType: "cod",
    //   currency: "AED",
    //   shippingId: "62a86f7eb3e7220d15f84981",
    //   shippingCostId: "62a86f7eb3e7220d15f84982",
    // };
    try {
      // comment for temporary
      //const res = await createOrder(finalData);

      // if (res.isSuccess) {
      if (dummyResponse.isSuccess) {
        //setOrderResponse(res.data);
        setOrderResponse(dummyResponse.data);
        updateCartCount(0);
        setParentSelected(2);
        setPlaceLoader(false);
      }
    } catch (err) {
      console.log(err.data);
      setCheckoutError(err.data);
    } finally {
      setPlaceLoader(false);
    }
  };

  const Checkout = async (card) => {
    const finalData = {
      addressId: selectedAddress,
      paymentType: selectedPayment?.method,
      currency: "AED",
      shippingId: selectedDelivery?._id,
      shippingCostId: selectedDelivery?.costs[0]._id,
      shippingObj: selectedAddress,
    };
    console.log("%c Selected Payment Method", "background: grey; color: black", selectedPayment)

    // console.log("with shipping --------> ", finalData);

    // {
    //   "orderId":"62a71d9dbc03f2f2382249a8",
    //     "card":{
    //         "pan":"4093191766216474",
    //         "expiry":"2025-04",
    //         "cvv":"123",
    //         "cardholderName":"John Brown"
    // }
    // }

    if (Boolean(selectedAddress)) {
      console.log("%c  if (Boolean(selectedAddress)) {", "background: grey; color: black")
      setPlaceLoader(true);
      console.log("%c Selected Payment Method", "background: grey; color: black", selectedPayment)
      if (selectedPayment?.method === "online") {
        if (card.cardNum && card.date && card.cvv && card.name) {
          console.log(cartData.totalPrice);
          const cardDetails = {
            pan: card.cardNum,
            expiry: card.date,
            cvv: card.cvv,
            cardholderName: card.name,
          };

          try {
           // const res = await createOrder(finalData);
           // if (res.isSuccess) {
            console.log("%c dummyResponse: ", "background: grey; color: black", dummyResponse);
            if (dummyResponse.isSuccess) {  
           const onlineOrderBody = {
               // orderId: res?.data?._id,
               orderId: dummyResponse?.data?._id, 
               card: { ...cardDetails },
              };
            console.log("%c onlineOrderBody: ", "background: grey; color: black", onlineOrderBody);

              console.log({ onlineOrderBody });
              const onlineRes = await createOrderOnline(onlineOrderBody);
            console.log("%c onlineRes: ", "background: grey; color: black", onlineRes);

             // setOrderResponse(res.data);
              setOrderResponse(dummyResponse.data);
              updateCartCount(0);
              setParentSelected(2);
              setPlaceLoader(false);
              console.log({ onlineRes });
            } else {
              setCheckoutError("Network request failed.");
            }
          } catch (err) {
            console.log(err);
            setCheckoutError("Network request failed.");
          } finally {
            setPlaceLoader(false);
          }

          console.log(cardDetails);
        } else {
          setPlaceLoader(false);
          setCheckoutError("Please fill all the card details");
        }
      } else {
        codPayment();
      }
    } else {
      console.log("%c  else (Boolean(selectedAddress)) {", "background: grey; color: black")

      setCheckoutError("Please select an address.");
    }
  };

  return {
    selectedAddress,
    setSelectedAddress,
    selected,
    setSelected,
    updateCartCount,
    error: checkoutError,
    setError: setCheckoutError,
    data,
    setData,
    cartData,
    setcartData: setCardData,
    paymentMethods,
    setPaymentMethods,
    selectedPayment,
    setSelectedPayment,
    selectedDelivery,
    deliveryMethods,
    setDeliveryMethods,
    setSelectedDelivery,
    placeLoader,
    setPlaceLoader,
    addresses,
    setAddresses,
    loadMoreLoader,
    setLoadMoreLoader,
    addressesLimit,
    setAddressesLimit,
    addressesResponse,
    setAddressesResponse,
    loader,
    setLoader,
    loadMoreAddresses,
    Checkout,
  };
}

export default useCheckout;
