import React from "react";
import AccountDropdown from "../dropdown/AccountDropdown";
import CartSlider from "../general/CartSlider";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";

import cart from "../../assets/svg/Shopping-Cart.svg";
import bag from "../../assets/svg/Shopping-Bag.svg";
import loginUser from "../../assets/svg/login.svg";
import flag from "../../assets/svg/flag.svg";

function MobileHeaderIcon({
  isRtl,
  sellWithUs,
  // flag,
  setIsRtl,
  loggedIn,
  onSignOut,
  openModal,
  // loginUser,
  setCartOpen,
  cartIcon,
  cartItems,
  cartOpen,
  settingData,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  console.log(settingData);
  return (
    <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
      <a
        href={settingData.sellWithUsUrl}
        target="_blank"
        className="cursor-pointer flex items-center px-2  py-2 border-gray-400 text-[14px] font-medium"
      >
        <img src={bag} className="w-[22px] h-[22px] mr-[4px]" />
        <p>{t("sellWithUs")}</p>
      </a>
      {loggedIn ? (
        <div className="cursor-pointer  pr-5 flex items-center">
          <AccountDropdown
            onNavigate={(val) => navigate(val)}
            onSignout={onSignOut}
          />
        </div>
      ) : (
        <div
          style={{ margin: "10px 6px" }}
          className="cursor-pointer flex items-center"
          onClick={openModal}
        >
          {/* <AccountDropdown /> */}
          <img src={loginUser} className="h-[20px] w-[20px] mr-2" />
          <p className="text-[14px] font-medium">{t("login")}</p>
        </div>
      )}

      <div className="flex">
        <div className="cursor-pointer flex items-center pl-[5px] pr-[10px] py-2 border-gray-400">
          <img
            className="h-5"
            src={flag}
            onClick={() => {
              setIsRtl(!isRtl);
              if (isRtl) {
                i18n.changeLanguage("en");
                localStorage.setItem("isRtl", "false");
                document
                  .getElementsByTagName("html")[0]
                  .setAttribute("dir", "ltr");
              } else {
                i18n.changeLanguage("ar");
                localStorage.setItem("isRtl", "true");
                document
                  .getElementsByTagName("html")[0]
                  .setAttribute("dir", "rtl");
              }
            }}
          />
        </div>

        <Popover.Button>
          <div
            onClick={() => setCartOpen(true)}
            className="cursor-pointer px-2 py-2 w-[40px] h-[40px] flex items-center justify-center relative shadow-md rounded-md mx-2"
          >
            <img src={cart} />
            <div className=" flex items-center justify-center absolute bg-yellow-300 p-[2px] text-[10px] rounded-[50%] top-[-5px] right-[-5px] h-[15px] w-[15px] ">
              {isNaN(cartItems) ? 0 : cartItems}
            </div>
            <CartSlider open={cartOpen} setOpen={setCartOpen} />
          </div>
        </Popover.Button>
      </div>
    </div>
  );
}

export default MobileHeaderIcon;
