import React, { useState } from "react";
import { StarIcon } from "@heroicons/react/solid";
import ReviewModal from "../modals/ReviewModal";
import ReviewCard from "../general/card/ReviewCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Reviews({ reviews }) {
  const averageFunc = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  const reviews1 = {
    average: averageFunc(reviews.map((item) => item.ratingStar)),
    totalCount: reviews.length,
    counts: [
      {
        rating: 5,
        count: reviews.filter((item) => item.ratingStar == 5).length,
      },
      {
        rating: 4,
        count: reviews.filter((item) => item.ratingStar == 4).length,
      },
      {
        rating: 3,
        count: reviews.filter((item) => item.ratingStar == 3).length,
      },
      {
        rating: 2,
        count: reviews.filter((item) => item.ratingStar == 2).length,
      },
      {
        rating: 1,
        count: reviews.filter((item) => item.ratingStar == 1).length,
      },
    ],
    featured: [
      {
        id: 1,
        rating: 5,
        content: `
          <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
        `,
        author: "Emily Selman",
        avatarSrc:
          "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
      },
      // More reviews...
    ],
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div className="bg-white fade-anim">
      <div className="max-w-2xl pt-8 pb-0 px-4 lg:max-w-7xl lg:py-16 lg:px-8 ">
        <div className="">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            Customer Reviews
          </h2>
          <div className="mt-3 flex items-center">
            <div>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      reviews1.average > rating
                        ? "text-yellow-400"
                        : "text-gray-300",
                      "flex-shrink-0 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className="sr-only">{reviews1.average} out of 5 stars</p>
            </div>
            <p className="ml-2 text-sm text-gray-900">
              Based on {reviews.length} reviews
            </p>
          </div>
          <div className="mt-6">
            <h3 className="sr-only">Review data</h3>

            <dl className="space-y-3">
              {reviews1.counts.map((count) => (
                <div key={count.rating} className="flex items-center text-sm">
                  <dt className="flex-1 flex items-center">
                    <p className="w-3 font-medium text-gray-900">
                      {count.rating}
                      <span className="sr-only"> star reviews</span>
                    </p>
                    <div
                      aria-hidden="true"
                      className="ml-1 flex-1 flex items-center"
                    >
                      <StarIcon
                        className={classNames(
                          count.count > 0 ? "text-yellow-400" : "text-gray-300",
                          "flex-shrink-0 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />

                      <div className="ml-3 relative flex-1">
                        <div className="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                        {count.count > 0 ? (
                          <div
                            className="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full"
                            style={{
                              width: `calc(${count.count} / ${reviews1.totalCount} * 100%)`,
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </dt>
                  <dd className="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">
                    {Math.round((count.count / reviews1.totalCount) * 100)}%
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          {/* <div className="mt-10">
            <h3 className="text-lg font-medium text-gray-900">
              Share your thoughts
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              If you’ve used this product, share your thoughts with other
              customers
            </p>

            {/* <p
              onClick={() => setShowModal(true)}
              className="mt-6 cursor-pointer inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              Write a review
            </p> */}
          {/* </div> */}
          {showModal && (
            <ReviewModal
              isOpen={showModal}
              closeModal={() => setShowModal(false)}
              openModal={() => setShowModal(true)}
            />
          )}
        </div>
      </div>
      <div className="mt-16 ">
        <h3 className="sr-only">Recent reviews</h3>

        <div className="flow-root">
          <div className="-my-12 divide-y divide-gray-200">
            {reviews.map((review) => (
              <ReviewCard review={review} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
