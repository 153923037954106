import React from "react";

function Maintenance(props) {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[500px]">
        <h1 className="text-[25px] font-extrabold">
          We'll be back soon <span className="text-[#FF9900]">!</span>{" "}
        </h1>
        <p className="my-[20px]">
          We're very sorry for the inconvenience but we're performing
          maintenance. You can still contact us at{" "}
          <a
            className="text-[#FF9900] underline"
            href="mailto:help@matjars.com"
          >
            help@matjars.com
          </a>
          .
        </p>
        <p className="text-[#FF9900]">-The Matjar Team</p>
      </div>
    </div>
  );
}

export default Maintenance;
