import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";

import { down } from "../../assets/index";
const categories = [
  { name: "All Categories" },
  { name: "Test Category 1" },
  { name: "Test Category 2" },
  { name: "Test Category 3" },
  { name: "Test Category 4" },
  { name: "Test Category 5" },
];

export default function CategoryDropDown({ data, onSubmit }) {
  const [selected, setSelected] = useState(categories[0]);

  return (
    <div className="w-40 ">
      <Listbox
        value={selected}
        onChange={(val) => {
          setSelected(val);
          onSubmit(val);
        }}
      >
        <div className="relative">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm cursor-pointer">
            <span className="block truncate text-[12px] text-[#333] ">
              {selected.name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <img src={down} />
              {/* <ChevronDownIcon
                className="w-5 h-5 text-[#000]"
                aria-hidden="true"
              /> */}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ">
              {data.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                          cursor-pointer select-none relative py-2 pl-2 pr-4 text-xs`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected
                            ? "font-medium text-amber-600"
                            : "font-normal text-[#333]"
                        } block truncate`}
                      >
                        {item.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
