import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import ReactStars from "react-rating-stars-component";

import MainInput from "../general/MainInput";
import DetailButton from "../buttons/CardButton";
import MainTextArea from "../general/MainTextArea";
import SubmitButton from "../buttons/SubmitButton";
import { addReviewBuyer } from "../../config/Apis/ReviewsApi";
export default function ReviewModal({ closeModal, openModal, isOpen, id }) {
  const [reviewText, setReviewText] = useState("");
  const [ratingStars, setRatingStars] = useState(0);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const currentUrl = window.location.href;
  console.log(currentUrl);
  const ratingChanged = (newRating) => {
    setRatingStars(newRating);
  };

  const onSubmit = async () => {
    if (reviewText && ratingStars) {
      try {
        setLoader(true);
        const finalData = {
          _id: id,
          ratingSummery: reviewText,
          ratingStar: ratingStars,
        };
        console.log(reviewText, ratingStars, id);
        const response = await addReviewBuyer(finalData);
        console.log(response);
        window.location.reload();
      } catch (err) {
        console.log(err);
      } finally {
        setError("");
        setLoader(false);
      }
    } else {
      setError("Please fill all the required fields");
    }
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="min-h-full flex flex-col mx-auto w-[80%] justify-center items-center sm:px-6 lg:px-4">
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    value={ratingStars}
                    size={34}
                    classNames="outline-none"
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />

                  <MainTextArea
                    id="reviews"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    inputLabel="Enter Review"
                  />
                  <div className="h-4" />
                  <div className="w-full">
                    <SubmitButton
                      label="Submit"
                      color={"#FF9900"}
                      onClick={onSubmit}
                      loader={loader}
                    />
                  </div>
                  {error && (
                    <p className="text-xs text-red-700 text-center py-2">
                      {error}
                    </p>
                  )}
                  <div className="h-4" />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
