import { BASE_URL, TOKEN, USER_ID } from "../keys";
import axios from "axios";

const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
};

export const getUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}user/${USER_ID}`, getConfig);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const updateUser = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(`${BASE_URL}user`, body, getConfig);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
