import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import appContext from "../../context/AppContext";
import CategoryDropDown from "../dropdown/CategoryDropDown";

function HeaderSearchMobile({
  onSearch,
  searchCategories,
  searchIcon,
  query,
  setQuery,
  setSelectedCategory,
  allCategories,
  ...props
}) {
  const { t } = useTranslation();
  const { isRtl } = useContext(appContext);
  return (
    <div className="min-w-0 flex-1 md:px-8 lg:px-0 hidden md:block   ">
      <div className="flex items-center px-6 py-4 max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
        <div className="w-full border border-[#DCDEE0] rounded-md p-[2px] ">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <form onSubmit={onSearch} className="relative">
            <button
              type="submit"
              className={`pointer-events-none ${
                isRtl ? "left-[3px]" : "right-0"
              } absolute inset-y-0  pr-3 flex items-center`}
            >
              <img src={searchIcon} />
            </button>
            <div className="flex">
              <div className="sm:hidden">
                {/* <div className="h-3 w-3 bg-slate-600"></div> */}
                {Boolean(allCategories.length) && (
                  <CategoryDropDown
                    data={allCategories}
                    onSubmit={(val) => setSelectedCategory(val._id)}
                  />
                )}
              </div>
              <div className="w-[2px] my-1 bg-[#DCDEE0] sm:hidden" />

              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                id="search"
                name="search"
                className="block w-full placeholder:text-[12px] placeholder:text-[#DCDEE0] bg-white  py-2 pl-3 pr-3 text-sm placeholder-[rgba(35, 47, 62, 0.38)] focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={t("enterKeyWord")}
                type="text"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HeaderSearchMobile;
