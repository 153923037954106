import { BASE_URL } from "../keys";
import axios from "axios";

export const addWishlist = (productid) => {
  console.log(productid);
  // console.log(TOKEN);
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));
  // console.log(data.token)
  return new Promise(async (resolve, reject) => {
    try {
      const getConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      };
      const body = JSON.stringify({
        productId: productid,
      });

      const response = await axios.post(`${BASE_URL}wish`, body, getConfig);
      console.log("success", response.data);

      return resolve(response.data);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};

export const getWishlist = () => {
  // console.log(TOKEN);
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));

  return new Promise(async (resolve, reject) => {
    try {
      const getConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      };

      const response = await axios.get(`${BASE_URL}wish`, getConfig);

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const RemoveWishList = (productid) => {
  console.log(productid);
  // console.log(TOKEN);
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));
  // console.log(data.token)
  return new Promise(async (resolve, reject) => {
    try {
      const getConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      };

      const response = await axios.delete(`${BASE_URL}wish/${productid}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
        data: {
          productId: productid,
        },
      });
      console.log("success", response.data);

      return resolve(response.data);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};
