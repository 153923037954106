import React from "react";
import DetailButton from "../buttons/CardButton";

import { Link } from "react-router-dom";
const OrderCart = ({ data }) => {
  return (
    <div className="w-[100%]">
      <div className="bg-white p-5 rounded-md my-2 ">
        <h2 className="text-[22px] text-[#333] font-medium my-2">
          Order Summary
        </h2>

        <div className="mt-1  rounded-md shadow-sm flex ">
          <input
            type="text"
            name="price"
            id="price"
            className="focus:ring-indigo-500 border pl-[10px] text-[16px] placeholder:opacity-25 placeholder:text-[#000] py-2 h-[40px] focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
            placeholder="Coupon Code"
            aria-describedby="price-currency"
          />
          <div className="flex items-center pointer-events-none h-[40px] ">
            <span
              className="text-white font-normal px-3 py-2 bg-[#2C73D2] sm:text-sm"
              id="price-currency"
            >
              Apply
            </span>
          </div>
        </div>
        <div className="my-4">
          <div className="flex justify-between my-2 ">
            <p className="font-medium text-[16px] text-[#333]">
              Subtotal Item(s)
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              {data.products?.length ?? 0}
            </p>
          </div>

          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">
              Total Product Price:
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              {data.totalPrice ?? 0}
            </p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">Tax:</p>
            <p className="font-normal text-[16px] text-[#444444]">
              {data.tax ?? 0}
            </p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">
              Shipping Fee
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              {data.shipping ?? 0}
            </p>
          </div>
        </div>
        <div className="border-b-[1px] border-[#f8f1f1] w-full mx-auto"></div>

        <div className="flex justify-between my-2 mx-1 mb-4">
          <p className="font-medium text-[20px] text-[#444444]">Total</p>
          <p className="font-medium text-[20px] text-[#444444]">
            {data.total ?? 0}
          </p>
        </div>
        {data.cart?.length > 0 ? (
          <Link to={"/check-out"} className="w-[100%] my-4">
            <DetailButton
              bgcolor="#FF9900"
              text="Checkout"
            />
          </Link>
        ) : (
          <DetailButton bgcolor="#FF9900" text="Checkout" />
        )}
      </div>
    </div>
  );
};

export default OrderCart;
