import React from "react";
import { Link } from "react-router-dom";

function OrderProductCard({ product }) {
  return (
    <tr key={product.id}>
      <td className="py-6 pr-8">
        <div className="flex items-center">
          <img
            src={product.productId?.media ? product.productId.media[0].url : ""}
            alt={"Product"}
            className="w-16 h-16 object-center object-cover rounded mr-6"
          />
          <div>
            <div className="font-medium text-gray-900">
              {product?.productId?.title}
            </div>
            <div className="mt-1 hidden lg:block">{product?.price}</div>
          </div>
        </div>
      </td>
      <td className="lg:hidden py-6 pr-2 table-cell">
        {product?.productId?.title}
      </td>
      <td className="lg:hidden py-6 pr-2 table-cell">{product?.price}</td>
      <td className="lg:hidden py-6 pr-2 table-cell">{product?.quantity}</td>
      <td className="py-6 font-medium text-right whitespace-nowrap">
        <Link
          to={`/ProductDetail/${product?.productId?._id}`}
          className="text-indigo-600"
        >
          View
          <span className="hidden lg:inline"> Product</span>
        </Link>
      </td>
    </tr>
  );
}

export default OrderProductCard;
