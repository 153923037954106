import { Fragment, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const categories = [
  { name: "All Categories" },
  { name: "Test Category 1" },
  { name: "Test Category 2" },
  { name: "Test Category 3" },
  { name: "Test Category 4" },
  { name: "Test Category 5" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function FiltersDropdown({
  data,
  title,
  type,
  onSubmit,
  selected1,
  setSelected1,
  onSelection,
  key,
  match,
}) {
  const [selected, setSelected] = useState("");

  const onOptionSelect = (option) => {
    if (type == "all") {
      onTypeAllSelect(option);
    } else {
      onSimpleSelect(option);
    }
  };

  const onTypeAllSelect = (option) => {
    setSelected1(option.id);
    onSelection(option.id, match);
  };

  const onSimpleSelect = (option) => {
    setSelected(option.label);
    onSelection(selected1, option.value);
  };
  return (
    <div className="min-w-[110px]" key={key}>
      <Menu as="div" className="relative inline-block text-left ">
        <div>
          {type == "all" ? (
            <Menu.Button className="group inline-flex justify-center text-[12px]  font-medium text-gray-700 hover:text-gray-900">
              {data[selected1].label}
              <ChevronDownIcon
                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          ) : (
            <Menu.Button className="group inline-flex justify-center text-[12px] font-medium text-gray-700 hover:text-gray-900">
              {data.find((item) => item.selected == true)?.label || title}
              <ChevronDownIcon
                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Menu.Button>
          )}
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left absolute z-10 left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {data.map((option, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <a
                      onClick={() => onOptionSelect(option)}
                      className={
                        type == "all"
                          ? classNames(
                            active ? "bg-gray-100" : "",
                            `block px-4 py-2 text-[12px] font-medium cursor-pointer ${selected1 == option.id
                              ? "text-yellow-500"
                              : "text-gray-900"
                            } `
                          )
                          : classNames(
                            active ? "bg-gray-100" : "",
                            `block px-4 py-2 text-[12px] font-medium cursor-pointer ${option.selected
                              ? "text-yellow-500"
                              : "text-gray-900"
                            } `
                          )
                      }
                    >
                      {option.label}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
