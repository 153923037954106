import React, { useState } from "react";
import Slider from "react-slick";

import TopBrandsCard from "../general/card/TopBrandsCard";
import { RightArrow, LeftArrow } from "../../assets/index";
import { useTranslation } from "react-i18next";
function TopBrands(props) {
  // console.log(props.data);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <div className="w-[100%] py-8 px-8">
        <div className="flex justify-between mb-[30px]">
          <p className="text-xl font-medium"> {t("topBrands")} </p>
          {/* <p className="text-sm font-normal cursor-pointer hover:text-yellow-500">
            See all
          </p> */}
        </div>
        <div className="flex flex-wrap md:flex-nowrap w-[100%] overflow-x-auto mt-4 no-scrollbar  lgth:text-center lgth:w-[100%]">
          {props.data.map((item, index) => (
            <div
              key={index}
              className="lg:min-w-[250px] sm:min-w-[190px] lg:overflow-x-auto scrollbar-hide lgth:w-[19%] mr-[10px]"
            >
              <TopBrandsCard
                id={item._id}
                name={item.name}
                logo={item.logo}
                website={item.website}
                slug={item.slug}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopBrands;
