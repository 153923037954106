import React from "react";
import { useTranslation } from "react-i18next";
import { LocationMarkerIcon } from "@heroicons/react/outline";

function DeliverToMobile({ setDeliveryModal, City, isRtl, ...props }) {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => setDeliveryModal(true)}
      className={`cursor-pointer hidden mx-[10px] my-[4px] w-full px-[0.5%] border-gray-400 sm2:[p-0] sm:flex h-[20px] items-center`}
    >
      <LocationMarkerIcon
        className="block h-[10px] text-[#666666] "
        aria-hidden="true"
      />
      <p className="font-normal text-sm sm2:text-[55%] sm2:leading-4  lg:text-[10px] text-[#666666] ml-[3px]">
        {t("deliverTo")} <span className="font-semibold">{City}</span>
      </p>
    </div>
  );
}

export default DeliverToMobile;
