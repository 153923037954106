import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import MainSlider from "../components/general/MainSlider";
import PopularCategories from "../components/sections/PopularCategories";
import TopBrands from "../components/sections/TopBrands";
import DarkLoader from "../components/general/DarkLoader";
import MainCarousel from "../components/sections/MainCarousel";
import useHome from "../hooks/useHome";
import { useTranslation } from "react-i18next";
import appContext from "../context/AppContext";
import ProductStatusWithOrderWise from "./ProductStatusWithOrderWise";
import Cookies from "js-cookie";
import { loginRequest } from "../config/Apis/AuthApis";
import EmailModal from "../components/modals/EmailModal";
function Home(props) {
  const [showModal, setShowModal] = useState(true);
  const [newEmail, setNewEmail] = useState(false);
  const { data, loader } = useHome();
  const { mainCategories, settingData, settingLoader } = useContext(appContext);
   const { t } = useTranslation();

  const [cookieData, setCookieData] = useState();
  const [tokenData, setTokenData] = useState();
  const [isStatus, setIsStatus] = useState(false);
  const [loginToken, setLoginToken] = useState([]);
console.log("%c setting Data:--->>", "background: purple; color: white",settingData);
  const cookiesData = () => {
    setCookieData(JSON.parse(Cookies.get("session")));
    setTokenData(JSON.parse(Cookies.get("token")));
    setLoginToken(JSON.parse(localStorage.getItem("matjar_user_data")));
  };
   const dynamicSwitching = () => {
    if (Cookies.get("token") || localStorage.getItem("matjar_user_data")) {
      loginRequest(JSON.parse(Cookies.get("token")) || loginToken.token)
        .then((res) => {
          console.log(res.data);
          if (!localStorage.getItem("matjar_user_data")) {
            // setLoggedIn(true);
            localStorage.setItem(
              "matjar_user_data",
              JSON.stringify({
                ...res.data,
                Email: JSON.parse(Cookies.get("email")),
                token: JSON.parse(Cookies.get("token")) || loginToken.token,
              })
            );

            window.location.reload();
            // setTimeout(() => {
            // }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // window.location.reload();
    }
  };
  return (
    <div>
      {Cookies.get("token") && Cookies.get("email") && Cookies.get("modalStatus") == "false" && (
        <>
          <EmailModal
            isOpen={showModal}
            message={`Do you want to continue with this email ${JSON.parse(
              Cookies.get("email")
            )} ?`}
            closeModal={() => {
              setShowModal(false);
              dynamicSwitching();
              setNewEmail(true);
              Cookies.set("modalStatus", true);

              // setIsShow(false);
            }}
            newEmail={() => {
              setNewEmail(false);
              setShowModal(false);
              setIsStatus(true);
              Cookies.remove("Token");
              Cookies.remove("email");
              Cookies.remove("session");
              localStorage.removeItem("matjar_user_data");
              window.location.reload();
            }}
          />
        </>
      )}
      {loader || settingLoader ? (
        <div className="h-screen flex items-center justify-center ">
          <DarkLoader />
        </div>
      ) : (
        <div>
          <Helmet>
            <title> {settingData?.homePage?.metaTitle ?? ""} </title>
            <meta
              name="description"
              content={settingData?.homePage?.metaDescription ?? ""}
            />
            <meta
              name="keywords"
              content={settingData?.homePage?.metaKeywords ?? ""}
            />
          </Helmet>
          <MainSlider data={settingData?.slider} />
          {/* --------- Hot Deals Data --------------- */}
          {Boolean(data.hotDealsData.length) && (
            <MainCarousel title={t("hotDeals")} data={data.hotDealsData} />
          )}
            <div >
            {/* ----------- brands data  --------------*/}
            {Boolean(data.brandsData.length) && (
              <TopBrands data={data.brandsData} />
            )}
            {Boolean(mainCategories?.length) && (
              <PopularCategories
                data={mainCategories.slice(0, 8).map((item) => item) || []}
              />
            )}
          </div>
            <ProductStatusWithOrderWise />
          <img
            src={settingData && settingData?.ads && settingData?.ads[0]?.image}
            className="w-screen h-auto cursor-pointer mb-8"
            onClick={() => window.open(settingData?.ads[0]?.url)}
          />
          {Boolean(data.auctionData.length) && (
            <MainCarousel
              title={t("featuredProducts")}
              data={data.auctionData}
            />
          )}
        </div>
      )}
    </div>
  ); 
}

export default Home;
