import React, { useContext, useState } from "react";
import Geocode from "react-geocode";
import appContext from "../context/AppContext";

function useAddresses(props) {
  const { setError } = useContext(appContext);

  const [coordinates, setCoordinates] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });
  const [location, setLocation] = useState({});
  Geocode.setApiKey("AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU");
  Geocode.setLanguage("en");
  // Geocode.setLocationType("ROOFTOP");

  const onLocationSet = () => {
    // console.log(coordinates);
    Geocode.fromLatLng(coordinates.lat.toString(), coordinates.lng.toString())
      .then((response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, completeAddress;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        completeAddress = response?.plus_code?.compound_code ?? "";
        setLocation({ city, state, country, completeAddress });
        setError("");
      })
      .catch((err) => {
        // console.log(err.message);
        setError("Oops! Something went wrong.");
        window.scrollTo(0, 0);
      });
  };

  return {
    coordinates,
    setCoordinates,
    location,
    setLocation,
    onLocationSet,
  };
}

export default useAddresses;
