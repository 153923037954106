import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "react-error-boundary";
import MainHeader from "../components/header/MainHeader";
import ProductDetail from "../components/sections/ProductDetail";
import ProductDetailSide from "../components/sections/ProductDetailSide";
import HotDealsCard from "../components/general/card/HotDealsCard";
import Specs from "../components/general/Specs";
import SImilarProducts from "../components/sections/SImilarProducts";
import HotDealsBreadCrumbs from "../components/general/HotDealsBreadCrumbs";
import DarkLoader from "../components/general/DarkLoader";
import { getOneProduct, getSeller } from "../config/Apis/ProductApis";
import Crossell from "../components/sections/Crossell";
import { getReviews } from "../config/Apis/ReviewsApi";
import appContext from "../context/AppContext";
import { useTranslation } from "react-i18next";
import ProductDetailVariable from "../components/sections/ProductDetailVariable";
import ErrorFallback from "../components/general/ErrorFallback";
import ProductData from './ProductData';
console.log("Product Detail page")

// Now you can use ProductData in your frontend code
console.log("ProductData: ", ProductData);

function Home(props) {
  const { state, pathname } = useLocation();
  const { settingData } = React.useContext(appContext);
  const { id } = useParams();
  const [loader, setloader] = React.useState(true);
  const [data, setdata] = React.useState({});
  const [sellerData, setSellerData] = React.useState({});
  const [reviews, setReviews] = React.useState([]);
  
  const { t } = useTranslation();

  const getReviewsReq = async (id) => {
    try {
      const response = await getReviews(id);
      setReviews(response.data.list);
    } catch (err) {
      throw err; // Throw the error to be caught by the ErrorBoundary
    }
  };
  const fetchData = async () => {
    try {
      const res = await getOneProduct(id);
      console.log("%c Response of Single Product API: ","background: purple; color: white",res.data)
      setdata(res.data);
      
      const seller = await getSeller(res.data.sellerId);
      await getReviewsReq(res.data._id);
      setSellerData(seller.data);
      setloader(false);
    } catch (err) {
      console.log(err);
      setloader(false);
    }
  };
  useEffect(() => {
    
    fetchData();
  }, []);
console.log("------------ data: ", data)
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        {loader ? (
          <div className="bg-white py-6 h-screen flex items-center justify-center">
            <DarkLoader />
          </div>
        ) : (
          <div className="bg-white grid grid-cols-12 py-6 ">
            <Helmet>
              <title>
                {`${data?.metaTitle || data?.title} | ${settingData.siteTitle}`}
              </title>
              <meta
                name="description"
                content={data?.metaDescription || data?.description}
              />
              <meta name="keywords" content={data?.metaKeyword} />
            </Helmet>
            <div className="col-span-9 lg:col-span-12  ">
              {data.type === "variable" && data.sellingType !== "auction" ? (
                <ProductDetailVariable data={data} reviewsData={reviews} />
              ) : (
                <ProductDetail data={data} reviewsData={reviews} />
              )}
              {Boolean(data?.linkedProduct?.length) && <Crossell />}
              <Specs data={data} reviews={reviews} />
            </div>
            <div className="col-span-3 lg:col-span-12 flex-col flex items-center ">
              {/* Comment */}
              <ProductDetailSide sellerData={sellerData} />
              {Boolean(data?.crosssells?.length) && (
                <>
                  <h1 className="uppercase py-2 lg:pl-4 font-semibold text-xl w-[100%]">
                    {t("recommendedProducts")}
                  </h1>
                  <div className="w-[80%] lg:w-[60%] md:w-[70%] sm:w-[90%] sm:mx-auto mr-[5%] ">
                    <HotDealsCard />
                    <HotDealsCard />
                  </div>
                </>
              )}
            </div>
            {Boolean(data?.upsells?.length) && (
              <div className="col-span-12">
                <SImilarProducts heading={t("customersWhoBought")} />
              </div>
            )}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default Home;
