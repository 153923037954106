import React from "react";
import DarkLoaderSm from "../general/DarkLoaderSm";
import { ChevronDownIcon } from "@heroicons/react/outline";

function LoadMoreButton({ loading, ...props }) {
  return (
    <>
      {loading ? (
        <div className="h-[80px] flex items-center">
          <DarkLoaderSm />
        </div>
      ) : (
        <div
          {...props}
          className="flex flex-col items-center my-[15px] cursor-pointer"
        >
          <button
            {...props}
            className="w-[110px] text-[#666] font-medium  text-sm "
          >
            Load More
          </button>
          <ChevronDownIcon color="#666" className="h-[18px]" />
        </div>
      )}
    </>
  );
}

export default LoadMoreButton;
