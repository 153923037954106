import { BASE_URL, TOKEN, USER_ID } from "../keys";
import axios from "axios";

const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
};

export const getReviews = (productId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${BASE_URL}review/product/${productId}`,
        getConfig
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getPendingReviews = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}review/pending`, {
        ...getConfig,
        params: { limit: 100 },
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getBuyersReviews = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}review/buyer/${USER_ID}`, {
        ...getConfig,
        params: { limit: 100 },
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const addReviewBuyer = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify(data);
      const response = await axios.post(
        `${BASE_URL}review/add`,
        body,
        getConfig
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
