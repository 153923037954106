import { BASE_URL } from "../keys";
import axios from "axios";

let details = JSON.parse(localStorage.getItem("matjar_user_data"));

const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${details?.token ?? ""}`,
  },
};
export const createOrder = (data) => {
  // console.log(TOKEN);
  // console.log(data.token)
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify(data);
      //const response = await axios.post('http://localhost:4000/api/v1/order', body, getConfig);

      const response = await axios.post(`${BASE_URL}order`, body, getConfig);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err.response.data);
      return reject(err);
    }
  });
};

export const createOrderOnline = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify(data);
      const response = await axios.post(
        `${BASE_URL}order/payment`,
        body,
        getConfig
      );
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err.response.data);
      return reject(err);
    }
  });
};
export const getOrders = () => {
  // console.log(TOKEN);
  // console.log(data.token)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${BASE_URL}order/user?page=1&limit=100`,
        getConfig
      );
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};
