import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { chat } from "../../../assets/index";
function ProductDetailSideCard2({ sellerName, sellerId }) {
  const { t } = useTranslation();
  return (
    <div className="w-[95%] bg-[#f7f7fa] lg:mx-auto ">
      <div className=" p-5 my-2 ">
        <div className="flex justify-between ">
          <div className="mb-2">
            <p className="text-[#666666] text-sm font-semibold">
              {t("soldBy")}
            </p>
            <p className="font-semibold">{sellerName}</p>
          </div>
          <div className="flex">
            <p className="mr-1 text-[#FF9900] text-sm font-semibold">
              {t("chat")}
            </p>
            <img src={chat} className="w-6 h-6" />
          </div>
        </div>
        {/* <div className="flex my-2 justify-between">
          <div className="w-1/2">
            <p className="text-xs py-1 text-[#666666]">
              Positive Seller Ratings
            </p>
            <p className="text-3xl font-semibold text-[#333333] ">96%</p>
          </div>
          <div>
            <p className="text-xs py-1 text-[#666666]">Ship on Time</p>
            <p className="text-3xl  font-semibold text-[#333333]">100%</p>
          </div>
        </div> */}
      </div>
      <Link
        to={`/seeall?sellerId=${sellerId}`}
        className="uppercase bg-[#eaeaea] justify-center flex py-1 text-sm font-medium text-[#2C73D2]"
      >
        {t("goToStore")}
      </Link>
    </div>
  );
}

export default ProductDetailSideCard2;
