import React from "react";
import { useTranslation } from "react-i18next";
import NoData from "../../components/general/NoData";

function SpecDetails({ body, type }) {
  const { t } = useTranslation();
  return (
    <div className="fade-anim text-sm">
      {body ? (
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      ) : (
        <div className="py-[10px]">
          <NoData />
        </div>
        // <p className="text-center text-lg my-8">
        //   {`${t("thisProducthas")} ${type} ${t("yet")}.`}
        // </p>
      )}
    </div>
  );
}

export default SpecDetails;
