import { data } from "autoprefixer";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddressCard } from "../../pages/CheckoutAddresses";
import LoadMoreButton from "../buttons/LoadMoreButton";
import DeliverySelector from "../general/DeliverySelector";

const ContactInfo = ({
  deliveries,
  setDeliveries,
  selectedDelivery,
  setSelectedDelivery,
  addresses,
  selected,
  setSelected,
  setSelectedAddress,
  addressesResponse,
  loadMoreAddresses,
  loadMoreLoader,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // console.log(selected);
  console.log("div", deliveries, selected);

  const [Delivery, setDelivery] = useState(deliveries);

  function filterDelivery() {
    console.log(selected.state);

    let deliveryMethodarr = deliveries.filter((item, ind) =>
      item.costs.some((item1) => item1.location.state === selected.state)
    );

    if (deliveryMethodarr.length == 0) {
      let country = selected.country?.split(" ");
      if (country) {
        country =
          selected.country === "UAE"
            ? "UAE"
            : country[0]?.split("")[0] +
              country[1]?.split("")[0] +
              country[2]?.split("")[0];
      }
      console.log("country", country);
      deliveryMethodarr = deliveries.filter((item, ind) =>
        item.costs.some((item1) => item1.location.country === country)
      );
      setDelivery(deliveryMethodarr);
    }
  }

  useEffect(() => {
    filterDelivery();
  }, [selected]);

  return (
    <div className="col-span-7 lg:col-span-12 lg:mx-4">
      <div className="bg-white p-5 rounded-md my-2">
        <h2 className="text-[24px] text-[#333] font-medium my-2">
          {t("contactInfo")}
        </h2>
        <div className="text-[#666666] mb-2">{t("contactInfo1")}</div>
        <div className="border-b-[1px] border-[#f8f1f1] w-full mx-auto"></div>

        <div className="mt-5">
          <label
            htmlFor="email"
            className="block text-sm mb-2 ml-1 text-gray-700"
          >
            {t("email")} *
          </label>
          <div className="mt-1">
            <input
              disabled
              name="email"
              type="email"
              value={props.data.email}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-5 rounded-md">
        <h2 className="text-[24px] text-[#333] font-medium my-2  ">
          {t("shippingAddress")}
        </h2>
        <div className="border-b-[1px] border-[#f8f1f1] w-full mx-auto"></div>

        {console.log("%c Addresses:  -->> ","background: purple; color: white",addresses)}
        <div className="mt-5 flex flex-wrap">
          {addresses.map((item, index) => (
            <AddressCard
              key={index}
              data={item}
              selected={selected}
              setSelected={setSelected}
              setSelectedAddress={setSelectedAddress}
            />
          ))}

          <div
            onClick={() => {
              navigate("/checkoutaddress");
            }}
            className={`w-[320px] h-[136px] border-[#333] border-[1px] border-dashed flex justify-center items-center p-6 bg-[#fff]  cursor-pointer`}
          >
            <p className="text-xl font-light"> {t("addNewAddress")}</p>
          </div>
        </div>
        {addressesResponse.isNext && (
          <div className="min-h-[120px] w-[320px] flex justify-center items-center">
            <LoadMoreButton
              loading={loadMoreLoader}
              onClick={loadMoreAddresses}
            />
          </div>
        )}
        <h2 className="text-[24px] text-[#333] font-medium mb-2 mt-8">
          {t("deliveryMethod")}
        </h2>

        <div className="my-4">
          <DeliverySelector
            data={Delivery}
            selected={selectedDelivery}
            setSelected={setSelectedDelivery}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
