import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import DarkLoader from "../components/general/DarkLoader";
import { getOnePage } from "../config/Apis/PagesApi";
import appContext from "../context/AppContext";

function Page(props) {
  const { settingData } = useContext(appContext);
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const { slug } = useParams();
  const getData = async () => {
    try {
      setLoader(true);
      const response = await getOnePage(slug);
      console.log(response.data);
      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [slug]);
  return (
    <div key={window.location.href} className="py-4 px-4 md:px-1">
      <Helmet>
        <title>
          {" "}
          {`${data?.metaTitle || data?.title} | ${settingData.siteTitle}`}{" "}
        </title>
        <meta
          name="description"
          content={data?.metaDescription || data?.body}
        />
        <meta name="keywords" content={data?.metaKeyword} />
      </Helmet>
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: data.title }}
            className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: data.body }}
            className="mt-4 space-y-6 text-base italic text-gray-600"
          />
        </div>
      )}
    </div>
  );
}

export default Page;
