import React from 'react'

const FeaturedProducts = () => {
  return (
    <div>
      <h1> Featured Products</h1>
      
    </div>
  )
}

export default FeaturedProducts
