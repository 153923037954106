import React from 'react'

const HotDealsProducts = () => {
  return (
    <div>
      <h1> Hot Deals Products</h1>
      
    </div>
  )
}

export default HotDealsProducts
