import { Fragment, useState, useEffect, useContext } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import {
  useLocation,
  useNavigate,
  Link,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SideBarItems } from "../sidebar/AccountSidebar";
import CheckBox from "../general/CheckBox";
import SubCategory from "../general/SubCategory";
import { searchIcon } from "../../assets/index";
import { getMenu } from "../../config/Apis/CategoryApis";
import appContext from "../../context/AppContext";
import SidebarContent from "./SidebarContent";
function FilterSidebar({
  open,
  setOpen,
  category,
  categories,
  brands,
  variants,
  fixedBrands,
  setBrands,
  currentBrand,
  maxLimit,
  minLimit,
  selectedVariant,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Transition.Root appear={true} show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">
                  {t("shopByCategory")}
                </h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="m-4 p-5 w-[90%] bg-white relative">
                <SidebarContent
                  brands={brands}
                  category={category}
                  maxLimit={maxLimit}
                  minLimit={minLimit}
                  variants={variants}
                  setBrands={setBrands}
                  selectedVariant={selectedVariant}
                  fixedBrands={fixedBrands}
                  currentBrand={currentBrand}
                  setOpen={setOpen}
                />
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default FilterSidebar;
