import React from "react";
import { Link } from "react-router-dom";
import SubSubCategory from "./SubSubCategory";

function SubCategory({
  to,
  name,
  currentCategory,
  id,
  parentSelected,
  data,
  setData,
  subSubCategory,
  isSelected,
  setOpen,
  ...props
}) {
  return (
    <>
      {parentSelected && (
        <>
          <div
            style={{ marginRight: props.isRtl ? "20px" : 0 }}
            className="mt-2 ml-8 text-[14px] leading-[28px] flex items-center"
          >
            {subSubCategory.length ? (
              <div
                className="text-[16px] pb-[1px] cursor-pointer"
                onClick={() => {
                  let arr = data.map((item1) => {

                    item1.subCategory.map((item2) => {
                      item2.isSelected = !item2.isSelected;
                    });

                    return { ...item1 };
                  });
                  setData(arr);
                  // console.log(arr);
                }}
              >
                {!isSelected ? "+" : "-"}
              </div>
            ) : (
              <></>
            )}

            <Link onClick={() => setOpen(false)} {...props} to={to}>
              <div
                className={`ml-2 ${
                  id == currentCategory ? "font-semibold" : "font-normal"
                } `}
              >
                {name}
              </div>
            </Link>
          </div>
          {subSubCategory.map((item3) => (
            <>
              <SubSubCategory
                to={`/seeall/${item3._id}`}
                id={item3._id}
                name={item3.name}
                parentSelected={isSelected}
                currentCategory={currentCategory}
                isRtl={props.isRtl}
                setOpen={setOpen}
              />
            </>
          ))}
        </>
      )}
    </>
  );
}

export default SubCategory;
