import React, { Fragment, useEffect, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../modals/Modal";
import SignIn from "../modals/SignIn";
import SignupModal from "../modals/SignUp";
import SessionModal from "../modals/SessionModal";

import {
  logo,
  flag,
  loginUser,
  cartIcon,
  sellWithUs,
  searchIcon,
} from "../../assets/index";
import { getMenu } from "../../config/Apis/CategoryApis";
import appContext from "../../context/AppContext";
import { getCurrentAddress } from "../../config/Apis/AddressApis";
import { getCart } from "../../config/Apis/CartApis";
import { getWishlist } from "../../config/Apis/WishlistApi";
import ErrorAlert from "../general/ErrorAlert";
import DeliveryModal from "../modals/DeliveryModal";
import { currentAddress } from "../../config/keys";
import DeliverToMobile from "./DeliverToMobile";
import { getProductQuantity } from "../../Helper/helperFunctions";
import MainLogo from "./MainLogo";
import MainHeaderSearch from "./MainHeaderSearch";
import DesktopHeaderIcons from "./DesktopHeaderIcons";
import HeaderSearchMobile from "./HeaderSearchMobile";
import HeaderMenu from "./HeaderMenu";
import MobileHeaderIcon from "./MobileHeaderIcon";
import HeaderMenuIcon from "./HeaderMenuIcon";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MainHeader() {
  const {
    cartItems,
    loggedIn,
    setLoggedIn,
    setIsOpen,
    SetWishlist,
    isOpen,
    updateCartCount,
    setIsRtl,
    isRtl,
    error,
    setError,
    setSessionModal,
    sessionModal,
    City,
    setCity,
    settingData,
    setMainCategories,
    mainCategories,
    setCardData,
  } = React.useContext(appContext);
  const [allCategories, setallCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [cartOpen, setCartOpen] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("matjar_user_data"));
   const selleremail = JSON.parse(localStorage.getItem("selleremail"));
   
   const selleremaill = localStorage.getItem("selleremail");
   console.log("Seller Emailll", selleremaill)
    // console.log(user);
    const isRTL = localStorage.getItem("isRtl") || "false";
    if (isRTL == "false") {
      i18n.changeLanguage("en");
      setIsRtl(false);
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }

    if (isRTL == "true") {
      i18n.changeLanguage("ar");
      setIsRtl(true);
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    }

    if (currentAddress?.state) {
      setCity(currentAddress.state);
    } else {
      getCurrentAddress()
        .then((res) => {
          setCity(res.city);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if(selleremail || user){
    // if (user) {
      setLoggedIn(true);

      getCart()
        .then((res) => {
          const productQuantity = getProductQuantity(res);
          setCardData(res.data);
          updateCartCount(productQuantity);
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 401) {
            setSessionModal(true);
          }
        });
    }
    getMenu()
      .then(async (res) => {
        let data = [{ name: "All Categories" }, ...res];
        // console.log("menu -----> ", res);
        setMainCategories(res);
        setSearchCategories(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onSearch = async (e) => {
    e.preventDefault();
    navigate(`/seeall/search?query=${query}&categoryId=${selectedCategory}`);
    setQuery("");
  };

  const onSignOut = () => {
    setLoggedIn(false);
    SetWishlist([]);
    updateCartCount(0);
    localStorage.clear();
    window.location.replace("/");
  };
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-[1920px] mx-auto">
              <div className=" relative min-h-[75px] shadow-sm justify-between grid grid-cols-12 gap-8">
                <MainLogo />
                <MainHeaderSearch
                  onSearch={onSearch}
                  query={query}
                  searchCategories={searchCategories}
                  searchIcon={searchIcon}
                  setQuery={setQuery}
                  setSelectedCategory={setSelectedCategory}
                />

                <HeaderMenuIcon open={open} />

                <DesktopHeaderIcons
                  cartIcon={cartIcon}
                  cartItems={cartItems}
                  cartOpen={cartOpen}
                  flag={flag}
                  isRtl={isRtl}
                  loggedIn={loggedIn}
                  loginUser={loginUser}
                  onSignOut={onSignOut}
                  openModal={openModal}
                  sellWithUs={sellWithUs}
                  setCartOpen={setCartOpen}
                  setIsRtl={setIsRtl}
                  settingData={settingData}
                />
              </div>
              <HeaderSearchMobile
                onSearch={onSearch}
                query={query}
                searchCategories={searchCategories}
                searchIcon={searchIcon}
                setQuery={setQuery}
                setSelectedCategory={setSelectedCategory}
                allCategories={mainCategories}
              />
              <HeaderMenu
                City={City}
                allCategories={mainCategories}
                isRtl={isRtl}
                setDeliveryModal={setDeliveryModal}
              />
              <div className="w-[98%] mx-auto">
                <DeliverToMobile
                  setDeliveryModal={setDeliveryModal}
                  City={City}
                  isRtl={isRtl}
                />
              </div>
            </div>
            <Popover.Panel
              as="nav"
              className="lg:block hidden"
              aria-label="Global"
            >
              <MobileHeaderIcon
                cartIcon={cartIcon}
                cartItems={cartItems}
                cartOpen={cartOpen}
                flag={flag}
                isRtl={isRtl}
                loggedIn={loggedIn}
                loginUser={loginUser}
                onSignOut={onSignOut}
                openModal={openModal}
                sellWithUs={sellWithUs}
                setCartOpen={setCartOpen}
                setIsRtl={setIsRtl}
                settingData={settingData}
              />
            </Popover.Panel>
            {/*<Login closeModal={closeModal} openModal={openModal} isOpen={isOpen}/>*/}
            {isOpen && (
              <SignIn
                onSubmit={() => {
                  setLoggedIn(true);
                }}
                closeModal={closeModal}
                isOpen={isOpen}
                onClose={closeModal}
              />
            )}
          </>
        )}
      </Popover>
      {error && <ErrorAlert error={error} onClose={() => setError(false)} />}
      <SessionModal
        closeModal={() => setSessionModal(false)}
        isOpen={sessionModal}
        openModal={() => setSessionModal(true)}
        onLogout={onSignOut}
      />
      {deliveryModal && (
        <DeliveryModal
          closeModal={() => {
            setDeliveryModal(false);
            if (loggedIn) return;
            window.location.reload();
          }}
          isOpen={deliveryModal}
          onNewAddress={() => {
            navigate("/checkoutaddress");
            setDeliveryModal(false);
          }}
          onConfirm={(address) => {
            console.log(address);
            localStorage.setItem("currentAddress", JSON.stringify(address));
            setDeliveryModal(false);
            window.location.reload();
          }}
        />
      )}
    </>
  );
}
