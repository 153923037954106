import React from "react";

function NoData(props) {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={"/assets/noData.svg"} alt="noData" className="h-[50px]" />
      <p className="text-[12px] text-[#999] ">No Data</p>
    </div>
  );
}

export default NoData;
