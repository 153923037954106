import React from "react";
import OrderProductCard from "./OrderProductCard";

function OrderCard1({ order, key }) {
  return (
    <div key={key}>
      <div className="bg-gray-50 rounded-lg py-6 px-4 sm:px-6 flex lg:block items-center">
        <dl className="text-sm w-[80%] text-gray-600 sm:divide-y-0 grid grid-cols-3 lg:grid-cols-1 lg:w-full gap-x-2">
          <div className="justify-between block lg:flex">
            <dt className="font-medium text-gray-900">Date placed</dt>
            <dd className="sm:mt-1">
              <time dateTime={order?.created_at}>
                {order?.created_at
                  ? new Date(order?.created_at).toLocaleDateString()
                  : ""}
              </time>
            </dd>
          </div>
          <div className="justify-between block lg:flex">
            <dt className="font-medium text-gray-900">Order number</dt>
            <dd className="sm:mt-1">{order.orderId}</dd>
          </div>
          <div className="justify-between block lg:flex">
            <dt className="font-medium text-gray-900">Total amount</dt>
            <dd className="sm:mt-1">{order.total}</dd>
          </div>
        </dl>

        {/* <div className="">
          <a
            href={order.invoiceHref}
            className=" flex w-auto lg:justify-center items-center justify-end bg-white  py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            View Invoice
            <span className="sr-only">for order {order.number}</span>
          </a>
        </div> */}
      </div>

      <table className="mt-4 w-full text-gray-500 sm:mt-6">
        <thead className="text-sm text-gray-500 text-left lg:hidden">
          <tr>
            <th scope="col" className="w-1/5 pr-8 py-3 font-normal">
              Product
            </th>
            <th scope="col" className="w-1/5 pr-8 py-3 font-normal">
              Name
            </th>
            <th
              scope="col"
              className="sm:hidden w-1/5 pr-8 py-3 font-normal table-cell"
            >
              Price
            </th>
            <th
              scope="col"
              className="sm:hidden pr-8 py-3 font-normal table-cell"
            >
              Quantity
            </th>
            <th scope="col" className="w-0 py-3 font-normal text-right">
              Info
            </th>
          </tr>
        </thead>
        <tbody className="border-b border-gray-200 divide-y divide-gray-200 text-sm border-t">
          {order.products.map((product) => (
            <OrderProductCard product={product} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderCard1;
