import React, { useContext, useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import FooterCard from "../general/card/FooterCard";
import { getNavigation } from "../../config/Apis/NavigationApis";
import {
  footerLinkedin,
  footerFb,
  footerTwitter,
  chatnowNext,
} from "../../assets/index";
import { getSettings } from "../../config/Apis/SettingsApi";
import appContext from "../../context/AppContext";
import { useTranslation } from "react-i18next";

function MainFooter(props) {
  const [navigationData, setNavigationData] = useState([]);
  const { settingData, setSettingData, isRtl } = useContext(appContext);

  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responses = await Promise.all([getNavigation()]);
      setNavigationData(responses[0]?.data?.list ?? []);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <div className="w-full mx-auto">
      <div className="bg-gradient-to-r py-10 from-[#4481EB] to-[#07B5F0] min-h-[150px] flex justify-center md:flex-col items-center ">
        <p className="text-white text-[28px] font-normal lg:text-lg md:px-9 mr-3 text-center">
          {/* {t("cantFind")} */}
          {settingData.chatNowText}
        </p>
        <div className="flex min-w-[157] md:mt-2 items-center px-3 hover:-translate-y-[1px] transition-all">
          <a
            href={settingData.chatNowUrl}
            target="_blank"
            className="bg-white rounded-sm min-h-[54px] md:min-h-[45px] py-1 px-5 flex items-center"
          >
            <p className="text-[16px] md:text-[14px] uppercase text-[#444] font-medium pr-2">
              {t("chatNow")}
            </p>
            <img
              style={{
                transform: isRtl ? "rotate(180deg)" : "rotate(0deg)",
                marginRight: isRtl ? "10px" : "0px",
              }}
              src={chatnowNext}
              alt=""
            />
            {/* <ChevronRightIcon className="h-5 w-5" /> */}
          </a>
        </div>
      </div>

      <div className="flex w-full max-w-[1440px] mx-auto py-4 px-8 md:flex-col bg-[#fff]">
        {navigationData.map((item, ind) => {
          if (item.menu !== "") {
            return (item.for === "buyer" && item.location === "footer") &&
              <div className="flex-1" key={ind}>
                <FooterCard
                  title={item.menu}
                  data={item.pages}
                />
              </div>
          }

        })}
      </div>
      {/* {t("copyright")} &copy; {new Date().getFullYear()}{" "}
            {settingData.siteTitle} | {t("allRights")} */}
      <div className="flex justify-center w-[100%] border-t-[1px] border-[#f0f0f0] mx-auto">
        <div className="py-4 flex w-full max-w-[1440px] mx-auto sm:flex-col px-8 justify-between items-center ">
          <p className="text-[14px] md:text-[10px] ">
            {`Copyright © ${new Date().getFullYear()}`}{" "}
            {settingData.copyrightText}
          </p>
          <a href="/help">Help</a>
          {Boolean(settingData.social) && (
            <div className="flex">
              {settingData.social.map((item, index) => (
                <div key={index}>
                  {item.status == "active" && (
                    <div
                      className="relative flex flex-col items-center group"
                      key={index}
                    >
                      <a target={"_blank"} href={item.url}>
                        <img
                          src={item.icon}
                          className="h-[30px] w-[30px] mx-1 md:h-[25px] md:w-[25px] "
                        />
                      </a>
                      <div className="absolute bottom-[10px] flex-col items-center hidden mb-6 group-hover:flex">
                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
                          {item.type}
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div >
  );
}

export default MainFooter;
