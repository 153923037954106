import React from "react";
import Slider from "react-slick";

import { heading_underline } from "../../assets/index";
import HotDealsCard from "../general/card/HotDealsCard";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { RightArrow, LeftArrow } from "../../assets/index";

function SImilarProducts({ heading }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    centerMode: true,
    centerPadding: "10px",
    slidesToScroll: 1,
    nextArrow: <img src={RightArrow} />,
    prevArrow: <img src={LeftArrow} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <div className="p-2">
      <div>
        <div className="px-4">
        <p className="text-2xl py-2"> {heading} </p>
        <img src={heading_underline} className="w-full" />
        </div>
        <div className="flex justify-center">
          <Slider className="w-[95%] md:w-[82%] py-6"  {...settings}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, ind) => (
              <div key={ind} >
                <div className="w-[220px]">
                  <HotDealsCard />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default SImilarProducts;
