import { BASE_URL, TOKEN } from "../keys";
import axios from "axios";

export const getAllBrands = (page, limit, type) => {
  // console.log(TOKEN);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${BASE_URL}brand?page=${page}&limit=${limit}`
      );
      console.log("%c success get all brands", "background: black; color: white",response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getPopularBrands = () => {
  // console.log(TOKEN);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}brand/popular`);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
