import React, { useState, useEffect } from "react";
import { getMenu, getPopularCategories } from "../config/Apis/CategoryApis";
import { getAllBrands, getPopularBrands } from "../config/Apis/BrandsApis";
import {
  getAllProducts,
  getAllProductsClean,
} from "../config/Apis/ProductApis";
import { getSettings } from "../config/Apis/SettingsApi";

function useHome(props) {
  const [data, setData] = useState({
    hotDealsData: [],
    brandsData: [],
    auctionData: [],
    navigationData: [],
  });

  const [loader, setLoader] = useState(true);
  const getAllData = async () => {
    try {
      const response = await Promise.allSettled([
        getPopularBrands(),
        getAllProducts({ page: 1, limit: 5 }),
      ]);
      console.log("brands --> ", response[1].data);
      console.log("%c Response From useHome.js", "background: purple; color: white", response)
      setData({
        brandsData: response[0]?.value.data && (response[0]?.value.data.filter((item) => item.isFeatured == true) ?? []),
        // brandsData: response[1]?.data ?? [],
        // hotDealsData:
        //   response[2]?.data?.list?.products.filter(
        //     (item) => item.isHotdeal == true
        //   ) ?? [],
        hotDealsData: response[1]?.data?.list?.products ?? [],

        auctionData:
          response[1]?.data?.list?.products.filter(
            (item) => item.isFeatured == true
          ) ?? [],
      });
      setLoader(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return { data, setData, loader, setLoader };
}

export default useHome;
