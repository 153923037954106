import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import OrderCard from "../components/general/card/OrderCard";
import OrderCard1 from "../components/general/card/OrderCard1";
import DarkLoader from "../components/general/DarkLoader";
import NoData from "../components/general/NoData";
import Tabs from "../components/general/Tabs";
import TabsMobile from "../components/general/TabsMobile";
import { getOrders } from "../config/Apis/OrderApi";

const tabs = [
  { name: "all", id: 0 },
  { name: "processing", id: 1 },
  { name: "paymentPending", id: 2 },
  { name: "completed", id: 3 },
];

export default function Orders(props) {
  const [selected, setSelected] = useState(0);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoader(true);
      const response = await getOrders();
      console.log(response.data.list);
      setProducts(response?.data?.list ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const renderAll = (
    <div className="space-y-10">
      {products.filter((item) => item.status == "processing").length ? (
        <>
          {products.map((order) => (
            <OrderCard1 key={order._id} order={order} />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </div>
  );

  const renderProcessing = (
    <div className="space-y-10">
      {products.filter((item) => item.status == "processing").length ? (
        <>
          {products
            .filter((item) => item.status == "processing")
            .map((order) => (
              <OrderCard1 key={order._id} order={order} />
            ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </div>
  );

  const renderPending = (
    <div className="space-y-10">
      {products.filter((item) => item.status == "pendingPayment").length ? (
        <>
          {products
            .filter((item) => item.status == "pendingPayment")
            .map((order) => (
              <OrderCard1 key={order._id} order={order} />
            ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </div>
  );

  const renderCompleted = (
    <div className="space-y-10">
      {products.filter((item) => item.status == "completed").length ? (
        <>
          {products
            .filter((item) => item.status == "completed")
            .map((order) => (
              <OrderCard1 key={order._id} order={order} />
            ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Helmet>
        <title> {`Orders | ${props.siteTitle}`} </title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6  lg:px-8">
        <div className="w-full">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-xll">
            {t("orderHistory")}
          </h1>
          <p className="mt-2 text-sm text-gray-500">{t("orderHistory1")}</p>
        </div>
        <Tabs tabs={tabs} selected={selected} setSelected={setSelected} />
        <TabsMobile tabs={tabs} selected={selected} setSelected={setSelected} />
        <div className="mt-6">
          {loader ? (
            <div>
              <DarkLoader />
            </div>
          ) : (
            <>
              {selected == 0 && renderAll}
              {selected == 1 && renderProcessing}
              {selected == 2 && renderPending}
              {selected == 3 && renderCompleted}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

// <div className="block pt-0">
// <dt className="font-medium text-gray-900">
//   Order number
// </dt>
// <dd className="sm:mt-1">{order.number}</dd>
// </div>
// <div className=" font-medium text-gray-900 block">
// <dt>Total amount</dt>
// <dd className="sm:mt-1">{order.total}</dd>
// </div>
