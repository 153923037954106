import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

import { mainSlide, bullet, bulletFill, car1, car2 } from "../../assets/index";
import { Link } from "react-router-dom";

const Slide1 = ({ data }) => {
  return (
    <a href={data.url} target="_blank" className="max-w-full  relative ">
      {/* <img src={data.image} className="w-screen " /> */}
      <img src={data.image}
      style={{
        width: '100%',
        height: '400px',
    imageRendering: "pixelated",
  }} />
    </a>
    // <div class="bg-cover bg-center h-screen w-screen"  style={{ backgroundImage: `url(${data.image})` }}>
  // {/* <!-- Content goes here --> */}
// </div>
  );
};

let count = 0;
let slideInterval;

function MainSlider({ data }) {
  const [current, setCurrent] = useState(0);

  const handleOnNextClick = () => {
    count = (count + 1) % data.length;
    // console.log(count);
    setCurrent(count);
  };

  const startSlider = () => {
    slideInterval = setInterval(() => {
      handleOnNextClick();
    }, 3000);
  };

  useEffect(() => {
    startSlider();

    return () => clearInterval(slideInterval);
  }, []);
  return (
    <div className="justify-center">
      <div>
        {data.map((item, index) => (
          <div
            key={index}
            className={`transition-all duration-1000 ${
              current == item.order - 1 ? "opacity-100" : "hidden opacity-0"
            }`}
          >
            <Slide1 data={item} />
          </div>
        ))}
      </div>

      {/* <div className="flex bottom-4 right-10 ">
        {data.map((item, index) => {
          return (
            <img
              key={index}
              src={current == item.order - 1 ? bulletFill : bullet}
              alt=""
              className="mx-1 cursor-pointer"
              onClick={() => setCurrent(item.order - 1)}
            />
          );
        })}
      </div> */}
    </div>
  );
}

export default MainSlider;
