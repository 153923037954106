import React, { useEffect, useState } from "react";
import HistoryReviewedCard from "../components/general/card/HistoryReviewedCard";
import ToBeReviewedCard from "../components/general/card/ToBeReviewedCard";
import Tabs from "../components/general/Tabs";
import DarkLoader from "../components/general/DarkLoader";
import TabsMobile from "../components/general/TabsMobile";

import { getBuyersReviews, getPendingReviews } from "../config/Apis/ReviewsApi";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import NoData from "../components/general/NoData";

const tabs = [
  { name: "toBeReviewed", id: 0 },
  { name: "history", id: 1 },
];

function MyReviews(props) {
  const [selected, setSelected] = useState(0);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [completedReviews, setCompletedReviews] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoader(true);
      const responses = await Promise.all([
        getPendingReviews(),
        getBuyersReviews(),
      ]);
      console.log(responses);
      setPendingReviews(responses[0].data.list);
      setCompletedReviews(responses[1].data.list.filter(item => item.status === "active"));
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const renderCompleted = (
    <>
      {completedReviews.length ? (
        <>
          {completedReviews.map((item) => (
            <HistoryReviewedCard data={item} />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </>
  );

  const renderPending = (
    <>
      {pendingReviews.length ? (
        <>
          {pendingReviews.map((item) => (
            <ToBeReviewedCard data={item} />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          <NoData />
        </div>
      )}
    </>
  );

  return (
    <div className="py-4 px-4 md:px-1">
      <Helmet>
        <title> {`Reviews | ${props.siteTitle}`} </title>
      </Helmet>
      <div className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
        {t("reviews")}
      </div>

      <Tabs tabs={tabs} selected={selected} setSelected={setSelected} />
      <TabsMobile tabs={tabs} selected={selected} setSelected={setSelected} />
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <>
          {selected == 0 && renderPending}
          {selected == 1 && renderCompleted}
        </>
      )}
    </div>
  );
}

export default MyReviews;
