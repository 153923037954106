import React from 'react'
import{
  whishlist, HotDealImage, AunctionIcon, useNavigate, Link, addWishlist, getTimeRemaining, getWishlist, appContext, helperFunctions, ImagePlaceHolder, t
  } from './index.js'
function HotDealsCard(props) {
  const navigate = useNavigate();
// ---------- Remaining Products ------------
  const remainingCount =
    props.data?.variants?.length && props.data?.sellingType != "auction"
      ? props.data?.variants[0].quantity - props.data?.soldCount
      : props.data?.quantity - props.data?.soldCount;
// ----------- Remaining Products Percent
  const remainingPercent = props.data?.variants?.length
    ? (remainingCount / props.data?.variants[0]?.quantity) * 100
    : (remainingCount / props.data?.quantity) * 100;
  const { SetWishlist } = React.useContext(appContext);
  const timeLeft = getTimeRemaining(
    props.data?.auction?.endingDate || new Date()
  );
  //  console.log("props.data.sellingType", props.data.sellingType, props.sellinType, props.selected)
  return (
    <div
      className="flex justify-start items-center
  flex-col min-w-[100%] h-[305px]
  bg-white shadow-md rounded-lg relative cursor-pointer"
      onClick={() =>
        navigate(`/ProductDetail/${props.data?._id}`, { state: props.data })
      }
    >
      <div className="p-3 w-full h-full">
        <div className="absolute" style={{ top: "5px", left: "5px" }}>
          {props.data?.isSale ? (
            <div className="bg-[#F14705] p-1 rounder-md">
              <p className="text-[10px] font-normal text-[#fff] ">
                {props.data?.variants?.length
                  ? helperFunctions(
                      props.data?.variants[0]?.price -
                        props.data?.variants[0]?.salePrice,
                      props.data?.variants[0]?.price
                    ).SavedPercent.toFixed(1)
                  : helperFunctions(
                      props.data?.salePrice,
                      props.data?.price
                    ).SavedPercent.toFixed(1)}
                % OFF
              </p>
            </div>
          ) : (
            <div className="bg-white p-1 rounder-md"></div>
          )}
          {/* <div>
            <img
              src={whishlist}
              className="h-[20px] cursor-pointer"
              onClick={() =>
                addWishlist(props.data?._id)
                  .then((res) => {
                    getWishlist()
                      .then((res) => {
                        SetWishlist(res.data.list);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  })
              }
            />
          </div> */}
        </div>
        <div className="flex py-4">
          <div className="w-[100%] h-[100px] flex items-center overflow-hidden">
            <img
              src={
                props.data?.variants?.length
                  ? props.data?.variants[0]?.media[0]?.url
                    ? props.data?.variants[0]?.media[0]?.url
                    : ImagePlaceHolder
                  : props.data?.media[0]?.url
                  ? props.data?.media[0]?.url
                  : ImagePlaceHolder
              }
              className="h-full mx-auto"
              // style={{ height: '100px' }}
            />
          </div>
        </div>
        {/* ------- auction ------------- */}
        <div className="flex w-[100%] justify-end">
          {props.data.sellingType == "auction" ? (
            <div className="flex">
              <div className="h-[25px] text-[#999999] text-[10px] px-[3px] bg-[#F6F6F6] flex items-center">
                {timeLeft} - {props.data?.bid?.length || 0} bids
              </div>
              <img src={AunctionIcon} className="h-[25px] self-end" />
            </div>
          ) : (
            <div />
          )}
        </div>
        {/* ************** auction ****************** */}
        {/* <div className="flex w-[100%] justify-end items-center"></div> */}
        <p className="my-1 h-[20px] text-[10px] font-light text-[#999999]">
          {props.data?.categoryId?.name ?? ""}
        </p>
        
        {/* <Link to={`/ProductDetail/${props.data?._id}`} state={props.data}> */}
        <p className="mt-2 mb-1 text-[12px] min-h-[42px] font-medium text-[#2C73D2]">
          {/* {props.data?.title} */}
          {props.data?.title.length > 50 ? `${props.data.title.substring(0,50)}...` : props.data.title}
        </p>
        {/* </Link> */}
        <div className="flex items-center">
          {/* {console.log(props.data)} */}
          {props.data.sellingType === "auction" ? (
            <p className="text-[16px] font-bold text-[#333]">
              {`AED ${props.data?.auction?.openingPrice}`}
            </p>
          ) : (
            <p className="text-[16px] font-bold text-[#333]">
              {props.data?.type === "variable"
                ? `AED ${
                    props.data?.variants?.length
                      ? props.data?.variants[0]?.price -
                          props.data?.variants[0]?.salePrice ||
                        props.data?.variants[0]?.salePrice
                      : 0
                  }`
                : `AED ${props.data?.finalPrice}`}
            </p>
          )}

          {props.data?.isSale && (
            <p className="text-[11px] font-light text-[#AAAAAA] ml-2 absolute right-2">
              {props.data?.variants?.length ? (
                <s>AED {props.data?.variants[0]?.price}</s>
              ) : (
                <s>AED {props.data.price}</s>
              )}
            </p>
          )}
        </div>
        {props.data?.sellingType == "auction" ? (
          <div className="h-[16px]" />
        ) : (
          <div className="my-1 w-[100%] md:w-auto h-[10px] bg-gray-200 rounded-lg">
            <div
              style={{ width: `${remainingPercent}%` }}
              className={`h-[100%] bg-[#FFBF2D] rounded-lg`}
            ></div>
          </div>
        )}

        {props.data?.sellingType == "auction" ? (
          <div className="h-[22px]">
            {timeLeft === "0d 0h" && (
              <p className="font-semibold text-red-600 w-[100%] text-[10px]">
                {t("auctionExpire")}
              </p>
            )}
          </div>
        ) : (
          <>
            {props.data?.variants?.length ? (
              <>
                {props.data?.variants[0].quantity == 0 ? (
                  <p className="my-1 font-semibold text-red-600 w-[100%] text-[10px] lgth:text-[9px] lgth2:text-[10px] text-black-500">
                    {t("outOfStock")}
                  </p>
                ) : (
                  <p className="my-1 w-[100%] text-[10px] lgth:text-[9px] lgth2:text-[10px] font-light text-black-500">
                    Hurry! Only{" "}
                    <span className="font-semibold text-red-600">
                      {remainingCount}
                    </span>{" "}
                    Left in Stock.
                  </p>
                )}
              </>
            ) : (
              <>
                {props.data?.quantity === 0 ? (
                  <p className="my-1 font-semibold text-red-600 w-[100%] text-[10px] lgth:text-[9px] lgth2:text-[10px] text-black-500">
                    {t("outOfStock")}
                  </p>
                ) : (
                  <p className="my-1 w-[100%] text-[10px] lgth:text-[9px] lgth2:text-[10px] font-light text-black-500">
                    Hurry! Only{" "}
                    <span className="font-semibold text-red-600">
                      {remainingCount}
                    </span>{" "}
                    Left in Stock.
                  </p>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default HotDealsCard;
