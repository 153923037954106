import React from 'react'

const Brands = () => {
  return (
    <div>
      <h1> Brands</h1>
      
    </div>
  )
}

export default Brands
