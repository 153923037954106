import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import appContext from "../../context/AppContext";

export default function ScrollToTop({ errorRef }) {
  const { pathname } = useLocation();
  const { setError } = useContext(appContext);
  useEffect(() => {
    setError("");
    errorRef.current.resetErrorBoundary();
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
