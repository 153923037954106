import React, { useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainFooter from "../components/footer/MainFooter";
import CheckOut from "../pages/CheckOut";
import Home from "../pages/Home";
import SeeAll from "../pages/SeeAll";
import ProductDetail from "../pages/ProductDetail";
import ScrollToTop from "../components/general/ScrollToTop";
import ShoppingCart from "../pages/ShoppingCart";
import MainHeader from "../components/header/MainHeader";
import Account from "../pages/Account";
import Thankyou from "../pages/Thankyou";
import CheckoutWrapper from "../pages/CheckoutWrapper";
import CheckoutAddress from "../pages/CheckoutNewAddress";
import NotFound404 from "../pages/NotFound404";
import EditAddress from "../pages/EditAddress";
import Page from "../pages/Page";
import ErrorFallback from "../components/general/ErrorFallback";
import Maintenance from "../pages/Maintenance";
import Help from "../pages/Help";
// import Signin  from "../prac/Signin";

const Router = (props) => {
  const errorRef = useRef();

  return (
    <div>
      <BrowserRouter>
        <MainHeader />
        <ScrollToTop errorRef={errorRef} />

        <div className="w-full bg-[#f8f8f8] pb-8">
          <div className="max-w-[1440px] mx-auto">
            <ErrorBoundary ref={errorRef} FallbackComponent={ErrorFallback}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/help" element={<Help />} />
                <Route
                  path="/seeAll/:categoryid/:search"
                  element={<SeeAll />}
                />
                <Route path="/seeAll/:categoryid" element={<SeeAll />} />
                <Route path="/seeAll/" element={<SeeAll />} />
                <Route path="/check-out" element={<CheckoutWrapper />} />
                <Route path="/ProductDetail/:id" element={<ProductDetail />} />
                <Route path="/cart" element={<ShoppingCart />} />
                <Route path="/checkoutaddress" element={<CheckoutAddress />} />
                {/* <Route path="/thankyou" element={<Thankyou />} /> */}
                <Route path="/account/*" element={<Account />} />
                <Route path="/page/:slug" element={<Page />} />
                <Route path="*" element={<NotFound404 />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
        <MainFooter />
      </BrowserRouter>
    </div>
  );
};

export default Router;
