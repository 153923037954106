import React, { useState } from "react";
import { useContext } from "react";
import CheckoutSlider from "../components/general/CheckoutSlider";
import EmptyCart from "../components/general/EmptyCart";
import appContext from "../context/AppContext";
import CheckOut from "./CheckOut";
import CheckoutAddresses from "./CheckoutAddresses";
import Thankyou from "./Thankyou";

function CheckoutWrapper(props) {
  const [selected, setSelected] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [orderResponse, setOrderResponse] = useState({});
  const { cartData } = useContext(appContext);
  console.log("%c checkoutWapper--> cartData","background: purple; color: white", cartData);
  console.log("%c checkoutWapper--> cartData","background: purple; color: white", cartData);
 
  return (
    <div className="bg-[#F8F8F8]">
{/*        {cartData?.cart?.length ? ( */}
      {!cartData?.cart?.length ? (
        <>
          <CheckoutSlider selected={selected} setSelected={setSelected} />
          {/* {selected == 1 && (
        <CheckoutAddresses
          setParentSelected={setSelected}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )} */}
          {selected == 1 && (
            <CheckOut
              setOrderResponse={setOrderResponse}
              selectedAddress={selectedAddress}
              setParentSelected={setSelected}
            />
          )}
          {selected == 2 && <Thankyou data={orderResponse} />}
        </>
      ) : (
        <EmptyCart from="checkout" />
      )}
    </div>
  );
}

export default CheckoutWrapper;
