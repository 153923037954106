import React, { useState } from "react";
import ContactInfo from "../components/CheckOut/ContactInfo";
import Header from "../components/CheckOut/Header";
import Order from "../components/CheckOut/Order";
import PaymentMethod from "../components/CheckOut/PaymentMethod";
import DarkLoader from "../components/general/DarkLoader";
import { createOrder } from "../config/Apis/OrderApi";
import {
  getCurrentAddress,
  addAddress,
  getAddressById,
  getAllAddresses,
} from "../config/Apis/AddressApis";
import { getCart } from "../config/Apis/CartApis";
import { useNavigate, Link } from "react-router-dom";
import appContext from "../context/AppContext";
import { getPaymentMethods } from "../config/Apis/PaymentsApis";
import { getShippingMethods } from "../config/Apis/ShippingApis";
import { getToken, pay } from "../config/Payment/Service";
import { details } from "../config/keys";
import { useTranslation } from "react-i18next";
import useCheckout from "../hooks/useCheckout";
const CheckOut = ({ setOrderResponse, setParentSelected }) => {
  const navigate = useNavigate();
  const {
    setSelectedAddress,
    selected,
    setSelected,
    error,
    data,
    setData,
    selectedDelivery,
    cartData,
    paymentMethods,
    selectedPayment,
    setSelectedPayment,
    deliveryMethods,
    setSelectedDelivery,
    placeLoader,
    addresses,
    loadMoreLoader,
    addressesResponse,
    loader,
    loadMoreAddresses,
    Checkout,
    setError,
    setDeliveryMethods
  } = useCheckout({ setOrderResponse, setParentSelected });
  const { t } = useTranslation();
  return (
    <div>
      {/* <Header /> */}
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <div className="flex-1 grid grid-cols-12 py-4">
          <div className="col-span-1"></div>
          <ContactInfo
            selected={selected}
            setSelected={setSelected}
            setSelectedAddress={setSelectedAddress}
            addresses={addresses}
            addressesResponse={addressesResponse}
            loadMoreAddresses={loadMoreAddresses}
            loadMoreLoader={loadMoreLoader}
            data={data}
            setData={setData}
            deliveries={deliveryMethods}
            setDeliveries={setDeliveryMethods}
            selectedDelivery={selectedDelivery}
            setSelectedDelivery={setSelectedDelivery}
          />

          <div className="col-span-3 lg:col-span-12 mx-4">
            <Order data={cartData} />
            <PaymentMethod
              loader={placeLoader}
              payments={paymentMethods}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              error={error}
              setError={setError}
              onClicked={(card) => Checkout(card)}
            />
            <p className="ml-2 mt-2 block text-xs text-[#888888] text-center">
              {t("uponClick")}
            </p>
          </div>

          <div className="col-span-1"></div>
        </div>
      )}
    </div>
  );
};

export default CheckOut;
