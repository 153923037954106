import React, { useContext, useEffect, useState } from "react";
import MainInput from "../components/general/MainInput";
import { uploadimage } from "../config/imageupload";
import GenderDropdown from "../components/dropdown/GenderDropdown";
import { getUser, updateUser } from "../config/Apis/UserApis";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import appContext from "../context/AppContext";
import SuccessModal from "../components/modals/SuccessModal";
export default function Profile(props) {
  const { t } = useTranslation();
  const { setError } = useContext(appContext);
  const [imgUrl, setImgUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const genderData = [{ name: "Male" }, { name: "Female" }];
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    gender: genderData[0].name,
    profilePic: "",
    _id: "",
  });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const user = await getUser();
      console.log(user.data);
      setData({
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.data.email,
        dob: user.data.dob ? user.data.dob.split("T")[0] : data.dob,
        gender: user.data.gender || genderData[0].name,
        profilePic: user.data.profilePic,
        _id: user.data._id,
      });
      setImgUrl(user.data.profilePic);
    } catch (err) {
      console.log(err);
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const res = await updateUser(data);
      console.log(res);
      setError("");
      setShowModal(true);
    } catch (err) {
      console.log(err.response.data);
      setError(err.response.data.message);
      window.scrollTo(0, 0);
    } finally {
      setLoader(false);
    }
  };

  const onImageUpload = async (e) => {
    try {
      const res = await uploadimage(e.target.files[0]);
      console.log(res);
      setImgUrl(res.location);
      setData({ ...data, profilePic: res.location });
      setError("");
    } catch (err) {
      setError(err.statusText);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="py-4 px-4 md:px-1">
        <Helmet>
          <title>{`Profile | ${props.siteTitle}`}</title>
        </Helmet>
        <div className=" md:gap-6">
          <div className="py-2">
            <div className="px-2 sm:px-0">
              <h3 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
                {t("profile")}
              </h3>
              <p className="mt-1 text-sm text-gray-600">{t("profile1")}</p>
            </div>
          </div>
          <div className="mt-5 ">
            <form onSubmit={submitData}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white mt-5 ">
                  <div className="mt-5 flex w-full md:flex-col">
                    <div className="w-full">
                      <label className="block text-sm font-medium text-gray-700">
                        {t("photo")}
                      </label>
                      <div className="mt-1 flex items-center">
                        <span className="inline-block h-24 w-24 rounded-full overflow-hidden bg-gray-100">
                          {imgUrl ? (
                            <img
                              src={data.profilePic}
                              className="h-full w-full"
                            />
                          ) : (
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          )}
                        </span>

                        <label
                          htmlFor="file-upload"
                          className="mx-5 cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <span>{t("change")}</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            className="sr-only"
                            onChange={onImageUpload}
                          />
                        </label>
                      </div>
                    </div>
                    <div className=" w-full self-end">
                      <MainInput
                        value={data.email}
                        id="email"
                        inputLabel={t("email")}
                        type={"email"}
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex w-full md:flex-col">
                    <MainInput
                      value={data.firstName}
                      onChange={(e) =>
                        setData({ ...data, firstName: e.target.value })
                      }
                      id="firstName"
                      inputLabel={t("firstName")}
                    />
                    <MainInput
                      value={data.lastName}
                      onChange={(e) =>
                        setData({ ...data, lastName: e.target.value })
                      }
                      id="lastName"
                      inputLabel={t("lastName")}
                    />
                  </div>

                  <div className="mt-5 flex w-full md:flex-col">
                    <MainInput
                      value={data.dob}
                      onChange={(e) =>
                        setData({ ...data, dob: e.target.value })
                      }
                      id="dob"
                      inputLabel={t("dob")}
                      type="date"
                    />
                    <div className="mt-5 w-full">
                      <div className="text-sm text-gray-700 mx-2 ">
                        {" "}
                        {t("gender")}{" "}
                      </div>

                      <div className="flex">
                        {genderData.map((item, ind) => (
                          <div
                            className="mt-1 flex items-center mx-2"
                            key={ind}
                          >
                            <input
                              value={item.name}
                              onChange={(e) =>
                                setData({ ...data, gender: e.target.value })
                              }
                              name={item.name}
                              type="radio"
                              checked={item.name == data.gender}
                              className="h-4 w-4 ml-1 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label
                              htmlFor={item.name}
                              className="block text-sm ml-1 text-gray-700"
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    disabled={loader}
                    type="submit"
                    className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  ${
                      loader
                        ? "bg-stone-400"
                        : "hover:bg-indigo-700 bg-indigo-600"
                    }  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showModal}
        message={"Your profile has been updated successfully."}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
