import S3 from "react-aws-s3";

export const details = JSON.parse(localStorage.getItem("matjar_user_data"));
export const currentAddress = JSON.parse(
  localStorage.getItem("currentAddress")
);

// export const BASE_URL = "https://api-matjars-qa.dspaco.com/api/v1/";
//export const BASE_URL = 'http://ec2-157-175-160-106.me-south-1.compute.amazonaws.com:5003/api/v1/';
console.log("%c token from keys.js", "background: orange; color:white",details?.token, (details ? "true" : "false"));
export const BASE_URL = "https://api-matjars-qa.dspaco.com/api/v1/";
//export const BASE_URL = "http://localhost:4000/api/v1/"
export const POOL_ID = "us-east-1_q9xkM9JJh";
export const USER_ID = details ? details._id : "";
export const CLIENT_ID = "7ra93jvdjcu30j24alr29jplv0";
export const TOKEN = details ? details.token : "";
export const s3config = {
  bucketName: "admin-matjar",
  // bucketName: 'admin-matjar',
  dirName: "accounts" /* optional */,
  region: "me-south-1",
  s3Url: "https://admin-matjar.s3.me-south-1.amazonaws.com",
  accessKeyId: "AKIAWDQJ5ISA4BZVUTOT",
  secretAccessKey: "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",
};

const ReactS3Client = new S3(s3config);

export { ReactS3Client };

// https://api-mj.dsinc.cyou/api/v1/
