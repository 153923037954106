import React, { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import { useTranslation } from "react-i18next";

import DetailButton from "../components/buttons/CardButton";
import AddressSection from "../components/sections/AddressSection";
import useAddresses from "../hooks/useAddresses";
import useMyMaps from "../hooks/useMyMaps";
const Map = (props) => {
  const { coordinates, onDrag, onMapClicked, onMarker, setCoordinates } =
    useMyMaps({ onChanged: props.onChanged });

  return (
    <GoogleMap
      onClick={onMapClicked}
      defaultZoom={10}
      defaultCenter={coordinates}
    >
      {props.isMarkerShown && (
        <Marker
          draggable
          ref={onMarker}
          onDrag={onDrag}
          // onPositionChanged={onPositionChanged}
          position={coordinates}
        />
      )}
    </GoogleMap>
  );
};

//const WrappedMap = withScriptjs(withGoogleMap(Map));
const WrappedMap = withScriptjs(withGoogleMap(props => <Map {...props} />));

function Addresses({ data }) {
  const { t } = useTranslation();
  const { coordinates, location, setCoordinates, setLocation, onLocationSet } =
    useAddresses();

  return (
    <div className="w-[90%] mx-auto">
      <div className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
        {t("addNewAddress")}
      </div>
      <WrappedMap
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB2k_2Wcx9y7qjIg2M9OWZOuDpzwmeHNVU&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onChanged={(val) => setCoordinates(val)}
      />
      <div className="w-36 my-2 ">
        <DetailButton
          onClicked={onLocationSet}
          bgcolor={"#2c73d2"}
          text={t("confirm")}
        />
      </div>
      <AddressSection location={location} coordinates={coordinates} />

    </div>
  );
}

export default Addresses;
