export const logo = require("./Logo.png");
export const flag = require("./flag.png");
export const loginUser = require("./loginUser.png");
export const cartIcon = require("./cartIcon.png");
export const sellWithUs = require("./ep_sell.png");
export const mainSlide = require("./mainSlideImg.png");
export const LeftArrow = require("./LeftArrow.png");
export const RightArrow = require("./RightArrow.png");
export const brakes = require("./Brake-Discs-Pads.png");
export const batteries = require("./Batteries.png");
export const engine = require("./EngineParts.png");
export const oil = require("./Oillubricants.png");
export const servicekit = require("./ServiceKits.png");
export const steering = require("./Steering.png");
export const suspension = require("./Suspension.png");
export const tires = require("./TiresWheels.png");

export const WishlistSelected = require("./wishlist-selected.png");
export const HotDealImage = require("./HotDealImage1.png");
export const AunctionIcon = require("./AunctionIcon.png");
export const footerLinkedin = require("./footerLinkedin.png");
export const footerFb = require("./FooterFb.png");
export const footerTwitter = require("./FooterTwitter.png");
export const brandCover = require("./brandCover.png");
export const menu_underline = require("./menu_underline.png");
export const EyeIcon = require("./EyeIcon.png");
export const CartUpIcon = require("./cartup.png");
export const ShareIcon = require("./ShareIcon.png");
export const verified = require("./verified.png");
export const timer = require("./timer.png");
export const chat = require("./chat.png");
export const heading_underline = require("./heading_underline.png");
export const deleteAllIcon = require("./deleteAllIcon.png");
export const deleteIcon = require("./deleteIcon.png");
export const whishlist = require("./whishlist.png");
export const bullet = require("./bullet.png");
export const bulletFill = require("./bulletFill.png");
export const car1 = require("./car1.jpg");
export const car2 = require("./car2.jpg");
export const down = require("./down.png");
export const searchIcon = require("./searchIcon.png");
export const chatnowNext = require("./chatnowNext.png");
export const creditcards = require("./creditcards.png");
export const tires1 = require("./tires.png");
export const plus = require("./plus.png");
