import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AunctionIcon } from "../../assets/index";
import SubmitButton from "../buttons/SubmitButton";
import MainInput from "../general/MainInput";
import { getTimeRemaining } from "../../Helper/helperFunctions";
import { postAuction } from "../../config/Apis/ProductApis";
import DarkLoaderSm from "../general/DarkLoaderSm";
import appContext from "../../context/AppContext";

export default function BidModal({
  closeModal,
  openModal,
  isOpen,
  data,
  bidAmount,
  setBidAmount,
  isBidSelected,
}) {
  const currentUrl = window.location.href;

  const [bidError, setBidError] = useState(false);
  const [bidConfirmError, setBidConfirmError] = useState("");
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(1);
  const { isRtl } = useContext(appContext);
  const { t } = useTranslation();
  const { auction } = data;
  const timeLeft = getTimeRemaining(auction.endingDate);
  const { bid } = data;
  const bidPercent = data.auction.openingPrice * 0.03;
  const onBidSubmit = () => {
    if (bidAmount) {
      console.log(bidAmount);
      console.log(bidAmount < data.auction.openingPrice + bidPercent);
      if (bidAmount < data.auction.openingPrice + bidPercent) {
        setBidError(
          `Bid amount must be greater than ${
            data.auction.openingPrice + bidPercent
          }`
        );

        return;
      }

      setStatus(2);
    } else {
      setBidError("Please enter a valid amount");
    }
  };

  const placeBid = (amount) => {
    setStatus(2);
    setBidAmount(amount);
    setBidError("");
  };

  const bidConfirm = async () => {
    setLoader(true);
    try {
      const res = await postAuction({
        productId: data._id,
        bid: parseInt(bidAmount),
      });
      console.log(res);
      setBidConfirmError("");
      setStatus(3);
    } catch (err) {
      setBidConfirmError(err.message);
    } finally {
      setLoader(false);
    }
  };

  const BidContent = () => {
    return (
      <>
        <p
          className={`text-[20px] font-bold text-[#333] ${
            isRtl ? "text-right" : "text-left"
          }`}
        >
          {t("aed")} {data.auction.openingPrice}
        </p>
        <div className="flex">
          <div className="h-[25px] text-[#999999] text-[12px] px-[3px] bg-[#F6F6F6] flex items-center">
            {timeLeft} - {bid?.length || 0} bids
          </div>
          <img src={AunctionIcon} className="h-[25px] self-end" />
        </div>
        <p
          className={`mt-[10px] mb-[20px] text-[15px] text-[#444] font-semibold ${
            isRtl ? "text-right" : "text-left"
          }`}
        >
          {t("placeUrBid")}
        </p>
        <div className="flex mb-[20px]">
          <SubmitButton
            color={"#2C73D2"}
            label={`${t("aed")} ${
              data.auction.openingPrice + data.auction.bidIncrement + bidPercent
            }`}
            onClick={() =>
              placeBid(
                data.auction.openingPrice +
                  data.auction.bidIncrement +
                  bidPercent
              )
            }
          />
          <div className="w-[15px]" />
          <SubmitButton
            color={"#2C73D2"}
            label={`${t("aed")} ${
              data.auction.openingPrice +
              data.auction.bidIncrement * 2 +
              bidPercent * 2
            }`}
            onClick={() =>
              placeBid(
                data.auction.openingPrice +
                  data.auction.bidIncrement * 2 +
                  bidPercent * 2
              )
            }
          />
          <div className="w-[15px]" />
          <SubmitButton
            color={"#2C73D2"}
            label={`${t("aed")} ${
              data.auction.openingPrice +
              data.auction.bidIncrement * 3 +
              bidPercent * 3
            }`}
            onClick={() =>
              placeBid(
                data.auction.openingPrice +
                  data.auction.bidIncrement * 3 +
                  bidPercent * 3
              )
            }
          />
        </div>
        <div className="flex items-center justify-around">
          <div className="h-[1px] w-[45%] bg-[#888]" />
          <p>{t("or")}</p>
          <div className="h-[1px] w-[45%] bg-[#888]" />
        </div>
        <div className="flex items-end">
          <MainInput
            isRtl={isRtl}
            id="bid"
            inputLabel={t("aed")}
            type="number"
            onChange={(e) => {
              console.log(e.target.value);
              setBidAmount(e.target.value);
              setBidError(false);
            }}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                onBidSubmit();
              }
            }}
            value={bidAmount}
          />
          <div className="w-[10px]" />
          <div className="w-[50%] mb-[5px]">
            <SubmitButton
              color={"#2C73D2"}
              label={t("placeBid")}
              onClick={() => {
                onBidSubmit();
              }}
            />
          </div>
        </div>
        {bidError && (
          <p className="text-[13px] text-red-800 text-center mt-[10px]">
            {bidError}
          </p>
        )}
      </>
    );
  };

  const BidConfirmation = () => {
    return (
      <div className="flex flex-col min-h-[280px] justify-center items-center">
        <p>Are you sure you want to place this bid?</p>
        {!loader ? (
          <div className="flex w-[60%] my-[20px]">
            <SubmitButton color={"#2C73D2"} label="Yes" onClick={bidConfirm} />
            <div className="w-[15px]" />
            <SubmitButton
              color={"red"}
              label="No"
              onClick={() => {
                if (isBidSelected) {
                  closeModal();
                  setBidConfirmError("");
                } else {
                  setStatus(1);
                  setBidConfirmError("");
                }
              }}
            />
          </div>
        ) : (
          <div className="my-[20px]">
            <DarkLoaderSm />
          </div>
        )}
        {bidConfirmError && (
          <p className="text-[13px] text-red-800 text-center mt-[10px]">
            {bidConfirmError}
          </p>
        )}
      </div>
    );
  };

  const BidSuccess = () => {
    return (
      <div className="flex flex-col min-h-[280px] justify-center items-center">
        <img src="/assets/successOk.png" alt="success" className="h-[70px]" />
        <p className="text-[18px] font-medium text-[#333]">
          Your bid has been placed successfully.
        </p>
        <div className="my-[10px]">
          <SubmitButton
            color={"#2C73D2"}
            label="Continue"
            onClick={closeModal}
          />
        </div>
      </div>
    );
  };
  // console.log(currentUrl);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            closeModal();
          }}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 sm:p-[15px] my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {isBidSelected ? (
                  <div className="min-h-[280px] mx-auto w-[95%] sm:w-full justify-between sm:px-6 lg:px-4">
                    {status == 1 && BidConfirmation()}
                    {status == 2 && BidSuccess()}
                  </div>
                ) : (
                  <div className="min-h-[280px] mx-auto w-[95%] sm:w-full justify-between sm:px-6 lg:px-4">
                    {status == 1 && BidContent()}
                    {status == 2 && BidConfirmation()}
                    {status == 3 && BidSuccess()}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
