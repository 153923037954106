import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import MenuCard from "../general/card/MenuCard";
import { toTitleCase } from "../../Helper/helperFunctions";

const categories = [
  { name: "All Categories" },
  { name: "Lifestyle" },
  { name: "Brakes" },
  { name: "Drivetrain" },
  { name: "Engine" },
  { name: "Exterior" },
  { name: "Intake" },
  { name: "Interior" },
  { name: "Lights" },
  { name: "Restoration" },
  { name: "Suspension" },
  { name: "Tuners" },
  { name: "Wheels & Tires" },
];

export default function AllCategoryDropDown({ data, isRtl }) {
  const [selected, setSelected] = useState("");

  return (
    <div className="w-40">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative w-full font-medium py-2 pl-3 pr-10 text-left bg-white  cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate text-[#333] text-[14px]">
              {selected == "" ? "All Categories" : selected}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 text-[#333]"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute top-[42px] ${
                isRtl ? "right-[3px] pr-2" : "left-[-6px]"
              }  z-20 w-auto py-1 mt-1 overflow-visible text-base bg-white shadow-lg min-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
            >
              {data.map((item, personIdx) => (
                <Link key={personIdx} to={`/seeall/${item._id}`}>
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `${active ? "text-yellow-400 " : "text-gray-900"}
                          cursor-pointer select-none relative py-2 pl-2 text-xs`
                    }
                    value={item.name}
                  >
                    {({ selected, active }) => (
                      <div className="relative group">
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate mr-8`}
                        >
                          {toTitleCase(item.name)}
                        </span>
                        {/* {selected ? ( */}
                        <span
                          className={`${
                            active ? "text-yellow-400" : "text-gray-900"
                          }
                                absolute inset-y-0 ${
                                  isRtl ? "left-2" : "right-2"
                                } flex items-center`}
                        >
                          {item.name == "All Categories" ? (
                            <></>
                          ) : (
                            <>
                              {Boolean(item.subCategory.length) && (
                                <ChevronRightIcon
                                  className={`w-5 h-5  ${
                                    isRtl ? "rotate-180" : ""
                                  }`}
                                  aria-hidden="true"
                                />
                              )}
                            </>
                          )}
                        </span>
                        {/* // ) : null} */}
                        {Boolean(item.subCategory.length) && (
                          <div
                            className={`hidden ${
                              isRtl ? " right-[826px]" : " left-[140px]"
                            } top-0 absolute z-10 group-hover:block `}
                          >
                            {item.name == "All Categories" ? (
                              <></>
                            ) : (
                              <div className="absolute top-[-4px] left-[118px] ">
                                <MenuCard from="all" data={item} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                </Link>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
