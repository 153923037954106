import React from "react";
import { useTranslation } from "react-i18next";
import { LocationMarkerIcon } from "@heroicons/react/outline";

function DeliverTo({ setDeliveryModal, City, isRtl, ...props }) {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => setDeliveryModal(true)}
      className={`cursor-pointer w-[220px] sm:hidden mx-[10px] my-[4px] border-gray-400 flex items-center `}
    >
      <div className="h-[30px] w-[1px] mx-[5px] bg-[#acacac]" />
      <LocationMarkerIcon
        className="block h-[20px] text-[#666666] sm2:hidden"
        aria-hidden="true"
      />
      <p className="font-normal text-[13px] sm2:leading-4  lg:text-[10px] text-[#666666] ml-[5px]">
        {t("deliverTo")}{" "}
        <span className="font-semibold">
          {City?.length > 8 ? (
            <>
              {City.substring(0, 7)}
              <span className="tooltip">
                ... <span className="tooltiptext">{City}</span>{" "}
              </span>
            </>
          ) : (
            City
          )}
        </span>
      </p>
    </div>
  );
}

export default DeliverTo;
