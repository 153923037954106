import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SubmitButton from "../components/buttons/SubmitButton";
import OrderCard from "../components/general/card/OrderCard";

/* This example requires Tailwind CSS v2.0+ */

export default function Example({ data }) {
  const { t } = useTranslation();
  // console.log(data);
  return (
    <div className="bg-white">
      <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
            {t("thankyou")}!
          </h1>
          <p className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
            {t("onWay")}!
          </p>
          <p className="mt-2 text-base text-gray-500">
            {t("onWay1")} #{data.orderId} {t("onWay2")}.
          </p>

          {/* <dl className="mt-12 text-sm font-medium">
            <dt className="text-gray-900">Tracking number</dt>
            <dd className="text-indigo-600 mt-2">51547878755545848512</dd>
          </dl> */}
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Your order</h2>

          <h3 className="sr-only">Items</h3>
          {/* {products.map((product) => (
            <OrderCard product={product} />
          ))} */}

          <div>
            <h3 className="sr-only">Your information</h3>

            <h4 className="sr-only">Addresses</h4>

            <h4 className="sr-only">Payment</h4>
            <dl className="grid grid-cols-2 md:block gap-x-6 border-t border-gray-200 text-sm py-10">
              <div>
                <dt className="font-medium text-gray-900">
                  {t("paymentMethod")}
                </dt>
                <dd className="mt-2 text-gray-700 uppercase">
                  <p>{data.paymentType}</p>
                  {/* <p>Mastercard</p>
                  <p>
                    <span aria-hidden="true">•••• </span>
                    <span className="sr-only">Ending in </span>1545
                  </p> */}
                </dd>
              </div>
              <div className="md:mt-2">
                <dt className="font-medium  text-gray-900">
                  {t("shippingMethod")}
                </dt>
                <dd className="mt-2 text-gray-700">
                  <p>Fullfilled by Matjars.</p>
                  <p>Takes up to 3 working days</p>
                </dd>
              </div>
            </dl>

            <h3 className="sr-only">Summary</h3>

            <dl className="space-y-6 border-t border-gray-200 text-sm pt-10">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{t("subtotal")}</dt>
                <dd className="text-gray-700">
                  {" "}
                  {t("aed")} {data.subTotal}{" "}
                </dd>
              </div>
              {/* {JSON.stringify(data)} */}
              {/* <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  Discount
                  <span className="rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 ml-2">
                    STUDENT50
                  </span>
                </dt>
                <dd className="text-gray-700">-$18.00 (50%)</dd>
              </div> */}
              {/* <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{t("shipping")}</dt>
                <dd className="text-gray-700">
                  {" "}
                  {t("aed")} {data?.shipping}{" "}
                </dd>
              </div> */}
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{t("tax")}</dt>
                <dd className="text-gray-700">
                  {" "}
                  {t("aed")} {data?.tax}{" "}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">{t("total")}</dt>
                <dd className="text-gray-900">
                  {" "}
                  {t("aed")} {data.total}{" "}
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
            <p>
              <Link to={"/"}></Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
