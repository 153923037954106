import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import OrderCard from "../components/general/card/OrderCard";
import Darkloader from "../components/general/DarkLoader";
import NoData from "../components/general/NoData";
import { getWishlist } from "../config/Apis/WishlistApi";
function Whishlist(props) {
  const [data, setdata] = React.useState([]);
  const [loader, setloader] = React.useState(true);
  const { t } = useTranslation();
  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getWishlist()
      .then((res) => {
        console.log("wish --> ", res.data.list);
        setdata(res?.data?.list[0].productIds);
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  return loader ? (
    <div className="flex justify-center py-[150px] w-[100%] ">
      <Darkloader />
    </div>
  ) : (
    <div className="py-4 px-4 md:px-1">
      <Helmet>
        <title> {`Wishlist | ${props.siteTitle}`} </title>
      </Helmet>
      <div className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
        {t("whishlist")}
      </div>

      {Boolean(data.length) ? (
        <>
          {data.map((product) => (
            <OrderCard onRefresh={getData} product={product} />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center py-[110px]">
          {/* {t("wishlistEmpty")} */}
          <NoData />
        </div>
      )}
    </div>
  );
}

export default Whishlist;
