import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { verified } from "../../../assets/index";
import appContext from "../../../context/AppContext";
function ProductDetailSIdeCard({ data }) {
  const { t } = useTranslation();
  const { isRtl } = useContext(appContext);
  return (
    <div className="w-[95%] bg-[#f7f7fa] p-5 my-2 lg:mx-auto lgth:px-3">
      <div className="flex items-center ">
        <img
          src={data.icon}
          className={`w-5 h-5 ${isRtl ? "ml-1 lgth:ml-2" : "mr-1 lgth:mr-2"}  `}
        />
        <p className="lgth:text-[16px]">{t(data.heading)}</p>
      </div>
      <div className="text-[#666666] text-sm lgth:px-0 lgth:text-[14px]">
        {t(data.desc)}
      </div>
    </div>
  );
}

export default ProductDetailSIdeCard;
