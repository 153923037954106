import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AccountSidebar from "../components/sidebar/AccountSidebar";
import Addresses from "./Addresses";
import Orders from "./Orders";
import Profile from "./Profile";
import Whishlist from "./Whishlist";
import MyReviews from "./MyReviews";
import AddressesWrapper from "./AddressesWrapper";
import EditAddress from "./EditAddress";
import { MenuIcon } from "@heroicons/react/outline";
import AccountSidebarMobile from "../components/sidebar/AccountSidebarMobile";
import { details } from "../config/keys";
import appContext from "../context/AppContext";

function Account(props) {
  const { settingData } = useContext(appContext);
  console.log("%c Setting Data:::", "background: purple; color: white", settingData)
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!details) {
      navigate(-1);
    }
  }, []);
  return (
    <div className="bg-[#f5f5f5] grid grid-cols-12 ">
      <div className="col-span-2">
        <div className="justify-center flex mt-6">
          <MenuIcon
            className="h-6 w-6 cursor-pointer hidden md:block"
            onClick={() => setOpen(true)}
          />
        </div>
        <div>
          <AccountSidebarMobile open={open} setOpen={setOpen} />
        </div>
        <div className="md:hidden">
          <AccountSidebar />
        </div>
      </div>
      <div className="col-span-10">
        <Routes>
          <Route
            path="orders"
            element={<Orders siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="addresses"
            element={<AddressesWrapper siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="/"
            element={<Profile siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="profile"
            element={<Profile siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="whishlist"
            element={<Whishlist siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="reviews"
            element={<MyReviews siteTitle={settingData.siteTitle} />}
          />
          <Route
            path="editaddress/:addressId"
            element={<EditAddress siteTitle={settingData.siteTitle} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default Account;
