import React from 'react'

import { whishlist, HotDealImage, AunctionIcon } from "../../../assets/index";
import { useNavigate, Link } from "react-router-dom";
import { addWishlist, getWishlist } from "../../../config/Apis/WishlistApi";
import appContext from "../../../context/AppContext";
import {
  getTimeRemaining,
  helperFunctions,
} from "../../../Helper/helperFunctions";
import ImagePlaceHolder from "../../../assets/imgplace.png";
import { t } from "i18next";
const AuctionCard = (props) => {
  const navigate = useNavigate();
  const remainingCount =
    props.data?.variants?.length && props.data?.sellingType != "auction"
      ? props.data?.variants[0].quantity - props.data?.soldCount
      : props.data?.quantity - props.data?.soldCount;

  const remainingPercent = props.data?.variants?.length
    ? (remainingCount / props.data?.variants[0]?.quantity) * 100
    : (remainingCount / props.data?.quantity) * 100;
  const { SetWishlist } = React.useContext(appContext);
  const timeLeft = getTimeRemaining(
    props.data?.auction?.endingDate || new Date()
  );
  console.log("Auction card is running")
  return (
    // <div
    //         //  key={index}
    //           className={`max-w-[280px] sm:w-[46%] w-[80%] mr-[10px] mb-[20px] lgth:w-[172px] lgth2:w-[183px] lgth3:w-[200px] lgth4:w-[209px] lgth5:w-[220px] lgth6:w-[233px] lgth6:mr-[20px]`}
    //         >
     <div
      className="flex justify-start items-center
  flex-col min-h-[330px] min-w-[100%] 
  bg-white shadow-md rounded-lg relative cursor-pointer"
      onClick={() => navigate(`/ProductDetail/${props.data?._id}`, { state: props.data })}
    >
    {/* <h1>hello</h1> */}
      <div className="p-3 w-full">
      {/* <div className="absolute" style={{ top: "5px", left: "5px" }}>
          {props.data?.isSale ? (
            <div className="bg-[#F14705] p-1 rounder-md">
              <p className="text-[12px] font-normal text-[#fff]">
                {props.data?.variants?.length
                  ? helperFunctions(
                    props.data?.variants[0]?.price -
                    props.data?.variants[0]?.salePrice,
                    props.data?.variants[0]?.price
                  ).SavedPercent.toFixed(1)
                  : helperFunctions(
                    props.data?.salePrice,
                    props.data?.price
                  ).SavedPercent.toFixed(1)}
                % OFF
              </p>
            </div>
          ) : (
            <div className="bg-white p-1 rounder-md"></div>
          )}
          
        </div> */}
        <div
          className="flex py-0"
        >
          <div className="w-[100%] h-[200px] flex items-center overflow-hidden">
            <img
              src={
                props.data?.variants?.media?.length 
                  ? props.data?.variants[0]?.media[0]?.url
                    ? props.data?.variants[0]?.media[0]?.url
                    : ImagePlaceHolder
                  : props?.data?.media[0]?.url
                    ? props.data?.media[0]?.url
                    : ImagePlaceHolder
              }
              className="w-[100%]  lgth:h-[180px]"
            />
          </div>
        </div>
        <div className="flex w-[100%] justify-end">
          {/* {props.data.sellingType == "auction" ? ( */}
            <div className="flex">
              <div className="h-[25px] text-[#999999] text-[12px] px-[3px] bg-[#F6F6F6] flex items-center">
                {timeLeft} - {props.data?.bid?.length || 0} bids
              </div>
              <img src={AunctionIcon} className="h-[25px] self-end" />
            </div>
          {/* ) : (
            <div className="h-[25px]" />
          )} */}
        </div>
        <div className="flex w-[100%] justify-end items-center"></div>
        <p className="my-2 h-[20px] text-[12px] font-light text-[#999999]">
          {props.data?.categoryId?.name ?? ""}
        </p>
        {/* <Link to={`/ProductDetail/${props.data?._id}`} state={props.data}> */}
        <p className="mt-5 mb-2 text-[14px] min-h-[42px] font-medium text-[#2C73D2]">
          {props.data?.title.length > 73 ? ` ${props.data?.title.substring(0,73)}...` : props.data?.title}
        </p>
        {/* </Link> */}
        <div className="flex items-center">
          {/* {console.log(props.data)} */}
          {/* {props.data.sellingType == "auction" ? ( */}
            <p className="text-[18px] font-bold text-[#333]">
              {`AED ${props.data?.auction?.openingPrice}`}
            </p>
          {/* ) : (
            <p className="text-[18px] font-bold text-[#333]">
              {props.data?.type == "variable"
                ? `AED ${props.data?.variants?.length
                  ? props.data?.variants[0]?.price -
                  props.data?.variants[0]?.salePrice ||
                  props.data?.variants[0]?.salePrice
                  : 0
                }`
                : `AED ${props.data?.finalPrice}`}
            </p>
          )} */}

        </div>
        {/* {props.data?.sellingType == "auction" ? ( */}
          <div className="h-[18px]" />
        {/* ) : (
          <div className="my-1 w-[100%] md:w-auto h-[10px] bg-gray-200 rounded-lg">
            <div
              style={{ width: `${remainingPercent}%` }}
              className={`h-[100%] bg-[#FFBF2D] rounded-lg`}
            ></div>
          </div>
        )} */}

        {/* {props.data?.sellingType == "auction" ? ( */}
          <div className="h-[22px]">
            {timeLeft == "0d 0h" && (
              <p className="font-semibold text-red-600 w-[100%] text-[12px]">
                {t("auctionExpire")}
              </p>
            )}
          </div>
        {/* ) : (
          <>
            {props.data?.variants?.length ? (
              <>
                {props.data?.variants[0].quantity == 0 ? (
                  <p className="my-1 font-semibold text-red-600 w-[100%] text-[12px] lgth:text-[11px] lgth2:text-[12px] text-black-500">
                    {t("outOfStock")}
                  </p>
                ) : (
                  <p className="my-1 w-[100%] text-[12px] lgth:text-[11px] lgth2:text-[12px] font-light text-black-500">
                    Hurry! Only{" "}
                    <span className="font-semibold text-red-600">
                      {remainingCount}
                    </span>{" "}
                    Left in Stock.
                  </p>
                )}
              </>
            ) : (
              <>
                {props.data?.quantity == 0 ? (
                  <p className="my-1 font-semibold text-red-600 w-[100%] text-[12px] lgth:text-[11px] lgth2:text-[12px] text-black-500">
                    {t("outOfStock")}
                  </p>
                ) : (
                  <p className="my-1 w-[100%] text-[12px] lgth:text-[11px] lgth2:text-[12px] font-light text-black-500">
                    Hurry! Only{" "}
                    <span className="font-semibold text-red-600">
                      {remainingCount}
                    </span>{" "}
                    Left in Stock.
                  </p>
                )}
              </>
            )}
          </>
        ) */}
        {/* } */}
      </div>
    </div>
    // </div>
 )
  
}

export default AuctionCard
