import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabsMobile({ tabs, selected, setSelected }) {
  const { t } = useTranslation();
  return (
    <div className="mt-2 hidden sm:block">
      <div className=" block">
        <div className="border-l border-gray-200">
          <nav className="" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                onClick={() => setSelected(tab.id)}
                key={tab.id}
                className={classNames(
                  selected == tab.id
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap cursor-pointer py-2 px-1 border-l-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {t(tab.name)}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
