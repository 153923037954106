
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import SellerLogin from './SellerLogin';
const SellerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button  className='text-[#333] lgth3:text-[12px] lgth:text-[12px] p-[2px]' style={{border:'none', background:'none', color:'black',boxShadow:'none'}} onClick={showModal}>
        Sell With Us
      </button>
      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>want to stay with same email 
            {/* <a href='/buyer-login'> */}
           {/* <p onClick={handleOk}> */}
             <SellerLogin />
              {/* </p> */}
            {/* </a> */}
            </p>
        <p>or another email  <a href='/buyer-register'> Register</a></p>
      </Modal>
    </>
  );
};
export default SellerModal;