import { s3config, ReactS3Client } from "./keys";
//Optional Import
import { uploadFile } from "react-s3";

import S3 from "aws-s3";

const S3Client = new S3(s3config);

const baseUrl = "https://matjars.s3.us-east-1.amazonaws.com/";

const generateRandom = () => Math.floor(Math.random() * 1000000) + 1;

export const uploadimage = (file) => {
  return new Promise(async (resolve, reject) => {
    const newFileName = file.name.split(".");
    const filePrefix = newFileName[0] + generateRandom();
    newFileName[0] = filePrefix;
    const finalName = newFileName.join(".");
    console.log(finalName);
    try {
      const response = await ReactS3Client.uploadFile(file, finalName);
      console.log("====================================");
      console.log(response);
      console.log("====================================");
      return resolve(response);
    } catch (err) {
      console.log("====================================");
      console.log(err);
      console.log("====================================");
      return reject(err);
    }
  });
};
