import React, { useContext, useEffect, useState } from "react";
import appContext from "../../context/AppContext";
import {
    TrendingProduct,
    FeaturedProducts,
    SaleProducts,
    PopularProducts,
    ClearanceProducts,
    Brands,
    Category,
    IsPopularCategories,
    HotDealsProducts,
  } from "../TopProductStatus";

const ProductStatusWithOrderWise = () => {
  const { settingData } = useContext(appContext);
  const [dataOrderWise, setDataOrderWise] = useState([]);
  console.log("%c Setting Data", "background: purple; color: white", settingData.homeCaro)
  // ---------- UseEffect ----------
 
  
  useEffect(() => {
    orderFunction()
  }, [settingData?.homeCaro])
  const orderFunction = () => {
    if (settingData && settingData?.homeCaro) {
      const dataArray = Object.entries(settingData?.homeCaro);
      const sortedArray = dataArray.sort((a, b) => a[1].order - b[1].order);
      setDataOrderWise(sortedArray.map(([key, value]) => ({
        name: key,
        component: componentsMap[key],
        order: value.order,
        limit: value.limit,
        isVisible: value.isVisible ? value.isVisible : false
        // isVisible: value.isVisible ? value.isVisible : true
      })
    // }
      )
      );
    }
  }

  console.log( "orderWise", dataOrderWise)
 const componentsMap = {
    isPopularCategories: <IsPopularCategories />,
    isHotDealsProducts: <HotDealsProducts />,
    isFeaturedProducts: <FeaturedProducts />,
    isSaleProducts: <SaleProducts />,
    isPopularProducts: <PopularProducts />,
    isTrendingProducts: <TrendingProduct />,
    isClearanceProducts: <ClearanceProducts />,
    isBrands: <Brands />,
    isCategory: <Category />,
  };
  return (
    <div>
       {dataOrderWise && dataOrderWise.map(({ component, name, isVisible }, index) => {
       console.log("%c Com", "background: grey; color: black", component, name, isVisible)
       return isVisible && (
<div key={name + index}>
            {component}
          </div>
        )
       }
          
       )}
    </div>
  )
}

export default ProductStatusWithOrderWise;
