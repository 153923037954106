import React from "react";
import { Link } from "react-router-dom";
import { StarIcon, DotsHorizontalIcon } from "@heroicons/react/solid";
import SubmitButton from "../../buttons/SubmitButton";
import { deleteIcon } from "../../../assets/index";
import { RemoveWishList } from "../../../config/Apis/WishlistApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function HistoryReviewedCard({ data }) {
  const [expand, setExpand] = React.useState(false);

  return (
    <div
      key={data._id}
      className="py-10 border-b border-gray-200 flex space-x-6 md:flex-col"
    >
      <Link
        to={`/ProductDetail/${data?.productId?._id}`}
        state={data.productId}
        className="max-w-[110px] max-h-[110px]"
      >
        <img
          src={data?.productId?.media[0]?.url ?? ""}
          alt={"Product"}
          className="flex-none sm:ml-6 object-center  bg-gray-100 rounded-lg sm:w-30 sm:h-30"
        />
      </Link>

      <div className="flex-auto flex flex-col py-2">
        <div>
          <h4 className="font-medium text-gray-900">{`${data?.productId?.title}`}</h4>
          <p className="mt-2 text-sm text-gray-600">
            {data?.productId?.shortDescription}
          </p>
          <p className="mt-2 text-sm text-gray-600">{data?.productId?.price}</p>
        </div>
        <div className="mt-1 flex items-center">
          {[0, 1, 2, 3, 4].map((rating) => (
            <StarIcon
              key={rating}
              className={classNames(
                parseInt(data?.ratingStar) > rating
                  ? "text-yellow-400"
                  : "text-gray-300",
                "h-5 w-5 flex-shrink-0"
              )}
              aria-hidden="true"
            />
          ))}
        </div>
        <div className="mt-4 w-[80%] space-y-6 text-base italic text-gray-600">
          {data.ratingSummery.length > 180 ? (
            <>
              {!expand ? (
                <>
                  {data.ratingSummery.substring(0, 179)}{" "}
                  {!expand && (
                    <DotsHorizontalIcon
                      onClick={() => setExpand(true)}
                      className="h-5 w-7 cursor-pointer inline-block border-[1px] border-gray-700 border-solid rounded-md"
                    />
                  )}
                </>
              ) : (
                data.ratingSummery
              )}
            </>
          ) : (
            data.ratingSummery
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryReviewedCard;
