import { useState } from "react";
import { Switch } from "@headlessui/react";

export default function Example({ isRtl,onToggle }) {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="py-2 ">
      <Switch
        checked={enabled}
        onChange={()=>
        {
          onToggle(!enabled)
          setEnabled(!enabled)
        }
        }
        className={`${enabled ? "bg-[#FF9900]" : "bg-[#888]"}
          relative inline-flex flex-shrink-0 h-[18px] w-[39px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        {!isRtl && (
          <span
            aria-hidden="true"
            className={`${enabled ? "translate-x-5" : "translate-x-0"}
        pointer-events-none inline-block h-[14px] w-[14px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        )}

        {isRtl && (
          <span
            aria-hidden="true"
            className={`${enabled ? "-translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[14px] w-[14px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        )}
      </Switch>
    </div>
  );
}
