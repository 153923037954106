import React from 'react'

const SaleProducts = () => {
  return (
    <div>
      <h1> Sale Products</h1>
      
    </div>
  )
}

export default SaleProducts
