import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SignupModal from "./SignUp";
import {
  Signin,
  Signup,
  confirmCodeReq,
  resendCodeReq,
  sendCode,
  newPassword,
} from "../../config/Auth/Auth";
import { loginRequest, signUpRequest } from "../../config/Apis/AuthApis";
import { getCart } from "../../config/Apis/CartApis";
import appContext from "../../context/AppContext";
import LoaderWhite from "../general/Loader";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
export default function MyModal({ closeModal, isOpen, onClose, onSubmit }) {
  const [userData, setuserData] = React.useState({
    Email: "",
    Password: "",
  });
  const { updateCartCount, setLoggedIn, isRtl } = React.useContext(appContext);
  const [error, setError] = useState("");
  const [confirmCode, setConfirmCode] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [confirmedScreen, setConfirmedScreen] = useState(false);
  const [forgetPasswordScreen, setForgetPasswordScreen] = useState(false);
  const [newPasswordScreen, setNewPasswordScreen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const [forgetPassLoader, setForgetPassLoader] = useState(false);
  const [newPasswordLoader, setNewPasswordLoader] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);

  const { t } = useTranslation();
const selleremail = localStorage.getItem("selleremail");
const signInReq = async () => {
  try {
    const res = await Signin(userData.Email, userData.Password);
    let token = res.accessToken.jwtToken;

    const loginRes = await loginRequest(token);
    setLoader(false);
    setConfirmLoader(false);
    console.log("%c res", "background: green; color: white", res, token);

     console.log("%c Login Res", "background: green; color: white", loginRes);
    if (selleremail || !localStorage.getItem("matjar_user_data")) {
      setLoggedIn(true);
      localStorage.setItem(
        "matjar_user_data",
        JSON.stringify({
          ...loginRes.data,
          Email: userData.Email,
          token: token,
        })
      );
      Cookies.set("buyerEmail", JSON.stringify(loginRes.data.email));
      Cookies.set("buyerToken", JSON.stringify(token));
      Cookies.set("buyerModalStatus", false);
      // window.location.reload();
    }

    setuserData({ Email: "", Password: "" });
    setError("");
    onClose();
    onSubmit();
    window.location.reload();
    console.log("Success");
  } catch (err) {
    setLoader(false);
    setConfirmLoader(false);
    console.error(err.message);

    if (err.code === "UserNotConfirmedException") {
      const codeRes = await resendCodeReq(userData.Email);
      console.log(codeRes);
      setIsSignIn(false);
      setConfirmCode(true);
    } else if (err.code === "UserNotFoundException") {
      setError("Your account is not registered.");
    } else if (err.code === "NotAuthorizedException") {
      setError("Incorrect email or password.");
    } else {
      setError(err.message);
    }
  }
};

const login = async (e) => {
  setLoader(true);
  e.preventDefault();
  await signInReq();
};

const Register = async (e) => {
  setLoader(true);
  e.preventDefault();
  try {
    const res = await Signup(userData.Email, userData.Password);
    setLoader(false);
    setError("");
    setIsSignUp(false);
    setConfirmCode(true);
    console.log(res);
  } catch (err) {
    setLoader(false);
    if (err.code === "InvalidPasswordException") {
      setError("Password must contain 1 uppercase letter, 1 number and 1 special character.");
    } else {
      setError(err.message);
    }
  }
};

const onConfirmCode = async () => {
  if (code) {
    try {
      setConfirmLoader(true);
      const res = await confirmCodeReq(code, userData.Email);
      const loginRes = await Signin(userData.Email, userData.Password);
      await signUpRequest(
        {
          email: userData.Email,
          cognitoId: loginRes.accessToken.payload.username,
        },
        loginRes.accessToken.jwtToken
      );
      await signInReq();
      console.log(res);
      setCodeError("");
    } catch (err) {
      console.error(err);
    } finally {
      // setConfirmLoader(false);
    }
  } else {
    setCodeError("Please enter a code.");
  }
};

const onSendCode = async (e) => {
  e.preventDefault();
  try {
    setForgetPassLoader(true);
    const res = await sendCode(userData.Email);
    setForgetPasswordScreen(false);
    setNewPasswordScreen(true);
    console.log(res);
  } catch (err) {
    console.error(err);
  } finally {
    setForgetPassLoader(false);
  }
};

const onNewPassword = async (e) => {
  e.preventDefault();
  try {
    setNewPasswordLoader(true);
    const res = await newPassword(userData.Email, code, userData.Password);
    setuserData({ Email: "", Password: "" });
    setCode("");
    setNewPasswordScreen(false);
    setIsSignIn(true);
    console.log(res);
    setError("");
  } catch (err) {
    console.log(err.code);
    if (err.code === "InvalidPasswordException") {
      setError("Password must contain 1 uppercase letter, 1 number, and 1 special character.");
    } else {
      setError(err.message);
    }
  } finally {
    setNewPasswordLoader(false);
  }
};

  const ErrorAlert = () => {
    return (
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded relative my-2"
        role="alert"
      >
        <strong class="font-bold text-xs">Error!</strong>
        <span class="block sm:inline text-xs"> {error} </span>
        <span
          class="absolute top-0 bottom-0 right-0 px-4 py-2"
          onClick={() => setError("")}
        >
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  };

  const CodeErrorAlert = () => {
    return (
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded relative my-2"
        role="alert"
      >
        <strong class="font-bold text-xs">Error!</strong>
        <span class="block sm:inline text-xs"> {codeError} </span>
        <span
          class="absolute top-0 bottom-0 right-0 px-4 py-2"
          onClick={() => setCodeError("")}
        >
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  };

  const SuccessAlert = () => {
    return (
      <div
        class="bg-[#ECFDF5] border border-[#15AF84] text-[#15AF84] px-3 py-2 rounded relative my-2"
        role="alert"
      >
        <strong class="font-bold text-xs">Success!</strong>
        <span class="block sm:inline text-xs">
          Your account has been successfully created, proceed to log into your
          account.
        </span>
      </div>
    );
  };

  const ConfirmedScreen = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          {SuccessAlert()}

          <div className="mt-6">
            <button
              onClick={() => {
                setConfirmedScreen(false);
                setIsSignIn(true);
              }}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ConfirmCodeScreen = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          <p className="text-[13px]">
            Please check your email and enter a code that we've sent you to
            verify your account.
          </p>

          <div className="pt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Code
            </label>
            <div className="mt-1">
              <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                id="code"
                name="code"
                type="text"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          {codeError && <CodeErrorAlert />}
          <div className="mt-6">
            <button
              onClick={onConfirmCode}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {confirmLoader ? <LoaderWhite /> : "Proceed"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const SignInScreen = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              {t("welcome")}
            </h2>
            {/* <h2 className="mt-3 text-center text-xl  text-gray-900"> Sign in for faster checkout.</h2> */}
            <p className="mt-2 text-center text-sm text-gray-600">
              {t("signin")} {t("or")}{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 outline-none hover:text-indigo-500"
                onClick={() => {
                  setIsSignIn(false);
                  setIsSignUp(true);
                  setuserData({ Email: "", Password: "" });
                }}
              >
                {t("createAccount")}
              </a>
            </p>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-3 px-5">
            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Google</span>

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4844 11.2492C21.4844 17.3293 17.3207 21.6562 11.1719 21.6562C5.27656 21.6562 0.515625 16.8953 0.515625 11C0.515625 5.10469 5.27656 0.34375 11.1719 0.34375C14.0422 0.34375 16.457 1.39648 18.3176 3.13242L15.4172 5.92109C11.623 2.26016 4.56758 5.01016 4.56758 11C4.56758 14.7168 7.53672 17.7289 11.1719 17.7289C15.3914 17.7289 16.9727 14.7039 17.2219 13.1355H11.1719V9.47031H21.3168C21.4156 10.016 21.4844 10.5402 21.4844 11.2492Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Facebook</span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 12 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1191 12.375L11.7302 8.39352H7.90981V5.8098C7.90981 4.72055 8.44348 3.65879 10.1545 3.65879H11.8913V0.268984C11.8913 0.268984 10.3152 0 8.80828 0C5.66211 0 3.60563 1.90695 3.60563 5.35906V8.39352H0.108398V12.375H3.60563V22H7.90981V12.375H11.1191Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div>

            {/* <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Linkedin</span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.68391 19.2501H0.692969V6.3981H4.68391V19.2501ZM2.68629 4.64498C1.41012 4.64498 0.375 3.58795 0.375 2.31178C0.375 1.69879 0.61851 1.1109 1.05196 0.677449C1.48541 0.243998 2.0733 0.000488281 2.68629 0.000488281C3.29928 0.000488281 3.88717 0.243998 4.32062 0.677449C4.75407 1.1109 4.99758 1.69879 4.99758 2.31178C4.99758 3.58795 3.96203 4.64498 2.68629 4.64498ZM19.6207 19.2501H15.6384V12.9938C15.6384 11.5028 15.6083 9.59068 13.5634 9.59068C11.4884 9.59068 11.1705 11.2106 11.1705 12.8864V19.2501H7.18383V6.3981H11.0115V8.15123H11.0673C11.6002 7.14146 12.9017 6.07584 14.8434 6.07584C18.8825 6.07584 19.625 8.7356 19.625 12.1903V19.2501H19.6207Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div> */}
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t("continueWith")}
                </span>
              </div>
            </div>
          </div>

          {error && ErrorAlert()}
          <form onSubmit={login}>
            <div className="pt-5">
              <label
                htmlFor="email"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("emailAddress")}
              </label>
              <div className="mt-1">
                <input
                  value={userData.Email}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      Email: e.target.value,
                    })
                  }
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="py-5">
              <label
                htmlFor="password"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("password")}
              </label>
              <div className="mt-1">
                <input
                  value={userData.Password}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      Password: e.target.value,
                    })
                  }
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div> */}
              <div />
              <div className="text-sm">
                <p
                  onClick={() => {
                    setIsSignIn(false);
                    setForgetPasswordScreen(true);
                  }}
                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                >
                  {t("forgotPass")}
                </p>
              </div>
            </div>

            <div className="mt-6">
              <button
                disabled={loader}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loader ? <LoaderWhite /> : t("signin")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const SignUpScreen = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              {t("register")}
            </h2>
            {/* <h2 className="mt-3 text-center text-xl  text-gray-900"> Sign in for faster checkout.</h2> */}
            <p className="mt-2 text-center text-sm text-gray-600">
              {t("alreadAcc")}{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 outline-none hover:text-indigo-500"
                onClick={() => {
                  setIsSignIn(true);
                  setIsSignUp(false);
                  setuserData({ Email: "", Password: "" });
                }}
              >
                {t("login")}
              </a>
            </p>
          </div>
          <div className="mt-6 grid grid-cols-2 gap-3 px-5">
            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Google</span>

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4844 11.2492C21.4844 17.3293 17.3207 21.6562 11.1719 21.6562C5.27656 21.6562 0.515625 16.8953 0.515625 11C0.515625 5.10469 5.27656 0.34375 11.1719 0.34375C14.0422 0.34375 16.457 1.39648 18.3176 3.13242L15.4172 5.92109C11.623 2.26016 4.56758 5.01016 4.56758 11C4.56758 14.7168 7.53672 17.7289 11.1719 17.7289C15.3914 17.7289 16.9727 14.7039 17.2219 13.1355H11.1719V9.47031H21.3168C21.4156 10.016 21.4844 10.5402 21.4844 11.2492Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Facebook</span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 12 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1191 12.375L11.7302 8.39352H7.90981V5.8098C7.90981 4.72055 8.44348 3.65879 10.1545 3.65879H11.8913V0.268984C11.8913 0.268984 10.3152 0 8.80828 0C5.66211 0 3.60563 1.90695 3.60563 5.35906V8.39352H0.108398V12.375H3.60563V22H7.90981V12.375H11.1191Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div>
            {/* 
            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Linkedin</span>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.68391 19.2501H0.692969V6.3981H4.68391V19.2501ZM2.68629 4.64498C1.41012 4.64498 0.375 3.58795 0.375 2.31178C0.375 1.69879 0.61851 1.1109 1.05196 0.677449C1.48541 0.243998 2.0733 0.000488281 2.68629 0.000488281C3.29928 0.000488281 3.88717 0.243998 4.32062 0.677449C4.75407 1.1109 4.99758 1.69879 4.99758 2.31178C4.99758 3.58795 3.96203 4.64498 2.68629 4.64498ZM19.6207 19.2501H15.6384V12.9938C15.6384 11.5028 15.6083 9.59068 13.5634 9.59068C11.4884 9.59068 11.1705 11.2106 11.1705 12.8864V19.2501H7.18383V6.3981H11.0115V8.15123H11.0673C11.6002 7.14146 12.9017 6.07584 14.8434 6.07584C18.8825 6.07584 19.625 8.7356 19.625 12.1903V19.2501H19.6207Z"
                    fill="#888888"
                  />
                </svg>
              </a>
            </div> */}
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t("continueWith")}
                </span>
              </div>
            </div>
          </div>
          {error && ErrorAlert()}
          <form onSubmit={Register}>
            <div className="pt-5">
              <label
                htmlFor="email"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("emailAddress")}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={userData.Email}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      Email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="h-[14px]" />
              <label
                htmlFor="password"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("password")}
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={userData.Password}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      Password: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="my-6">
              <button
                disabled={loader}
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loader ? <LoaderWhite /> : t("continue")}
              </button>
            </div>
          </form>

          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              {t("bySigning")}{" "}
              <Link
                to={"/page/terms-and-conditions"}
                className="underline"
                onClick={closeModal}
              >
                {t("terms")}
              </Link>{" "}
              &{" "}
              <Link
                to={"/page/privacy-policy"}
                className="underline"
                onClick={closeModal}
              >
                {t("policy")}
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ForgetPasswordEmail = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          <p className={`text-[13px] ${isRtl ? "text-right" : "text-left"}`}>
            {t("enterEmail")}
          </p>
          <form onSubmit={onSendCode}>
            <div className="pt-5">
              <label
                htmlFor="email"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("email")}
              </label>
              <div className="mt-1">
                <input
                  value={userData.Email}
                  onChange={(e) =>
                    setuserData({ ...userData, Email: e.target.value })
                  }
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-6">
              <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {forgetPassLoader ? <LoaderWhite /> : t("proceed")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const NewPasswordScreen = () => {
    return (
      <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
        <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-4">
          <p className="text-[13px]">{t("setNewPass")}</p>
          {error && <ErrorAlert />}
          <form onSubmit={onNewPassword}>
            <div className="pt-5">
              <label
                htmlFor="code"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("verificationCode")}
              </label>
              <div className="mt-1">
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  id="code"
                  name="code"
                  type="text"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="pt-5">
              <label
                htmlFor="password"
                className={`block text-sm font-medium text-gray-700 ${
                  isRtl ? "text-right" : "text-left"
                }`}
              >
                {t("newPass")}
              </label>
              <div className="mt-1">
                <input
                  value={userData.Password}
                  onChange={(e) =>
                    setuserData({ ...userData, Password: e.target.value })
                  }
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-6">
              <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {newPasswordLoader ? <LoaderWhite /> : t("proceed")}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            closeModal();
            // setConfirmedScreen(false);
            // setIsSignIn(true);
          }}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {confirmedScreen ? (
                ConfirmedScreen()
              ) : isSignIn ? (
                SignInScreen()
              ) : isSignUp ? (
                SignUpScreen()
              ) : confirmCode ? (
                ConfirmCodeScreen()
              ) : forgetPasswordScreen ? (
                ForgetPasswordEmail()
              ) : newPasswordScreen ? (
                NewPasswordScreen()
              ) : (
                <></>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
