import { BASE_URL } from "../keys";
import axios from "axios";

export const addProduct = (productid, quantity, variantid) => {
  console.log(productid, quantity, variantid);
  // console.log(TOKEN);
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));
  // console.log(data.token)
  return new Promise(async (resolve, reject) => {
    try {
      const getConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      };
      const body = JSON.stringify({
        productId: productid,
        quantity: quantity,
        variationId: variantid,
      });

      const response = await axios.post(`${BASE_URL}cart`, body, getConfig);
     console.log("success", response.data);
     console.log("%c Response from update cart Api: ", "background: purple; color: white", response)

      const res = await getCart();
      console.log("%c Response from getCart Api: ", "background: purple; color: white", res)
      return resolve(res);
    } catch (err) {
      console.log(err.response);
      return reject(err);
    }
  });
};

export const deleteProduct = (productIds) => {
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));
  // console.log(data.token);
  return new Promise(async (resolve, reject) => {
    try {
      const body = JSON.stringify({
        productIds: [productIds],
      });

      const response = await axios.delete(`${BASE_URL}cart`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
        data: {
          productIds: Array.isArray(productIds) ? productIds : [productIds],
        },
      });
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getCart = (token) => {
  // console.log(TOKEN);
  let data = JSON.parse(localStorage.getItem("matjar_user_data"));

  return new Promise(async (resolve, reject) => {
    try {
      const getConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token || token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}cart?userId=${data._id}`,
        getConfig
      );

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
