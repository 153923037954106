import { BASE_URL } from "../keys";
import axios from "axios";

export const loginRequest = (token) => {
  // console.log(TOKEN);
  const getConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      console.log("auth Token", token);
      const response = await axios.post(`${BASE_URL}auth/login`, {}, getConfig);
      console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const signUpRequest = (body, token) => {
  const getConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      console.log(body, token);
      const response = await axios.post(
        `${BASE_URL}auth/signup`,
        body,
        getConfig
      );
      console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
