import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addNewAddress, getAllAddresses } from "../../config/Apis/AddressApis";
import { toTitleCase } from "../../Helper/helperFunctions";
import DetailButton from "../buttons/CardButton";
import SubmitButton from "../buttons/SubmitButton";
import StateDropDown from "../dropdown/StateDropDown";
import MainInput from "../general/MainInput";
import ConfirmModal from "../modals/ConfirmModal";

const AddressSection = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    state: "",
    city: "",
    email: "",
    cordinate: [],
    postal: "",
    phone1: "",
    address1: "",
    label: "home",
    type: "primary",
    userType: "register",
  });
  const [allAddresses, setAllAddresses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [maxAddress, setmaxAddress] = useState(false);
  const [label, setLabel] = useState('');

  const labelData = [
    { name: "home" },
    { name: "office" },
    { name: "other label" },
  ];
  const typeData = [{ name: "primary" }, { name: "other type" }];

  useEffect(() => {
    getAllAddressesData();
    const { location, coordinates } = props;
    setData({
      ...data,
      country: location.country || "United Arab Emirates",
      state: location.state || data.state,
      city: location.city || data.city,
      address1: location.completeAddress || data.address1,
      cordinate: [coordinates.lat, coordinates.lng],
    });
  }, [props]);

  const getAllAddressesData = async () => {
    try {
      const response = await getAllAddresses();
      setAllAddresses(response.data.list);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const onAddressSubmit = async () => {
    setLoader(true);
    try {
      setModalLoader(true);
      console.log("%c data from address section  ","background: purple; color: white",data);
      await addNewAddress(data);
      if (props.from == "checkout") {
        navigate(-1);
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setModalLoader(false);
      setShowModal(false);
      setLoader(false);
    }
  };

  const checkPrimary = (e) => {
    e.preventDefault();

    console.log(allAddresses);

    if (allAddresses.length == 10)
      return setmaxAddress(true)

    if (data.type === 'other type' && data.label === 'other label')
      return onAddressSubmit()

    if (data.type === 'primary') {
      const isPrimary = allAddresses.find((item) => item.type === data.type);
      if (isPrimary) { setShowModal(true); return setLabel('Primary'); }
    }

    const isFound = allAddresses.filter((item) => item.type === data.type && item.label === data.label);
    if (isFound.length != 0) { setShowModal(true); return setLabel(toTitleCase(isFound[0]?.label)); }

    onAddressSubmit();
    console.log(isFound);

  };

    const filterPhone = (e) => {

      const reg = /^([^a-z$A-z%]*)$/;
      if (reg.test(e.currentTarget.value)) {
        e.target.value = e.target.value.replace(/\s/g, "")
        setData({ ...data, phone1: e.target.value })
      }
  
    }
  
    const filterCode = (e) => {
  
      const reg = /^([^a-z$A-z%]*)$/;
      if (reg.test(e.currentTarget.value)) {
        e.target.value = e.target.value.replace(/\s/g, "")
        setData({ ...data, postal: e.target.value })
      }
    }
    

    // if (data.type == "primary") {
    //   const isFound = allAddresses.find((item) => item.type == "primary");
    //   if (isFound) {
    //     setShowModal(true);
    //   } else {
    //     onAddressSubmit();
    //   }
    // } else {
    //   onAddressSubmit();
    // }
  return (
    <div className="col-span-7 lg:col-span-12 lg:mx-4 my-2">
      <div className="bg-white p-5 rounded-md">
        <h2 className="text-2xl font-medium my-2">{t("address")}</h2>
        <div className="border-b-[1px] border-[#f8f1f1] w-full mx-auto"></div>
        <form onSubmit={checkPrimary}>
          <div className="mt-5 flex md:flex-col">
            <MainInput
              id="country"
              value={data.country}
              disabled={true}
              // onChange={(e) => setData({ ...data, country: e.target.value })}
              inputLabel={t("country")}
            />
            {/* <div>
              <StateDropDown />
            </div> */}
            <MainInput
              id="state"
              value={data.state}
              onChange={(e) => setData({ ...data, state: e.target.value })}
              inputLabel={t("state")}
            />
          </div>

          <div className="mt-5 flex md:flex-col">
            <MainInput
              id="city"
              value={data.city}
              onChange={(e) => setData({ ...data, city: e.target.value })}
              inputLabel={t("city")}
            />
            <MainInput
              id="email"
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              inputLabel={t("email")}
            />
          </div>
          <div className="mt-5 flex md:flex-col">
            <MainInput
              id="firstName"
              value={data.firstName}
              onChange={(e) => setData({ ...data, firstName: e.target.value })}
              inputLabel={t("firstName")}
            />
            <MainInput
              id="lastName"
              value={data.lastName}
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
              inputLabel={t("lastName")}
            />
          </div>
          <div className="mt-5 flex md:flex-col">
          <MainInput
              type="text"
              id="postalCode"
              value={data.postal}
              onChange={filterCode}
              inputLabel={t("postalCode")}
            />
            <MainInput
              id="PhoneNum"
              type="text"
              value={data.phone1}
              onChange={filterPhone}
              inputLabel={t("PhoneNum")}
            />
          </div>
          <MainInput
            id="address"
            value={data.address1}
            onChange={(e) => setData({ ...data, address1: e.target.value })}
            inputLabel={t("address")}
          />
          <div className="mt-5">
            <div className="text-sm text-gray-700 ml-2 "> {t("label")} </div>
            <div className="flex">
              {labelData.map((item, ind) => (
                <div key={ind} className="mt-1 flex items-center ml-2">
                  <input
                    value={item.name}
                    onChange={(e) =>
                      setData({ ...data, label: e.target.value })
                    }
                    name={item.name}
                    type="radio"
                    checked={item.name == data.label}
                    className="h-4 w-4 ml-1 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor={toTitleCase(item.name)}
                    className="block text-sm ml-1 text-gray-700"
                  >
                    {toTitleCase(item.name)}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-5 ">
            <div className="text-sm text-gray-700 ml-2 "> {t("type")} </div>

            <div className="flex">
              {typeData.map((item, ind) => (
                <div key={ind} className="mt-1 flex items-center ml-2">
                  <input
                    value={item.name}
                    onChange={(e) => setData({ ...data, type: e.target.value })}
                    name={item.name}
                    type="radio"
                    checked={item.name == data.type}
                    className="h-4 w-4 ml-1 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor={toTitleCase(item.name)}
                    className="block text-sm ml-1 text-gray-700"
                  >
                    {toTitleCase(item.name)}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4 mx-auto w-[50%] md:w-full">
            <SubmitButton
              label={t("continue")}
              color={"#2c73d2"}
              loader={loader}
            />


            <ConfirmModal
              loader={modalLoader}
              closeModal={() => setShowModal(false)}
              isOpen={maxAddress}
              closebtn={true}
              btnlabel="Ok"
              label={
                "You already reach the maximum number of adding addresses!"
              }
              onConfirm={() => setmaxAddress(false)}
            />


            <ConfirmModal
              loader={modalLoader}
              closeModal={() => setShowModal(false)}
              isOpen={showModal}
              label={
                "You already have a " + label + " address, do you want to override it?"
              }
              onConfirm={onAddressSubmit}
            />


          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressSection;
