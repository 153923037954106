import React, { useEffect } from "react";
import { useState } from "react";
import { createSearchParams, useLocation } from "react-router-dom";

function useFilters({ setOpen, sellerId, navigate, category }) {
  const location = useLocation();
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [finalVariants, setFinalVariants] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  useEffect(() => {
    setSelectedVariants([]);
    setSelectedBrand([]);
    setFinalVariants("");
  }, [category]);

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const handleCheckChange = (e, variant) => {
    // console.log(e.target.value, variant);
    const isChecked = e.target.checked;
    const entry = [variant, e.target.value];
    console.log(minPrice);
    if (isChecked) {
      selectedVariants.push(entry.toString());
      setSelectedVariants(selectedVariants);
      let newArr = selectedVariants.map((item) => item.split(","));
      let arrToObj = newArr.map((item) => {
        return { key: item[0], item: item[1] };
      });
      let groupedObj = groupBy(arrToObj, "key");
      let groupedToArr = Object.entries(groupedObj).map((item) => {
        return {
          key: `${item[0]}|`,
          items: item[1].map((innerItem) => innerItem.item),
        };
      });
      let joinChildArr = groupedToArr.map((item) => {
        return `${item.key}${item.items.toString()}`;
      });
      let finalJoin = joinChildArr.join("|");
      // console.log(finalJoin);
      setFinalVariants(finalJoin);
      const params = {
        price1: minPrice,
        price2: maxPrice,
        variantion: finalJoin,
        brandId: selectedBrand,
        sellerId,
      };
      setOpen(false);
      navigate({
        pathname: `/seeall/${category}`,
        search: `?${createSearchParams(params)}`,
      });

      window.scrollTo(0, 100);
    } else {
      const index = selectedVariants.indexOf(entry.toString());
      selectedVariants.splice(index, 1);
      setSelectedVariants(selectedVariants);
      let newArr = selectedVariants.map((item) => item.split(","));
      let arrToObj = newArr.map((item) => {
        return { key: item[0], item: item[1] };
      });
      let groupedObj = groupBy(arrToObj, "key");
      let groupedToArr = Object.entries(groupedObj).map((item) => {
        return {
          key: `${item[0]}|`,
          items: item[1].map((innerItem) => innerItem.item),
        };
      });
      let joinChildArr = groupedToArr.map((item) => {
        return `${item.key}${item.items.toString()}`;
      });
      let finalJoin = joinChildArr.join("|");
      setFinalVariants(finalJoin);
      setOpen(false);

      const params = {
        price1: minPrice,
        price2: maxPrice,
        variantion: finalJoin,
        brandId: selectedBrand,
        sellerId,
      };

      navigate({
        pathname: `/seeall/${category}`,
        search: `?${createSearchParams(params)}`,
      });

      window.scrollTo(0, 100);
    }
  };

  const handleCheckChangeTop = (e, variant) => {
    const entry = [variant, e.target.value].toString();

    if (selectedVariants.some((item) => item.includes(variant))) {
      const foundVariant = selectedVariants.find((item) =>
        item.includes(variant)
      );
      const presentVariantIndex = selectedVariants.indexOf(foundVariant);
      selectedVariants[presentVariantIndex] = entry;
    } else {
      selectedVariants.push(entry);
    }

    setSelectedVariants(selectedVariants);
    let newArr = selectedVariants.map((item) => item.split(","));
    let arrToObj = newArr.map((item) => {
      return { key: item[0], item: item[1] };
    });
    let groupedObj = groupBy(arrToObj, "key");
    let groupedToArr = Object.entries(groupedObj).map((item) => {
      return {
        key: `${item[0]}|`,
        items: item[1].map((innerItem) => innerItem.item),
      };
    });
    let joinChildArr = groupedToArr.map((item) => {
      return `${item.key}${item.items.toString()}`;
    });
    let finalJoin = joinChildArr.join("|");
    setFinalVariants(finalJoin);
    const params = {
      price1: minPrice,
      price2: maxPrice,
      variantion: finalJoin,
      brandId: selectedBrand,
      sellerId,
    };
    navigate({
      pathname: `/seeall/${category}`,
      search: `?${createSearchParams(params)}`,
    });

    window.scrollTo(0, 100);
  };
  const handleWarrantyTop=(key,value,state)=>
  {
    let finalJoin='';
    if(state)
    {
      const entry = [key, value.toString()].toString();
      if (selectedVariants.some((item) => item.includes(key))) {
        const foundVariant = selectedVariants.find((item) =>
          item.includes(key)
        );
        const presentVariantIndex = selectedVariants.indexOf(foundVariant);
        selectedVariants[presentVariantIndex] = entry;
      } else {
        selectedVariants.push(entry);
      }
  
      setSelectedVariants(selectedVariants);
      let arrToObj = [{ key: key, item: value.toString()}]
      let groupedObj = groupBy(arrToObj, "key");
      let groupedToArr = Object.entries(groupedObj).map((item) => {
        return {
          key: `${item[0]}|`,
          items: item[1].map((innerItem) => innerItem.item),
        };
      });
      let joinChildArr = groupedToArr.map((item) => {
        return `${item.key}${item.items.toString()}`;
      });
      console.log(joinChildArr)
      finalJoin = joinChildArr.join("|");
    }
    else
    {
      const entry = [key, value.toString()].toString();
      if (selectedVariants.some((item) => item.includes(key))) {
        const foundVariant = selectedVariants.find((item) =>
          item.includes(key)
        );
        const presentVariantIndex = selectedVariants.indexOf(foundVariant);
        selectedVariants[presentVariantIndex] = entry;
      } else {
        selectedVariants.push(entry);
      }
      setSelectedVariants(selectedVariants);
      let arrToObj = [{ key: key, item: value.toString()}]
      let groupedObj = groupBy(arrToObj, "key");
      let groupedToArr = Object.entries(groupedObj).map((item) => {
        return {
          key: `${item[0]}|`,
          items: item[1].map((innerItem) => innerItem.item),
        };
      });
      let joinChildArr = groupedToArr.filter((item) => {
        item.key.includes('warranty')
      });
      let finalJoin = joinChildArr.join("|");
      
    }
    setFinalVariants(finalJoin);
      const params = {
        price1: minPrice,
        price2: maxPrice,
        variantion: finalJoin,
        brandId: selectedBrand,
        sellerId,
      };
      navigate({
        pathname: `/seeall/${category}`,
        search: `?${createSearchParams(params)}`,
      });
  
      window.scrollTo(0, 100);
  }   
  return {
    handleCheckChange,
    handleCheckChangeTop,
    selectedBrand,
    setSelectedBrand,
    selectedVariants,
    setSelectedVariants,
    finalVariants,
    setFinalVariants,
    minPrice,
    setMinPrice,
    maxPrice,
    handleWarrantyTop,
    setMaxPrice,
  };
}

export default useFilters;

// import React, { useEffect } from "react";
// import { useState } from "react";
// import { createSearchParams } from "react-router-dom";

// function useFilters({ setOpen, sellerId, navigate, category }) {
//   const [selectedBrand, setSelectedBrand] = useState("");

//   let url = new URL(window.location.href).searchParams.get("variantion");

//   url = url?.split('|')

//   const p = url ? url[1]?.split(",").map(item => `Color,${item}`) : undefined;

//   // console.log(p)
//   const [selectedVariants, setSelectedVariants] = useState([]);
//   const [finalVariants, setFinalVariants] = useState("");
//   const [minPrice, setMinPrice] = useState("");
//   const [maxPrice, setMaxPrice] = useState("");

//   console.log(selectedVariants)
//   var groupBy = function (xs, key) {
//     return xs.reduce(function (rv, x) {
//       (rv[x[key]] = rv[x[key]] || []).push(x);
//       return rv;
//     }, {});
//   };

//   const handleCheckChange = (e, variant) => {
//     // console.log(e.target.value, variant);
//     const isChecked = e.target.checked;
//     const entry = [variant, e.target.value];

//     if (isChecked) {
//       // console.log("selectedVariants", selectedVariants)
//       selectedVariants.push(entry.toString());
//       setSelectedVariants(selectedVariants);
//       let newArr = selectedVariants.map((item) => item.split(","));
//       // console.log("newArr", newArr)
//       let arrToObj = newArr.map((item) => {
//         return { key: item[0], item: item[1] };
//       });

//       let groupedObj = groupBy(arrToObj, "key");

//       let groupedToArr = Object.entries(groupedObj).map((item) => {
//         return {
//           key: `${item[0]}|`,
//           items: item[1].map((innerItem) => innerItem.item),
//         };
//       });
//       // console.log("groupedToArr",groupedToArr)
//       let joinChildArr = groupedToArr.map((item) => {
//         return `${item.key}${item.items.toString()}`;
//       });
//       let finalJoin = joinChildArr.join("|");
//       setFinalVariants(finalJoin);
//       const params = {
//         price1: minPrice,
//         price2: maxPrice,
//         variantion: finalJoin,
//         brandId: selectedBrand,
//         sellerId,
//       };
//       setOpen(false);
//       navigate({
//         pathname: `/seeall/${category}`,
//         search: `?${createSearchParams(params)}`,
//       });

//       window.scrollTo(0, 100);
//     } else {
//       const index = selectedVariants.indexOf(entry.toString());
//       selectedVariants.splice(index, 1);
//       setSelectedVariants(selectedVariants);
//       let newArr = selectedVariants.map((item) => item.split(","));
//       let arrToObj = newArr.map((item) => {
//         return { key: item[0], item: item[1] };
//       });
//       let groupedObj = groupBy(arrToObj, "key");
//       let groupedToArr = Object.entries(groupedObj).map((item) => {
//         return {
//           key: `${item[0]}|`,
//           items: item[1].map((innerItem) => innerItem.item),
//         };
//       });
//       console.log("groupedToArr",groupedToArr)
//       let joinChildArr = groupedToArr.map((item) => {
//         return `${item.key}${item.items.toString()}`;
//       });

//       let finalJoin = joinChildArr.join("|");
//       setFinalVariants(finalJoin);
//       setOpen(false);

//       const params = {
//         price1: minPrice,
//         price2: maxPrice,
//         variantion: finalJoin,
//         brandId: selectedBrand,
//         sellerId,
//       };

//       navigate({
//         pathname: `/seeall/${category}`,
//         search: `?${createSearchParams(params)}`,
//       });

//       window.scrollTo(0, 100);
//     }
//   };

//   const handleCheckChangeTop = (e, variant) => {
//     // console.log(e.target.value, variant);
//     console.log(e)
//     const entry = [variant, e.target.value];

//     // console.log(entry)
//     const findind = selectedVariants.find(item => item === entry.toString());
//     if (!findind) {
//       selectedVariants[0] = entry.toString();
//     }
//     setSelectedVariants(selectedVariants);
//     let newArr = selectedVariants.map((item) => item.split(","));
//     let arrToObj = newArr.map((item) => {
//       return { key: item[0], item: item[1] };
//     });
//     let groupedObj = groupBy(arrToObj, "key");
//     let groupedToArr = Object.entries(groupedObj).map((item) => {
//       return {
//         key: `${item[0]}|`,
//         items: item[1].map((innerItem) => innerItem.item),
//       };
//     });
//     let joinChildArr = groupedToArr.map((item) => {
//       return `${item.key}${item.items.toString()}`;
//     });
//     let finalJoin = joinChildArr.join("|");
//     setFinalVariants(finalJoin);
//     const params = {
//       price1: minPrice,
//       price2: maxPrice,
//       variantion: finalJoin,
//       brandId: selectedBrand,
//       sellerId,
//     };
//     navigate({
//       pathname: `/seeall/${category}`,
//       search: `?${createSearchParams(params)}`,
//     });

//     window.scrollTo(0, 100);
//   };

//   return {
//     handleCheckChange,
//     handleCheckChangeTop,
//     selectedBrand,
//     setSelectedBrand,
//     selectedVariants,
//     setSelectedVariants,
//     finalVariants,
//     setFinalVariants,
//     minPrice,
//     setMinPrice,
//     maxPrice,
//     setMaxPrice,
//   };
// }

// export default useFilters;
