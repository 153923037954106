import React from "react";
import { ExclamationIcon, HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="w-full h-[500px] flex justify-center items-center"
    >
      <div
        className="w-[450px]  h-[350px] bg-slate-100 
      shadow-lg rounded-2xl flex 
      justify-center items-center 
      flex-col"
      >
        <ExclamationIcon color="#FF9900" className="h-[90px]" />
        <h1 className="text-[20px] font-semibold">
          <span className="text-[#FF9900]">Oops!</span> something went wrong
        </h1>
        <div className="h-[1px] bg-[#d5d5d5] w-[90%] mx-auto my-[30px]" />
        <Link to={"/"}>
          <button className="bg-[#FF9900] px-[20px] py-[8px] text-[14px] text-white rounded-3xl flex items-center">
            <HomeIcon className="h-[15px] mr-[4px]" />
            Go back to home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ErrorFallback;

{
  /* <div role="alert">
<p>Something went wrong:</p>
<pre>{error.message}</pre>
<button onClick={resetErrorBoundary}>Try again</button>
</div> */
}
