import { BASE_URL, TOKEN } from "../keys";
import axios from "axios";

const getConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
};

// all-clean

export const getAllProducts = (params) => {
  // console.log(TOKEN);
  // console.log(params);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}product`, {
        params,
      });
      console.log("%c Get All Products", "background: black; color: white", response)
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getFilters = (categoryId) => {
  // console.log(TOKEN);
  // console.log(params);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}product/attribute/${categoryId}`,getConfig)
      console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const getAllProductsClean = (page = 1, limit = 100) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}product/all-clean`, {
        params: { limit: limit, page: page },
      });
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getOneProduct = (id) => {
  // console.log(TOKEN);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}product/${id}`, getConfig);

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSeller = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}seller/${id}`, getConfig);

      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const searchProducts = (query, categoryId = "", limit) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${BASE_URL}product/all-clean?q=${query}&categoryId=${categoryId}`,
        { params: { limit } }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const postAuction = (body) => {
  console.log(body);
  return new Promise(async (resolve, reject) => {
    try {
      // const body = JSON.stringify(data);
      const response = await axios.post(
        `${BASE_URL}product/auction`,
        body,
        getConfig
      );
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      console.log(err.response.data);
      return reject(err.response.data);
    }
  });
};
