import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import ReactStars from "react-rating-stars-component";
import { addProduct } from "../../config/Apis/CartApis";
import ImagePlaceHolder from "../../assets/imgplace.png";
import {
  addWishlist,
  getWishlist,
  RemoveWishList,
} from "../../config/Apis/WishlistApi";

import {
  whishlist,
  HotDealImage,
  AunctionIcon,
  WishlistSelected,
  EyeIcon,
  CartUpIcon,
  ShareIcon,
} from "../../assets/index";
import ShareModal from "../modals/ShareModal";
import DarkLoaderSm from "../general/DarkLoaderSm";
import DropDown from "../sidebar/DropDown";
import QuantityDropDown from "../dropdown/QuantityDropDown";
import appContext from "../../context/AppContext";
import {
  averageFunc,
  getProductQuantity,
  helperFunctions,
} from "../../Helper/helperFunctions";
import { getReviews } from "../../config/Apis/ReviewsApi";
import { useTranslation } from "react-i18next";
import BidModal from "../modals/BidModal";
import { StarIcon } from "@heroicons/react/solid";
import Dropdown2 from "../sidebar/DropDown2";
import Variant from "../sidebar/Variant";
import { SES } from "aws-sdk";
import useChangeVariant from "../../hooks/useChangeVariant";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProductDetailVariable({ data, reviewsData }) {
  // console.log(
  //   data.variants.filter((item) => item._id == "628f865a85d79293c4b10eaf")
  // );
  var specificData = {};
  const [filter, setFilter] = useState();
  const [currentVariant, setCurrentVariant] = useState(0);
  const [currentVariantId, setCurrentVariantId] = useState(
    data.variants[0]._id
  );
  const navigate = useNavigate();
  const { firstValue, dropdownData, getVariant } = useChangeVariant(data);

  let variantImages;
  console.log("%c Variants Image", "background: purple; color: white", data)

  variantImages = data.variants[currentVariant].media;
  const [currentImage, setCurrentImage] = useState("");
  const [currentImage1, setCurrentImage1] = useState(0);
  const [selectedvariant, setSelectedVariant] = useState({});
  const [addToCardLoader, setAddToCardLoader] = useState(false);
  const [buyNowLoader, setBuyNowLoader] = useState(false);
  const [loader, setloader] = useState(true);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(
    data.cart ? (data?.cart.length ? data.cart[0].quantity : 1) : 1
  );
  const [isfavorite, setIsfavorite] = useState(data.isWished);
  const {
    updateCartCount,
    setIsOpen,
    loggedIn,
    SetWishlist,
    wishlistItems,
    isRtl,
  } = React.useContext(appContext);

  const { t } = useTranslation();
  React.useEffect(() => {
    // console.log(data.variants[currentVariant]._id);
    formatVariants();
    setCurrentVariantId(data.variants[currentVariant]._id);
    setCurrentImage(variantImages[0]?.url || "");
    if (wishlistItems?.length > 0) {
      wishlistItems.map((item) => {
        // console.log(data._id);
        if (item._id == data._id) {
          setIsfavorite(true);
        }
      });
    }

    if (data.variants.length > 0) {
      setSelectedVariant({
        id: data?.variants[currentVariant]?._id,
        name: data?.variants[currentVariant]?.sku,
        size: data?.variants[currentVariant]?.data[0]?.value ?? "",
        color:
          data?.variants[currentVariant]?.data[currentVariant]?.value ?? "",
        quantity: data?.variants[currentVariant]?.quantity,
      });
      setloader(false);
    }
  }, [currentVariant]);

  const shortDescription = data?.shortDescription;

  const [showMore, setshowMore] = useState('');

  const formatVariants = () => {
    data.variants.map((item, index) => {
      {
        item.data.map((totalFilters, ind) => {
          if (specificData[totalFilters.key] !== undefined) {
            specificData[totalFilters.key].push({
              value: totalFilters.value,
              id: item._id,
            });
          } else {
            specificData[totalFilters.key] = [
              { value: totalFilters.value, id: item._id },
            ];
          }
        });
      }
    });
    setFilter(specificData);
  };
  const openImageViewer = React.useCallback((index) => {
    console.log(index);
    setCurrentImage1(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage1(0);
    setIsViewerOpen(false);
  };

  const averageRating = averageFunc(reviewsData.map((item) => item.ratingStar));

  const discounts = helperFunctions(
    data?.variants[currentVariant]?.price -
    data?.variants[currentVariant]?.salePrice,
    data?.variants[currentVariant]?.price
  );

  const addWish = () => {
    if (loggedIn) {
      setIsfavorite(true);
      addWishlist(data?._id)
        .then((res) => {
          getWishlist()
            .then((res) => {
              SetWishlist(res.data.list);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsOpen(true);
    }
  };

  var a = 1;
  var b = 0;
  if (a === 1 && b === 2) {
    alert(a);
  }

  const removeWish = () => {
    setIsfavorite(false);
    RemoveWishList(data?._id)
      .then((res) => {
        getWishlist()
          .then((res) => {
            SetWishlist(res.data.list);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { attributes } = data;
  const cartAction = (setLoader1, type) => {
    console.log(selectedvariant);
   /*  if (loggedIn) {
      setLoader1(true);
      updateCartCount((prev) => prev+1);
    // updateCartCount(1);
        
      setLoader1(false);
      if (type === "buy") {
        navigate("/check-out");
      
    } 
  }else {
      setIsOpen(true);
    } */
  
    if (loggedIn) {
      if (quantity > 0) {
        setLoader1(true);
        addProduct(data._id, quantity, selectedvariant.id)
          .then((res) => {
            // console.log(res);
            const productQuantity = getProductQuantity(res);
            updateCartCount(productQuantity);
            setLoader1(false);
            if (type == "buy") {
              navigate("/check-out");
            }
          })
          .catch((err) => {
            console.log(err);
            setLoader1(false);
          });
      } 
    } else {
      setIsOpen(true);
    }
   
  };


  const DetailButton = (props) => {
    return (
      <div
        style={{ backgroundColor: quantity > 0 ? props.color : "#e5e5e5" }}
        className={` mr-1 flex py-[8px] min-w-[200px] sm:min-w-[130px] px-8 items-center justify-center rounded-md cursor-pointer`}
        {...props}
      >
        <p className={`text-[12px] lg:text-[11px] text-[#fff] font-normal}`}>
          {props.loader ? <DarkLoaderSm /> : props.text}
        </p>
      </div>
    );
  };

  const ColorBox = ({ color, colors, currentColor, ...props }) => {
    // console.log(currentColor == colors[currentVariant]);
    return (
      <div
        {...props}
        style={{
          borderWidth: currentColor == colors[currentVariant] ? 4 : 1,
          padding: currentColor == colors[currentVariant] ? 0 : 2,
          borderColor: "darkgray",
        }}
        className="mx-[4px] w-[32px] h-[32px] border-[1px] border-[#E9EAEB] p-[2px] rounded-sm cursor-pointer"
      >
        <div
          style={{
            backgroundColor: color,
          }}
          className="w-[100%] h-[100%] rounded-sm"
        ></div>
      </div>
    );
  }


  const changeVariant = (val) => {

    const index = getVariant(val);
    setCurrentVariant(data.variants.indexOf(index))

  }
console.log("Current Image", currentImage, ImagePlaceHolder, data.media[0].url)
  return (
    <div className="flex bg-grey-500 w-[100%] px-6 justify-between lg:flex-col ">
      <div className="w-[40%] lg:w-[90%] lg:mx-auto px-4 items-center flex flex-col ">
        <img
          // src={currentImage || ImagePlaceHolder}
          src={data.media[0].url}
          style={{ pointerEvents: "all" }}
          onClick={() => openImageViewer(0)}
          // onClick={() => console.log("clicked")}
          className="h-[230px]"
        />

        <div className="flex my-2 justify-center">
          {variantImages.map((item, index) => {
            return (
              <div
                key={index}
                className="w-[70px] h-[70px] p-1 mx-1 border-[1px] border-[#DCDEE0]  hover:cursor-pointer"
              >
                {item?.url && (
                  <img
                    src={item?.url ?? ""}
                    onClick={() => setCurrentImage(item?.url ?? "")}
                    className="w-[100%] h-[100%]"
                  />
                )}
              </div>
            );
          })}
        </div>

        <>
          {isViewerOpen && (
            <ImageViewer
              src={variantImages.map((item) => item.url)}
              currentIndex={currentImage1}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </>

        <div className="flex w-[365px] xl:w-[100%] lg:w-[365px] md:w-[92%] border-[1px] bg-[#ECFDF5] border-[#15AF84] rounded-sm justify-center p-[8.5px]">
          <img src={EyeIcon} className="h-[14px]" />
          <p className="mx-2 text-xs font-normal text-[#15AF84]">
            {" "}
            <span className="font-semibold">0</span> {t("peopleAreLooking")}
          </p>
        </div>
      </div>
      <div className="w-[60%] lg:mx-auto lg:w-[90%] lg:my-2 ">
        <p className="text-[14px] font-normal"> {data?.brandId?.name} </p>
        <p className="text-[26px] uppercase font-medium">{data?.title ?? ""}</p>
        {Boolean(attributes?.partNumber) && (
          <p className="text-[14px] ml-12 bg-black font-normal text-[#666666]">
            Part Number: {attributes.partNumber}
          </p>
        )}
        <div className="flex sm:flex-col justify-between w-[100%]">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={classNames(
                    averageRating > rating ? "text-[#FB8200]" : "text-gray-300",
                    "flex-shrink-0 h-4 w-4"
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
            <a href="#specs" className="text-[12px] font-normal text-[#666666]">
              ({reviewsData.length} {t("reviews")})
            </a>
          </div>

          <div className="flex items-center">
            <div className="flex px-[6px] py-[4px] mx-[3px] bg-[#FFEDED] rounded-[3px] border-[1px] border-[#E24545] items-center justify-center">
              <img src={CartUpIcon} className="h-[18px] mx-1" />
              <p className="text-[12px] lg:text-[10px] text-[#E24545] font-normal">
                <span className="font-medium">{data.soldCount}</span>{" "}
                {t("sold")}
              </p>
            </div>

            <div className="flex px-[6px] py-[4px] lg:py-[5.5px] bg-[#ECFDF5] items-center justify-center rounded-[3px] border-[1px] border-[#15AF84]">
              <img src={EyeIcon} className="h-[14px] mx-1" />
              <p className="text-[12px] lg:text-[10px] text-[#15AF84] font-normal">
                <span className="font-medium">0</span> {t("watching")}
              </p>
            </div>
            {isfavorite ? (
              <img
                src={WishlistSelected}
                className="h-[20px] mx-[8px] cursor-pointer"
                onClick={removeWish}
              />
            ) : (
              <img
                src={"/assets/heartUnfilled.svg"}
                className="h-[20px] mx-[8px] cursor-pointer"
                onClick={addWish}
              />
            )}

            <img
              src={"/assets/share.svg"}
              onClick={() => setShowModal(true)}
              className="h-[20px] cursor-pointer"
            />
            <ShareModal
              isOpen={showModal}
              closeModal={() => setShowModal(false)}
              openModal={() => setShowModal(true)}
            />
          </div>
        </div>

        {data?.isSale && (
          <>
            <p className="text-[16px] font-normal text-[#AAAAAA]">
              {<s>AED {data?.variants[currentVariant]?.price}</s>}
            </p>
          </>
        )}
        <div className="flex items-baseline">
          <p className="text-[26px] font-medium">
            {`AED ${data?.variants[currentVariant]?.price -
              data?.variants[currentVariant]?.salePrice
              }`}
          </p>

          {data?.isSale && (
            <p className="mx-2 text-[14px] font-medium text-[#CC0000]">
              You Saved {discounts.SavedPercent.toFixed(2)}% (AED{" "}
              {discounts.SavedAmount})
            </p>
          )}
        </div>

        <ul class="list-none my-4">
          <p
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            // dangerouslySetInnerHTML={{ __html: data?.shortDescription }}
            className={`text-[12px] text-[#666666]  max-w-[250px] ${(shortDescription.split('').length < 95 || showMore === data._id) ? "" : "h-[54px]"} overflow-hidden`}
          />
          {
            (shortDescription.split('').length > 95 && showMore !== data._id) &&
            <span
              onClick={() => setshowMore(data._id)}
              className="text-[12px] text-[#666666]  max-w-[250px] hover:cursor-pointer"
            >show more</span>
          }
        </ul>
        {/* {attributes.condition && (
          <div className="flex">
            <p className="text-[12px] font-normal text-[#666666] min-w-[70px]">
              Condition:
            </p>
            <p className="text-[12px] font-medium">{attributes.condition}</p>
          </div>
        )} */}

        {data?.variants &&
          data.variants[0].data.map(
            (item) =>
              (item.key === "Warranty" || item.key === "Condition") && (
                <div className="flex">
                  <p className="text-[12px] font-normal text-[#666666] min-w-[70px]">
                    {item.key}
                  </p>
                  <p className="text-[12px] font-medium">{item.value}</p>
                </div>
              )
          )}

        {attributes.warranty && (
          <div className="flex">
            <p className="text-[12px] font-normal text-[#666666] min-w-[70px]">
              Warranty:
            </p>
            <p className=" text-[12px] font-medium">{attributes.warranty}</p>
          </div>
        )}

        {data.sellingType !== "auction" && (
          <div className="flex justify-start  text-sm  items-center my-2">
            <input
              type="number"
              name="quantity"
              id="quantity"
              value={quantity}
              onChange={(e) => {
                if (e.target.value > selectedvariant.quantity) return;
                setQuantity(e.target.value);
              }}
              min={1}
              max={selectedvariant.quantity}
              className="appearance-none block w-[70px] mr-2 text-xs px-2 text-center py-2 border border-[#EDEDED] bg-transparent outline-none shadow-sm placeholder-gray-400"
            />

            <>
              {selectedvariant.quantity && (
                <>
                  <div
                    className={`border-t-transparent border-b-transparent border-t-[8px] border-b-[8px] border-solid ${isRtl
                      ? "border-l-[8px] border-l-[#4B5C6B]"
                      : "border-r-[8px] border-r-[#4B5C6B]"
                      } `}
                  ></div>
                  <div className="text-xs bg-[#4B5C6B] text-white p-2">
                    {t("hurry")}{" "}
                    <span className="text-yellow-500 font-semibold">
                      {selectedvariant.quantity - data.soldCount}
                    </span>{" "}
                    {t("leftInStock")}
                  </div>
                </>
              )}
            </>
          </div>
        )}

        {Object.entries(filter ? filter : {}).map((item, ind) => {

          const ids = item[1].map(o => o.value)
          const filtered = item[1].filter(({ value }, index) => !ids.includes(value, index + 1))

          if (item[0] === "Color") {
            return (
              <div key={ind} className="flex items-center">
                <div className="w-[50px] text-[12px] "> Color:</div>
                {filtered.map((color, index) => (
                  <ColorBox
                    key={index}
                    onClick={() => {
                      setCurrentVariant(index);
                    }}
                    currentColor={color}
                    colors={filtered}
                    color={color.value.toLowerCase()}
                  />
                ))}
              </div>
            );
          }
          return (
            (dropdownData.keys[ind] !== "Warranty" && dropdownData.keys[ind] !== "Condition") &&
            <Variant label={dropdownData.values[ind][0]} data={dropdownData.values[ind]} key1={ind} keys={dropdownData.keys} onClick={changeVariant} />

            // <Dropdown2
            //   selectedVariations={selectedVariations}
            //   key={item.key}
            //   click={(e) => {
            //     handleCheckChangeTop(e, item.key);
            //   }}
            //   label={toTitleCase(item.key)}
            //   data={item?.value}
            //   category={category}
            // />
            // <DropDown
            //   onChange={(e) => {
            //     setCurrentVariant(JSON.parse(e.target.value).index);
            //   }}
            //   value={JSON.stringify({
            //     id: item[1].find((itemm) => itemm.id == currentVariantId).id,
            //     index: currentVariant,
            //   })}
            // >
            //   {item[1].map((ite, ind) => {
            //     // console.log(
            //     //   item[1].find((itemm) => itemm.id == currentVariantId)
            //     // );
            //     return (
            //       <option
            //         key={ind}
            //         value={JSON.stringify({ id: ite.id, index: ind })}
            //       >
            //         {ite.value}
            //       </option>
            //     );
            //   })}
            // </DropDown>
          );
        })}
        {/* <QuantityDropDown/> */}
        <div className="flex">
          <p className="text-[14px] font-medium">Shipping: </p>
          <p className="mx-1 text-[14px] font-normal">
            AED 50.00 |{" "}
            <span className="text-[12px] font-normal">
              From <span className="font-medium">Sharjah</span> to{" "}
              <span className="font-medium">Dubai</span> via Aramex
            </span>{" "}
          </p>
        </div>
        <div className="flex">
          <p className="text-[14px] font-medium">Delivery On: </p>
          <p className="mx-1 text-[14px] font-normal">25th February 2022</p>
        </div>

        {data.sellingType !== "auction" && (
          <div className="flex my-2">
            <DetailButton
              text={t("buyNow")}
              color="#2C73D2"
              loader={buyNowLoader}
              onClick={() => cartAction(setBuyNowLoader, "buy")}
            />

            <DetailButton
              text={t("addToCart")}
              color="#FF9900"
              loader={addToCardLoader}
              onClick={() => cartAction(setAddToCardLoader, "cart")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDetailVariable;
