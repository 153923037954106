import React from "react";
import { Link } from "react-router-dom";

function SubSubCategory({
  to,
  name,
  currentCategory,
  id,
  parentSelected,
  setOpen,
  ...props
}) {
  return (
    <>
      {parentSelected && (
        <div
          key={id}
          style={{ marginRight: props.isRtl ? "28px" : 0 }}
          className="mt-2 ml-12 text-[14px] leading-[28px] flex items-center"
        >
          <Link onClick={() => setOpen(false)} {...props} to={to}>
            <div
              className={`ml-2 ${id == currentCategory ? "font-semibold" : "font-normal"
                } `}
            >
              {name}
            </div>
          </Link>
        </div>
      )}
    </>
  );
}

export default SubSubCategory;
