import React from "react";
import { useTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
const Sidebar = ({
  category,
  variants,
  brands,
  setBrands,
  selectedVariant,
  fixedBrands,
  currentBrand,
  minLimit,
  maxLimit,
  setOpen,
}) => {
  const { t } = useTranslation();
  return (
    <div className="m-4 p-5 w-[90%] bg-white relative">
      <h2 className="mt-2 text-[18px] font-medium text-gray-900">
        {t("shopByCategory")}
      </h2>
      <SidebarContent
        brands={brands}
        category={category}
        maxLimit={maxLimit}
        minLimit={minLimit}
        variants={variants}
        setBrands={setBrands}
        selectedVariant={selectedVariant}
        fixedBrands={fixedBrands}
        currentBrand={currentBrand}
        setOpen={setOpen}
      />
    </div>
  );
};

export default Sidebar;
