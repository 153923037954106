import React from "react";
import { heading_underline } from "../assets/index";

import MainHeader from "../components/header/MainHeader";
import SImilarProducts from "../components/sections/SImilarProducts";
import Order from "../components/CheckOut/Order";
import OrderCart from "../components/CheckOut/OrderCart";
import ProductsCart from "../components/sections/ProductsCart";
import { getCart } from "../config/Apis/CartApis";
import appContext from "../context/AppContext";
import { getProductQuantity } from "../Helper/helperFunctions";
function ShoppingCart(props) {
  const [data, setdata] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [orderDetails, setOrderDetails] = React.useState({});
  const { updateCartCount } = React.useContext(appContext);
  React.useEffect(() => {
    getCartData();
  }, []);

  const getCartData = () => {
    console.log("in refresh");
    // setLoader(true);
    getCart()
      .then((res) => {
        setLoader(false);
        if (res.data?.cart.length) {
          const productQuantity = getProductQuantity(res);
          console.log(res.data);
          setdata(res.data?.cart);
          setOrderDetails(res.data);
          updateCartCount(productQuantity);
        } else {
          setdata([]);
          updateCartCount(0);
          setOrderDetails({});
        }

        // console.log(res);
      })
      .catch((err) => {
        setLoader(false);
        updateCartCount(0);
        console.log(err);
      });
  };

  return (
    <div className="bg-[#f5f5f5]">
      {/* <MainHeader /> */}
      <div className="p-3">
        {/* <p className="uppercase font-[Almarai] text-2xl py-2">shopping cart</p> */}
        <img src={heading_underline} className="w-full" />
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-9 lg:col-span-12">
          {/* <ProductsCart loader={loader} data={data} onRefresh={getCartData} /> */}
        </div>
        {Boolean(data.length) && (
          <div className="col-span-3 mx-2 lg:col-span-12 ">
            <OrderCart data={orderDetails} onRefresh={getCartData} />
          </div>
        )}
        {/* <div className="col-span-12">
          <SImilarProducts
            heading={"Customers who bought this item also brought"}
          />
        </div> */}
      </div>
    </div>
  );
}

export default ShoppingCart;
