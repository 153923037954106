import React from "react";
import HotDealsCard from "../general/card/HotDealsCard";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { RightArrow, LeftArrow } from "../../assets/index";
import { useTranslation } from "react-i18next";

function MainCarousel(props) {
  // console.log("in hot deals ---------> ", props.data);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center px-8">
      <div className="w-[100%]">
        <div className="flex justify-between mb-[15px]">
          <p className="text-xl font-medium"> {props.title} </p>
        </div>
      </div>
      <div
        className="flex overflow-x-auto no-scrollbar  
      h-[425px] w-[100%] md:w-[95%] 
      lgth:overflow-visible	lgth:flex"
      >
        {props.data.map((item, index) => (
          <div key={index}>
            <div className="block lgth:hidden">
              <div
                className=" w-[250px] sm:w-[200px]  mr-[20px]  h-[400px] sm:h-[350px]"
                key={index}
              >
                <div className="">
                  <HotDealsCard data={{ ...item, from: "hotDeals" }} />
                </div>
              </div>
            </div>
            <div className="hidden lgth:block">
              <div
                className=" w-[250px] h-[400px] lgth:w-[190px] lgth:mr-[15px] lgth2:w-[193px] lgth2:mr-[30px] lgth3:w-[200px] lgth3:mr-[34px] lgth4:w-[209px] lgth4:mr-[35px]  lgth5:w-[220px] lgth5:mr-[35px] lgth6:w-[233px] lgth6:mr-[37px]"
                key={index}
              >
                <div className="">
                  <HotDealsCard data={{ ...item, from: "hotDeals" }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainCarousel;
