import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
// import MainHeader from "../components/header/MainHeader";
import Filter from "../components/sidebar/SeeAllHeader";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import FilterSidebar from "../components/sidebar/FilterSidebar";
import LoadMoreButton from "../components/buttons/LoadMoreButton";
import useSeeAll from "../hooks/useSeeAll";
// import DarkLoader from "../components/general/DarkLoader";

const SeeAll = () => {
  const {
    settingData,
    isOpen,
    setIsOpen,
    loader,
    // setLoader,
    loadMoreLoader,
    // setLoadMoreLoader,
    data,
    // setData,
    dataInfo,
    // setDataInfo,
    // dataLimit,
    // setDataLimit,
    variants,
    // setVariants,
    selectedVariant,
    setSelectedVariant,
    categoryData,
    // setCategoryData,
    brand,
    setBrand,
    currentBrand,
    setCurrentBrand,
    fixedBrands,
    // setFixedBrands,
    // category,
    // setCategory,
    subCategories,
    // setSubCategories,
    selected,
    setSelected,
    match,
    setMatch,
    categoryid,
    // location,
    // query,
    // getData,
    loadMoreData,
    maxLimit,
    minLimit,
    getFilters,
  } = useSeeAll();

  let params = useParams();

  return (
    <div className="bg-[#f8f8f8]">
      {categoryData?.metaTitle && (
        <Helmet>
          <title>
            {" "}
            {`${categoryData?.metaTitle || categoryData?.name} | ${
              settingData.siteTitle
            }`}{" "}
          </title>
          <meta name="description" content={categoryData?.metaDescription} />
          <meta name="keywords" content={categoryData?.metaKeyword} />
        </Helmet>
      )}
      {/* <MainHeader /> */}

      <>
        <div
          className="p-5 w-20 hidden lg:block"
          onClick={() => setIsOpen(!isOpen)}
        >
          {Boolean(data.length) ||
          (!Boolean(data.length) && params.categoryid != "search") ? (
            <DotsVerticalIcon className="w-5 h-5 cursor-pointer" />
          ) : (
            <></>
          )}
        </div>
        <div className="grid grid-cols-12">
          {(Boolean(data.length) || params.categoryid != "search") && (
            <div className={`col-span-3 transition-all lg:hidden `}>
              <Sidebar
                brands={brand}
                fixedBrands={fixedBrands}
                setBrands={setBrand}
                variants={variants}
                maxLimit={maxLimit}
                minLimit={minLimit}
                category={categoryid}
                selectedVariant={selectedVariant}
                currentBrand={currentBrand}
                onClose={() => setIsOpen(false)}
                setOpen={setIsOpen}
              />
              <FilterSidebar
                currentCategory={categoryData}
                categories={subCategories}
                brands={brand}
                fixedBrands={fixedBrands}
                selectedVariant={selectedVariant}
                setBrands={setBrand}
                maxLimit={maxLimit}
                minLimit={minLimit}
                variants={variants}
                category={categoryid}
                currentBrand={currentBrand}
                open={isOpen}
                setOpen={setIsOpen}
              />
            </div>
          )}

          <div
            className={`col-span-${
              data.length === 0
                ? params.categoryid == "search"
                  ? "12"
                  : "9"
                : "9"
            } lg:col-span-12`}
          >
           
            <Filter
              selected={selected}
              setSelected={setSelected}
              category={categoryid}
              loader={loader}
              data={data}
              categoryData={categoryData}
              setSelectedVariant={setSelectedVariant}
              setCurrentBrand={setCurrentBrand}
              setMatch={setMatch}
              match={match}
              variants={variants}
              selectedVariant={selectedVariant}
            />
            {data.length !== 0 && dataInfo.isNext && (
              <div className="flex justify-center">
                {!loader && (
                  <LoadMoreButton
                    loading={loadMoreLoader}
                    onClick={loadMoreData}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
      {/* <div class='h-[200px] w-full bg-red-700 hidden sm:block'>small</div>
      <div class='h-[200px] w-full bg-green-300 sm:hidden'>large</div> */}

    </div>
  );
};

export default SeeAll;
