import React from "react";

function SubmitButton({ label, color, loader, ...props }) {
  return (
    <button
      {...props}
      type="submit"
      disabled={loader}
      style={{ backgroundColor: loader ? "#999" : color }}
      className="block py-2 rounded-md px-6 w-[100%] text-[14px] sm:text-[12px] text-gray-100 outline-none"
    >
      {label}
    </button>
  );
}

export default SubmitButton;
