import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubmitButton from "../../buttons/SubmitButton";
import DarkLoaderSm from "../../general/DarkLoaderSm";
import { deleteIcon } from "../../../assets/index";
import { RemoveWishList } from "../../../config/Apis/WishlistApi";
import ImagePlaceholder from "../../../assets/imgplace.png";
import { useContext } from "react";
import appContext from "../../../context/AppContext";
import { useTranslation } from "react-i18next";

function OrderCard({ product, onRefresh }) {
  const [loader, setLoader] = useState(false);
  const { isRtl } = useContext(appContext);
  const { t } = useTranslation();
  const removeWishlistReq = async () => {
    try {
      setLoader(true);
      const response = await RemoveWishList(product._id);
      console.log(response);
      onRefresh();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div
      key={product._id}
      className="py-10 border-b border-gray-200 flex space-x-6 md:flex-col"
    >
      <Link to={`/ProductDetail/${product._id}`} state={product}>
        <img
          src={product?.media[0]?.url || ImagePlaceholder}
          alt={"Product"}
          className={`flex-none ${
            isRtl ? "ml-[20px]" : ""
          } h-20 md:ml-[17px] object-center bg-gray-100  rounded-lg sm:w-30 sm:h-30`}
        />
      </Link>

      <div className="flex flex-col py-2 w-[60%] lg:w-[55%] md:w-[90%] ">
        <div>
          <h4 className="font-medium text-gray-900">{`${product.title}`}</h4>
          <p className="mt-2 text-sm text-gray-600">
            {product.shortDescription}
          </p>
          <div className="flex items-center">
            <p
              className={`mt-2 ${
                isRtl ? "ml-2" : "mr-2"
              } text-sm text-gray-600`}
            >
              {t("aed")} {product.price}
            </p>
            {loader ? (
              <div className="w-[50px] my-[2px]">
                <DarkLoaderSm />
              </div>
            ) : (
              <img
                src={deleteIcon}
                onClick={removeWishlistReq}
                className="mt-2 h-[15px] cursor-pointer"
              />
            )}
          </div>
        </div>
      </div>
      <div className="md:w-[200px]">
        <Link to={`/ProductDetail/${product._id}`} state={product}>
          <SubmitButton
            onClicked={() => console.log("hello")}
            color={"#FF9900"}
            label="Add to Cart"
            onClick={() => console.log("clicked")}
          />
        </Link>
      </div>
    </div>
  );
}

export default OrderCard;
