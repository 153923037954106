import { BASE_URL, TOKEN } from "../keys";
import axios from "axios";

export const getAllCategories = (page, limit, type) => {
  //   console.log(TOKEN);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category`);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getCategoryData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category/${id}/subcategory`);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getCategoryData1 = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category/${id}`);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getPopularCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category`);
      //   console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getMenu = (page = 1, limit = 100) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category/menu`, {
        params: { page, limit },
      });
      return resolve(response.data.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSubcategory = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}category/${id}/subcategory`);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
