import React, { createContext, useContext, useState } from "react";
import { BASE_URL } from "../config/keys";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const loginUser = async (email, password) => {
    try {
      // Make an API call to your backend to handle login
      // Example using fetch:
      // const response = await fetch(`${BASE_URL}/auth/login`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ email, password }),
      // });

      // const data = await response.json();
      // console.log("%c data auth Context", "background: black; color: white", data)
      // localStorage.setItem("matjar_user_data", JSON.stringify(loginRes.data));

      // Cookies.set("email", JSON.stringify(email));

      // Cookies.set("token", JSON.stringify(token));
      // Cookies.set("modalStatus", false);
      // if (response.ok) {
        setUser(email);
      // } else {
      //   throw new Error(data.message);
      // }
    } catch (error) {
      console.error("Login error: ", error.message);
      throw error;
    }
  };

  const register = async (email, password) => {
    try {
      // Make an API call to your backend to handle registration
      // Example using fetch:
      const response = await fetch(`${BASE_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setUser(data.user);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Registration error: ", error.message);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loginUser, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
