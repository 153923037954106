import React, { useState, useRef, useEffect } from "react";

function useMyMaps(props) {
  const [coordinates, setCoordinates] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });
  const mapRef = useRef();
  const refs = {};
  useEffect(() => {}, []);

  const onMarker = (ref) => {
    refs.marker = ref;
  };

  const onDrag = (e) => {
    props.onChanged({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const onPositionChanged = () => {
    console.log(refs.marker.getPosition().lat());
    let lat = refs.marker.getPosition().lat();
    let lng = refs.marker.getPosition().lng();
    setCoordinates({ lat, lng });
    let position = refs.marker;
    console.log({ lat, lng });
  };

  const onMapClicked = (e) => {
    const { latLng } = e;
    console.log(latLng);
    let lat = latLng.lat();
    let lng = latLng.lng();
    setCoordinates({ lat, lng });
    props.onChanged({ lat, lng });
  };

  return {
    coordinates,
    setCoordinates,
    onMarker,
    onDrag,
    onMapClicked,
  };
}

export default useMyMaps;
