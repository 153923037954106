import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { addProduct, deleteProduct } from "../../../config/Apis/CartApis";
import EmptyImage from "../../../assets/imgplace.png";
import DarkLoaderSm from "../DarkLoaderSm";
import { useNavigate, Link } from "react-router-dom";

function CartSliderCards({ product, onRefresh, setMainLoader, setOpen }) {
  const [loader, setLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteProductReq = async () => {
    try {
      // setRemoveLoader(true);
      setMainLoader(true);
      const response = await deleteProduct(product?._id);
      onRefresh();
    } catch (err) {
      console.log(err);
    } finally {
      setRemoveLoader(false);
    }
  };

  const addProductReq = async () => {
    try {
      // setLoader(true);
      setMainLoader(true);
      const response = await addProduct(
        product?._id,
        product?.quantity + 1,
        product?.variation._id
      );
      // console.log(response);
      onRefresh();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const decProductReq = async () => {
    if (product.quantity > 1) {
      try {
        // setLoader(true);
        setMainLoader(true);
        const response = await addProduct(
          product?._id,
          product?.quantity - 1,
          product?.variation._id
        );
        // console.log(response);
        onRefresh();
      } catch (err) {
        console.log(err);
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <li key={product.id} className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={product?.media?.url || EmptyImage}
          alt={"Product"}
          className="h-full"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <a
             onClick={() => {navigate(`/ProductDetail/${product?._id}`, { state: product }); setOpen(false);}}
            className="text-[14px] cursor-pointer">
              {" "}
              {product?.title}{" "}
            </a>
            <p className="ml-4 text-[14px]">AED {product?.price || 0}</p>
          </div>
          <p className="mt-1 text-sm text-gray-500 max-h-12">{product?.description}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <div className="flex items-center ">
            {!loader && (
              <p
                onClick={addProductReq}
                className="p-1 cursor-pointer mx-2 text-[20px] shadow-md "
              >
                +
              </p>
            )}
            {loader ? (
              <DarkLoaderSm />
            ) : (
              <p className="text-gray-500">Qty {product?.quantity}</p>
            )}
            {!loader && (
              <p
                onClick={decProductReq}
                className="p-1 cursor-pointer px-[6px] mx-2 text-[20px] shadow-md"
              >
                -
              </p>
            )}
          </div>

          <div className="flex">
            {removeLoader ? (
              <DarkLoaderSm />
            ) : (
              <button
                onClick={deleteProductReq}
                type="button"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t("remove")}
              </button>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

export default CartSliderCards;
