import React from "react";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../Helper/helperFunctions";
import AllCategoryDropDown from "../dropdown/AllCategoryDropDown";
import MenuCard from "../general/card/MenuCard";
import DeliverTo from "./DeliverTo";

function HeaderMenu({
  allCategories,
  isRtl,
  setDeliveryModal,
  City,
  ...props
}) {
  console.log("All Categories from HeaderMenu.js file", allCategories)
  return (
    <div className="flex justify-start pl-2 items-center shadow-sm py-1">
      {Boolean(allCategories.length) && (
        <AllCategoryDropDown isRtl={isRtl} from="all" data={allCategories} />
      )}

      <div className=" flex w-[80%] justify-start items-center lg:overflow-x-scroll no-scrollbar lgth8:overflow-x-scroll md:overflow-x-scroll sm:overflow-x-scroll ">
        {/* {console.log(allCategories)} */}
     {allCategories
          .filter((item) => item.isTop == true)
          .slice(0, 6)
          .map((item, index) =>
            item.name == "All Categories" ? (
              <div key={index} className="group lg:min-w-[120px] relative">
                <Link to={`/seeall/${item._id}`}>
                  <p
                    className="text-sm px-[8px]  text-center text-[#333] lg:text-[10px] cursor-pointer hover:text-yellow-400 lgth:text-[12px]"
                    id={index}
                  >
                    {item.name}
                  </p>
                </Link>
              </div>
            ) : (
              <div
                key={index}
                className="group relative h-[50px] lg:min-w-[60px] md:min-w-[90px] justify-center flex items-center "
              >
                <Link to={`/seeall/${item._id}`}>
                  <p
                    className="text-sm px-[8px] text-[#333] text-center lg:text-[10px] cursor-pointer hover:text-yellow-400 lgth:text-[11px] lgth2:text-[13px]"
                    id={index}
                  >
                    {toTitleCase(item.name)}
                  </p>
                </Link>
                {Boolean(item.subCategory.length) && (
                  <div
                    // style={{ left: -item.left }}
                    className={`hidden absolute top-[45px] z-30 ${isRtl ? "left-[-400px]" : "left-[-130px]"
                      }  group-hover:block`}
                  >
                    <MenuCard from={"notAll"} data={item} />
                  </div>
                )}
              </div>
            )
          )}
      </div>
      <DeliverTo
        setDeliveryModal={setDeliveryModal}
        City={City}
        isRtl={isRtl}
      />
    </div>
  );
}

export default HeaderMenu;
