import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import appContext from "../../context/AppContext";

const Order = (props) => {
  const { t } = useTranslation();
  const { cartData } = useContext(appContext);

  return (
    <div className="w-[350px] sm:w-[100%]">
      <div className="bg-white p-5 rounded-md my-2 ">
        <h2 className="text-[22px] text-[#333] font-medium my-2">
          {t("orderSummary")}
        </h2>

        <div className="mt-1  rounded-md shadow-sm flex ">
          <input
            type="text"
            name="price"
            id="price"
            className="focus:ring-indigo-500 text-[16px] placeholder:opacity-25 placeholder:text-[#000] border h-[40px] py-2 focus:border-indigo-500 block w-full pl-3 sm:text-sm border-gray-300"
            placeholder={t("couponCode")}
            aria-describedby="price-currency"
          />
          <div className="flex items-center pointer-events-none">
            <span
              className="text-white px-3 py-2 bg-[#2C73D2] text-[16px] h-[40px] sm:text-sm"
              id="price-currency"
            >
              {t("apply")}
            </span>
          </div>
        </div>
        <div className="my-4">
          <div className="flex justify-between my-2">
            <p className="font-semibold text-[16px] text-[#444444]">
              {/* {t("subtotalItem")} */}
            </p>
            <p className="font-semibold text-[16px] text-[#444444]">
              {t("aed")}{" "}
              <span className="w-[90px]">{props?.data?.subtotal || 0}</span>
            </p>
          </div>
          {/* <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">
              {t("totalProductPrice")}
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              {t("aed")} {props?.data?.totalPrice || 0}
            </p>
          </div> */}
          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">
              {t("discount")}
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              - {t("aed")} {props?.data?.discount || 0}
            </p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">{t("tax")}</p>
            <p className="font-normal text-[16px] text-[#444444]">
              {t("aed")} {parseFloat(props?.data?.tax || 0).toFixed(1) || 0}
            </p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-normal text-[16px] text-[#444444]">
              {t("shippingFee")}
            </p>
            <p className="font-normal text-[16px] text-[#444444]">
              {" "}
              {t("aed")} {props?.data?.shipping || 0}
            </p>
          </div>
        </div>
        <div className="border-b-[1px] border-[#f8f1f1] w-full mx-auto"></div>

        <div className="flex justify-between my-2 ">
          <p className="font-medium text-[18px] text-[#444444]">{t("total")}</p>
          <p className="font-medium text-[18px] text-[#444444]">
            {t("aed")} {props?.data?.total || 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Order;
