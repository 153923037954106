import React from 'react'

const ClearanceProducts = () => {
  return (
    <div>
      <h1> Clearance Products</h1>
      
    </div>
  )
}

export default ClearanceProducts
