import React from "react";
import CheckBox from "../CheckBox";
import { tires1 } from "../../../assets/index";

function CrossellCard(props) {
  return (
    <div className="w-[195px] h-[248px] shadow-md">
      <div className="flex justify-between items-center pt-2">
        <div className="h-[22px] w-[74px] bg-[#F14705] text-[#fff] ml-1 text-[10px] flex items-center justify-center font-medium ">
          25% OFF
        </div>
        <input
          type="checkbox"
          name="selectall"
          id="selectall"
          className="mr-1"
        />
      </div>
      <div className="max-w-[164px] max-h-[141px] flex justify-center mx-auto items-center">
        <img src={tires1} alt="" />
      </div>
      <p className="text-[#2C73D2] text-[12px] px-[5px] pt-2">
        Air Intake Pump (General Electric AIP - 1255 -2)
      </p>
      <p className="text-[14px] text-[#333] font-bold px-[5px] pt-1">
        AED 124.50{" "}
        <s className="text-[#aaa] text-[10px] font-normal">AED 260.00</s>
      </p>
    </div>
  );
}

export default CrossellCard;
