import React from 'react'

const IsPopularCategories = () => {
  return (
    <div>
      <h1> PopularCategories </h1>
      
    </div>
  )
}

export default IsPopularCategories
