import { BASE_URL, TOKEN, USER_ID } from "../keys";
import axios from "axios";

export const getSettings = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${BASE_URL}setting`);
      // console.log("success", response.data);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
