import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SubmitButton from "../buttons/SubmitButton";

export default function SuccessModal({
  closeModal,
  openModal,
  isOpen,
  message,
}) {
  const currentUrl = window.location.href;
  // console.log(currentUrl);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="min-h-full flex mx-auto w-[95%] sm:w-full justify-between sm:px-[3px] lg:px-[5px]">
                  <div className="flex flex-col min-h-[240px] justify-center items-center">
                    <img
                      src="/assets/successOk.png"
                      alt="success"
                      className="h-[70px]"
                    />
                    <p className="text-[18px] font-medium text-[#333] text-center sm:text-[14px]">
                      {message}
                    </p>
                    <div className="my-[10px]">
                      <SubmitButton
                        color={"#2C73D2"}
                        label="Continue"
                        onClick={closeModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
