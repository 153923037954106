import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Help = () => {
  const [form] = useState({
    category: 'product',
    productCode: '',
    issueTitle: '',
    issueDescription: '',
  });
  const [attachments, setAttachments] = useState([]);

  const onDrop = (acceptedFiles) => {
    setAttachments(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf', // Allow only images and PDF files
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to the server)
    console.log({
      ...form,
      attachments,
    });
    // Reset form fields and attachments after submission if needed
    setAttachments([]);
  };

  return (
    // <div className="max-w-md  mx-auto p-6 bg-white rounded shadow-lg">
    <div className="max-w-full p-6 bg-white rounded shadow-lg">
    <h2 className="text-2xl font-semibold mb-4">Help Form</h2>
      <form onSubmit={handleSubmit} className='flex flex-wrap gap-[12px]'>
        <div className="mb-4 w-[45%]">
          <label htmlFor="category" className="block text-sm font-medium text-gray-600 mb-1">Category:</label>
          <select
            id="category"
            className="w-full p-2 border rounded"
            value={form.category}
            onChange={(e) => form.category = e.target.value}
          >
            <option value="product">Product Query/Complaint</option>
            <option value="general">General Query/Complaint</option>
          </select>
        </div>
        {form.category === 'product' && (
          <div className="mb-4 w-[45%]">
            <label htmlFor="productCode" className="block text-sm font-medium text-gray-600 mb-1">Product Code:</label>
            <input
              type="text"
              id="productCode"
              className="w-full p-2 border rounded"
              value={form.productCode}
              onChange={(e) => form.productCode = e.target.value}
            />
          </div>
        )}
        <div className="mb-4 w-1/2">
          <label htmlFor="issueTitle" className="block text-sm font-medium text-gray-600 mb-1">Issue Title:</label>
          <input
            type="text"
            id="issueTitle"
            className="w-full p-2 border rounded"
            value={form.issueTitle}
            onChange={(e) => form.issueTitle = e.target.value}
          />
        </div>
        <div className="mb-4 w-1/2">
          <label htmlFor="issueDescription" className="block text-sm font-medium text-gray-600 mb-1">Issue Description:</label>
          <textarea
            id="issueDescription"
            className="w-full p-2 border rounded"
            rows="4"
            value={form.issueDescription}
            onChange={(e) => form.issueDescription = e.target.value}
          />
        </div>
        <div className="mb-4 w-1/2 border border-solid border-lightgray p-[42px]">
          <div {...getRootProps()} className="p-4 border-dashed border-gray-300 rounded cursor-pointer text-center">
            <input {...getInputProps()} />
            <p>Drag & drop images or PDF files here, or click to select files</p>
          </div>
          
        </div>
       <div className='mb-4 w-1/2'>
       <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
          Submit
        </button>
       </div>
      </form>
    </div>
  );
};

export default Help;
