import React from "react";
import { Link } from "react-router-dom";
import SubmitButton from "../buttons/SubmitButton";

function EmptyCart({ from, ...props }) {
  return (
    <div className="flex flex-col justify-center items-center py-[150px] font-semibold">
      <img
        src="/assets/emptycart.png"
        alt="empty cart"
        className="h-[80px] -ml-[20px] "
      />
      <p className="my-[10px] text-[20px] font-semibold text-[#FF9900]">
        Your cart is empty!
      </p>

      <p className="text-center w-[250px] text-[12px] font-light text-[#666]">
        Looks like you haven't added anything to your cart yet.
      </p>

      {from == "checkout" && (
        <Link to={"/"} className="w-[240px] mt-3">
          <SubmitButton color={"#FF9900"} label="Shop Now" />
        </Link>
      )}
    </div>
  );
}

export default EmptyCart;
