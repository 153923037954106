import React, { useEffect, useState } from "react";
import DetailButton from "../components/buttons/CardButton";
import Addresses from "./Addresses";
import AddressCard from "../components/general/card/AddressCard";
import DarkLoader from "../components/general/DarkLoader";

import { getAllAddresses } from "../config/Apis/AddressApis";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import NoData from "../components/general/NoData";

function AddressesWrapper(props) {
  const [newAddress, setNewAddress] = useState(false);
  const [addressesData, setAddressesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoader(true);
      const addresses = await getAllAddresses();
      console.log(addresses?.data?.list);
      setAddressesData(addresses?.data?.list ?? []);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="py-4 px-4 md:px-1">
      <Helmet>
        <title>{`Addresses | ${props.siteTitle}`}</title>
      </Helmet>
      {loader ? (
        <div className="py-[150px]">
          <DarkLoader />
        </div>
      ) : (
        <>
          {!newAddress && (
            <>
              {addressesData.length ? (
                <>
                  <div className="flex sm:flex-col items-center sm:items-start justify-between mb-6">
                    <h1 className="mt-2 sm:mb-2 text-4xl font-extrabold tracking-tight sm:text-xll">
                      {t("addresses")}
                    </h1>
                    <div className="w-60">
                      <DetailButton
                        onClicked={() => setNewAddress(true)}
                        text={t("addNewAddress")}
                        bgcolor="rgb(44, 115, 210)"
                      />
                    </div>
                  </div>
                  <div>
                    {addressesData.length ? (
                      <>
                        {addressesData.map((item) => (
                          <AddressCard data={item} />
                        ))}
                      </>
                    ) : (
                      <div className="flex justify-center items-center py-[110px]">
                        <NoData />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-xl">
                    {t("addresses")}
                  </h1>
                  <div className="w-60 mx-auto my-40">
                    <DetailButton
                      onClicked={() => setNewAddress(true)}
                      text={t("addNewAddress")}
                      bgcolor={"#2c73d2"}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          {/* {!newAddress && (
        <div className="w-44 mx-auto my-40">
          <DetailButton
            onClicked={() => setNewAddress(true)}
            text="ADD NEW ADDRESS"
            bgcolor={"#2c73d2"}
          />
        </div>
      )} */}

          {newAddress && <Addresses />}
        </>
      )}
      
    </div>
  );
}

export default AddressesWrapper;
