import React from "react";
import { useContext } from "react";
import appContext from "../../context/AppContext";

const CheckBox = ({ children, type, ...props }) => {
  const { isRtl } = useContext(appContext);
  return (
    <div className="flex items-center my-2">
      {/* <input type="checkbox"
                className="h-4 w-4 mr-1 bg-blue-200 rounded-sm border-0 accent-[#FFFFFF] border-[#FFFFFF]" /> */}

      <input
        {...props}
        type={type || "checkbox"}
        id="A3-yes"
        name="A3-confirmation"
        value={children?._id ?? children}
        className="opacity-0 absolute h-8 w-8"
      />
      <div className="bg-transparent border-2 border-[#EDEDED] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-[#EDEDED]">
        <svg
          className="fill-current w-3 h-3 hidden text-[#EDEDED] pointer-events-none"
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#1F73F1" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <label
        style={{ marginRight: isRtl ? "5px" : 0 }}
        className="text-sm text-gray-900"
      >
        {children?.name || children}
      </label>
    </div>
  );
};

export default CheckBox;
