import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, selected, setSelected }) {
  const { t } = useTranslation();
  return (
    <div className="mt-2 sm:hidden">
      <div className=" block">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                onClick={() => setSelected(tab.id)}
                key={tab.name}
                className={classNames(
                  selected == tab.id
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap cursor-pointer py-2 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {t(tab.name)}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

// {/* <div className="hidden sm:block">
// <label htmlFor="tabs" className="sr-only">
//   Select a tab
// </label>
// {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
// <select
//   id="tabs"
//   name="tabs"
//   className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//   defaultValue={tabs.find((tab) => tab.current).name}
// >
//   {tabs.map((tab) => (
//     <option key={tab.name}>{tab.name}</option>
//   ))}
// </select>
// </div> */}
