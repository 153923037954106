import React, { useState } from 'react'

const useChangeVariant = (data) => {

    const dropdownData = { keys: [], values: [] };
    // console.log('useChangeVariant', data);
    data.variants.forEach((variant, ind) => {
        variant.data.forEach((item, num) => {
            dropdownData.keys.push(item.key);
            dropdownData.keys = [...new Set(dropdownData.keys)]
            dropdownData.values = Array(num + 1).fill([]);
        })
    })


    data.variants.forEach((variant, ind) => {
        variant.data.forEach((item, num) => {

            if (item.key === dropdownData.keys[num]) {
                dropdownData.values[num] = [...dropdownData.values[num], item.value]
                dropdownData.values[num] = [...new Set(dropdownData.values[num])]
            }

        })

    })

    let firstValue = [];
    dropdownData.keys.map((key, ind) => {
        if (key !== "Warranty" && key !== "Condition") {
            firstValue.push({ key, value: dropdownData.values[ind][0] });
        }
    })

    const getVariant = (val) => {
        firstValue.map(item => {
            if (item.key === val.key) {
                item.value = val.value;
                return { ...item }
            }
        })

        const founded = data.variants.find((item, ind) => {
            const allValues = item.data.map(item1 => item1.value);
            const selectedValues = firstValue.map(item => item.value)
            return selectedValues.every(item => allValues.includes(item))
        })

        return founded;
    }

    return {
        firstValue,
        dropdownData,
        getVariant
    }

}

export default useChangeVariant